import { useMemo } from 'react';
import { BasePagination } from '../context/base/BasePaginationResponse';

export const DOTS = '...';

const range = (start: number, end: number) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, idx) => idx + start);
};

export type IPaginationHelperProps = {
	totalCount: number;
	pageSize: number;
	siblingCount: number;
	currentPage: number;
};

export const PaginationHelper = (props: IPaginationHelperProps) => {
	const { currentPage, pageSize, siblingCount, totalCount } = props;
	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);

		// Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
		const totalPageNumbers = siblingCount + 5;

		/*
          If the number of pages is less than the page numbers we want to show in our
          paginationComponent, we return the range [1..totalPageCount]
        */
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		/*
          We do not want to show dots if there is only one position left
          after/before the left/right page count as that would lead to a change if our Pagination
          component size which we do not want
        */
		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
		}
	}, [totalCount, pageSize, siblingCount, currentPage]);

	return paginationRange;
};

export type IPaginationRenderProps = {
	onPageChange: (page: number) => void;
	pagination?: BasePagination;
	siblingCount?: number;
};

export const PaginationRender = (props: IPaginationRenderProps) => {
	const { onPageChange, pagination, siblingCount } = props;

	const paginationRange = PaginationHelper({
		currentPage: pagination?.current_page || 1,
		totalCount: pagination?.total_records || 0,
		siblingCount: siblingCount || 2,
		pageSize: pagination?.page_size || 20,
	});

	// const rangeRecords = () => {
	// 	const minRange = pageSize * (currentPage - 1) + 1;
	// 	const maxRange = pageSize * currentPage;
	// 	return `${minRange} - ${maxRange >= totalRecords ? totalRecords : maxRange}`;
	// };

	// const onNext = () => {
	// 	onPageChange(currentPage + 1);
	// };

	// const onPrevious = () => {
	// 	onPageChange(currentPage - 1);
	// };
	const formatNumber = (number: number) => {
		return number.toLocaleString('vi-VN');
	};

	return (
		<>
			<div className='d-flex  justify-content-between'>
				{pagination && (
					<span className='d-inline-flex align-self-center'>
						<small>Tổng số: <b>{formatNumber(pagination?.total_records || 0)}</b> bài viết</small>
					</span>
				)}

				<ul className='pagination pagination-sm'>
					{paginationRange &&
						paginationRange.map((pageNumber, index) => {
							if (pageNumber === DOTS) {
								return (
									<li key={index} className='page-item'>
										<span className='page-link'>
											<em className='icon ni ni-more-h'></em>
										</span>
									</li>
								);
							}
							return (
								<li
									key={index}
									className={`page-item ${pageNumber.valueOf() === pagination?.current_page ? 'active' : ''}`}
									aria-current='page'
								>
									<button
										className={`page-link ${pageNumber.valueOf() === pagination?.current_page ? 'bg-primary text-white text-bold' : ''}`}
										disabled={pageNumber.valueOf() === pagination?.current_page}
										type='button'
										onClick={() => {
											if (typeof pageNumber === 'number') {
												return onPageChange(pageNumber);
											} else {
												return onPageChange(parseInt(pageNumber));
											}
										}}
									>
										{pageNumber}
									</button>
								</li>
							);
						})}
				</ul>
			</div>
		</>
	);
};

import { all, fork } from 'redux-saga/effects';
import accountSaga from './my-system/auth/accountSaga';
import menusSaga from './my-system/menus/menusSaga';
import roleScopesSaga from './my-system/roles/roleScopesSaga';
import rolesSaga from './my-system/roles/rolesSaga';
import usersGroupSaga from './my-system/users-group/userGroupSaga';
import usersSaga from './my-system/users/usersSaga';
import articleCategorySaga from './articles-category/articleCategorySaga';
import articleSaga from './articles/articleSaga';
import articleTypesSaga from './articles-type/articleTypeSaga';
import royaltySaga from './royalty/royaltySaga';
import channelSaga from './channel/channelTypeSaga';
import keywordSeoSaga from './seos/keywordSeoSaga';
import crawlArticleSaga from './crawl-articles/crawlArticleSaga';

export default function* rootSagas() {
	yield all([fork(usersSaga)]);
	yield all([fork(menusSaga)]);
	yield all([fork(accountSaga)]);
	yield all([fork(rolesSaga)]);
	yield all([fork(roleScopesSaga)]);
	yield all([fork(usersGroupSaga)]);
	yield all([fork(articleCategorySaga)]);
	yield all([fork(articleSaga)]);
	yield all([fork(articleTypesSaga)]);
	yield all([fork(royaltySaga)]);
	yield all([fork(channelSaga)]);
	yield all([fork(keywordSeoSaga)]);
	yield all([fork(crawlArticleSaga)]);
}

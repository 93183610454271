import { all, call, put, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {
	ArticleAction_CHANGE_STATUS_Request,
	ArticleAction_DELETE_Request,
	ArticleAction_GETBY_ACTION_Request,
	ArticleAction_GETBY_OWNER_ACTION_Request,
	ArticleAction_GETPAGINATION_Request,
	ArticleAction_GETPUBLISHED_Request,
	ArticleAction_GET_DETAIL_EDIT_Request,
	ArticleAction_GET_DETAIL_Request,
	ArticleAction_GET_RELATED_Request,
	ArticleAction_GET_RELATED_Success,
	ArticleAction_GET_TYPENEWS_Request,
	ArticleAction_SAVE_Request,
	ArticleAction_SHOW_DETAIL,
	eArticleActionTypeIds,
} from './IArticleActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { ArticleItemResponse, ArticleModel, ArticleSaveModel } from '../../models/articles/ArticleModel';
import { ArticleTypeNewsResponse } from '../../models/articles/ArticleTypeNews';

const _sagaApi = rootApi.article;

function* onLoadPaginationArticle(action: ArticleAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticle = res.result as ArticleItemResponse[];
			yield put(actions.article.getPaginationSuccess({ listDatas: listArticle, pagination: res.pagination }));
		} else {
			yield put(actions.article.getPaginationFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.getPaginationFailure(error || 'Có lỗi'));
	}
}

function* onLoadDetailArticle(action: ArticleAction_GET_DETAIL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetByIdAsync], action.payload);
		if (res.isSuccess && res.result) {
			const detail = res.result as ArticleModel;
			yield put(actions.article.detailSuccess(detail));
		} else {
			yield put(actions.article.detailFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.detailFailure(error || 'Có lỗi'));
	}
}

function* onLoadDetailEditArticle(action: ArticleAction_GET_DETAIL_EDIT_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetEditByIdAsync], action.payload);
		if (res.isSuccess && res.result) {
			const detail = res.result as ArticleSaveModel;
			yield put(actions.article.detailEditSuccess(detail));
		} else {
			yield put(actions.article.detailEditFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.detailEditFailure(error || 'Có lỗi'));
	}
}

function* onShowDetailArticle(action: ArticleAction_SHOW_DETAIL) {
	try {
		if (action.payload.id) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetByIdAsync], action.payload.id);
			if (res.isSuccess && res.result) {
				const detail = res.result as ArticleModel;
				yield put(actions.article.detailSuccess(detail));
			} else {
				yield put(actions.article.detailFailure(res.message));
			}
		} else {
			yield put(actions.article.detailSuccess());
		}
	} catch (error: any) {
		yield put(actions.article.detailFailure(error || 'Có lỗi'));
	}
}

function* onLoadByActionArticle(action: ArticleAction_GETBY_ACTION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectPaginationByActionAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticle = res.result as ArticleItemResponse[];
			yield put(actions.article.getByActionSuccess({ listDatas: listArticle, pagination: res.pagination }));
		} else {
			yield put(actions.article.getByActionFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.getByActionFailure(error || 'Có lỗi'));
	}
}

function* onLoadByOwnerActionArticle(action: ArticleAction_GETBY_OWNER_ACTION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectPaginationByActionOwnerAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticle = res.result as ArticleItemResponse[];
			yield put(actions.article.getByOwnerActionSuccess({ listDatas: listArticle, pagination: res.pagination }));
		} else {
			yield put(actions.article.getByOwnerActionFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.getByOwnerActionFailure(error || 'Có lỗi'));
	}
}

function* onLoadPublishedArticle(action: ArticleAction_GETPUBLISHED_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectPaginationPublishedAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticle = res.result as ArticleItemResponse[];
			yield put(actions.article.getPublishedSuccess({ listDatas: listArticle, pagination: res.pagination }));
		} else {
			yield put(actions.article.getPublishedFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.getPublishedFailure(error || 'Có lỗi'));
	}
}

function* onLoadRelatedArticle(action: ArticleAction_GET_RELATED_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectArticleRelatedAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticle = res.result as ArticleSaveModel[];
			yield put(actions.article.getRelatedSuccess(listArticle));
		} else {
			yield put(actions.article.getRelatedFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.getRelatedFailure(error || 'Có lỗi'));
	}
}

function* onLoadTypeNewsArticle(action: ArticleAction_GET_TYPENEWS_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectAllByTypeNewsAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticle = res.result as ArticleTypeNewsResponse[];
			yield put(actions.article.getTypeNewsSuccess(listArticle));
		} else {
			yield put(actions.article.getTypeNewsFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.getTypeNewsFailure(error || 'Có lỗi'));
	}
}

function* onSaveArticle(action: ArticleAction_SAVE_Request) {
	try {
		if (BigInt(action.payload.id) > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.article.saveSuccess(res.message));
				yield put(actions.article.needReload());
			} else {
				yield put(actions.article.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.article.saveSuccess(res.message));
				yield put(actions.article.needReload());
			} else {
				yield put(actions.article.saveFailure(res.message));
			}
		}
	} catch (error: any) {
		yield put(actions.article.saveFailure(error || 'Có lỗi'));
	}
}

function* onDeleteArticle(action: ArticleAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.article.deleteSuccess(res.message));
			yield put(actions.article.needReload());
		} else {
			yield put(actions.article.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.deleteFailure(error || 'Có lỗi'));
	}
}

function* onChangeStatusArticle(action: ArticleAction_CHANGE_STATUS_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.ChangeStatusAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.article.changeStatusSuccess(res.message));
			yield put(actions.article.needReload());
		} else {
			yield put(actions.article.changeStatusFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.article.changeStatusFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadPaginationArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationArticle);
}

function* watchOnLoadDetailArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_DETAIL_REQUEST, onLoadDetailArticle);
}

function* watchOnLoadDetailEditArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_DETAIL_EDIT_REQUEST, onLoadDetailEditArticle);
}

function* watchOnLoadByActionArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_BY_ACTION_REQUEST, onLoadByActionArticle);
}

function* watchOnShowDetailArticle() {
	yield takeEvery(eArticleActionTypeIds.SHOW_DETAIL, onShowDetailArticle);
}

function* watchOnLoadByOwnerActionArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_BY_OWNER_ACTION_REQUEST, onLoadByOwnerActionArticle);
}

function* watchOnLoadPublishedArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_PUBLISHED_REQUEST, onLoadPublishedArticle);
}

function* watchOnLoadRelatedArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_RELATED_REQUEST, onLoadRelatedArticle);
}

function* watchOnLoadTypeNewsArticle() {
	yield takeLatest(eArticleActionTypeIds.GET_TYPENEWS_REQUEST, onLoadTypeNewsArticle);
}

function* watchOnSaveArticle() {
	yield takeLatest(eArticleActionTypeIds.SAVE_REQUEST, onSaveArticle);
}

function* watchOnDeleteArticle() {
	yield takeLatest(eArticleActionTypeIds.DELETE_REQUEST, onDeleteArticle);
}

function* watchOnChangeStatusArticle() {
	yield takeEvery(eArticleActionTypeIds.CHANGE_STATUS_REQUEST, onChangeStatusArticle);
}

function* articleSaga() {
	yield all([fork(watchOnLoadPaginationArticle)]);
	yield all([fork(watchOnLoadDetailArticle)]);
	yield all([fork(watchOnLoadDetailEditArticle)]);
	yield all([fork(watchOnShowDetailArticle)]);
	yield all([fork(watchOnLoadByActionArticle)]);
	yield all([fork(watchOnLoadByOwnerActionArticle)]);
	yield all([fork(watchOnLoadPublishedArticle)]);
	yield all([fork(watchOnSaveArticle)]);
	yield all([fork(watchOnDeleteArticle)]);
	yield all([fork(watchOnChangeStatusArticle)]);
	yield all([fork(watchOnLoadRelatedArticle)]);
	yield all([fork(watchOnLoadTypeNewsArticle)]);
}

export default articleSaga;

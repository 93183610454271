import React from 'react';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { eArticleStatus, getArticleStatusName } from '../../../context/models/articles/eArticleStatus';

type IChangeStatusConfirmProps = {
	isShow: boolean;
	isLoading: boolean;
	mesageText?: string;
	actionStatus: eArticleStatus;
	selectedIds: any[];
	onClose: () => void;
	onConfirmStatus: () => void;
};

export const ChangeStatusConfirm = (props: IChangeStatusConfirmProps) => {
	const { isShow, isLoading, actionStatus, selectedIds, onConfirmStatus, onClose, mesageText } = props;

	return (
		<>
			<ConfirmModal
				show={isShow}
				innerText={
					mesageText ? mesageText : `Bạn chắc chắn muốn ${getArticleStatusName(actionStatus)} ${selectedIds.length} bài viết đã chọn ?`
				}
				type='primary'
				onClose={() => onClose()}
				onConfirmed={() => onConfirmStatus()}
				isLoading={isLoading}
				btnConfirmText={getArticleStatusName(actionStatus)}
				btnCloseText='Hủy'
			/>
		</>
	);
};

import ArticleCrawlerDetailPage from './crawler-new/ArticleCrawlerDetailPage';
import CrawlerNewPage from './crawler-new/ArticleCrawlerPage';

export const crawlerRoutes = [
	{
		title: 'Bài viết mới về',
		path: '/crawler-new',
		component: CrawlerNewPage,
	},
	{
		title: 'Bài viết mới về',
		path: '/crawler-new/preview/:id',
		component: ArticleCrawlerDetailPage,
	},	
];

import MenuPage from './menus';
import RolesPage from './roles';
import SYSAccountPage from './sys-accounts';
import UsersGroupPage from './users-group';

export const mySystemRoutes = [
	{
		title: 'Tài khoản',
		path: '/accounts',
		component: SYSAccountPage,
	},
	{
		title: 'Tài khoản',
		path: '/accounts/quan-tri',
		component: SYSAccountPage,
	},
	{
		title: 'Phan quyền chức năng',
		path: '/menu-roles',
		component: MenuPage,
	},
	{
		title: 'Tác nhân',
		path: '/users-group',
		component: UsersGroupPage,
	},
	{
		title: 'Quyền',
		path: '/roles',
		component: RolesPage,
	},
];

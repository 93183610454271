import React, { useCallback, useMemo, useState } from 'react';
import Editor from 'ckeditor5-custom-build';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { uploadApi } from '../../../context/api/my-system/uploadApi';
import { UploadFile } from '../../../context/models/my-system/upload/UploadFile';
import { useAuth } from '../../../utils/AuthContext';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { Button } from '../Button';
import './TextEditor.css';

type ITextEditorProps = {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	saveFileName?: string;
};

function isValidURL(url: string) {
	const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g);
	return res !== null;
}

function getImages(stringHtml: string) {
	const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
	const images = [];
	let img;
	while ((img = imgRex.exec(stringHtml))) {
		images.push(img[1]);
	}
	return images;
}

function extractHostname(url: string) {
	if (!isValidURL(url)) return '';

	let hostname = '';
	//find & remove protocol (http, ftp, etc.) and get hostname

	if (url.indexOf('//') > -1) {
		hostname = url.split('/')[2];
	} else {
		hostname = url.split('/')[0];
	}

	//find & remove port number
	hostname = hostname.split(':')[0];
	//find & remove "?"
	hostname = hostname.split('?')[0];

	return hostname;
}

const ckDefaultConfig = {
	toolbar: {
		items: [
			'sourceEditing',
			'|',
			'removeFormat',
			'heading',
			'style',
			'|',
			'bold',
			'italic',
			'underline',
			'fontSize',
			'fontFamily',
			'fontBackgroundColor',
			'fontColor',
			'highlight',
			'|',
			'alignment',
			'todoList',
			'bulletedList',
			'numberedList',
			'outdent',
			'indent',
			'|',
			'link',
			'blockQuote',
			'superscript',
			'subscript',
			'strikethrough',
			'horizontalLine',
			'specialCharacters',
			'|',
			'imageUpload',
			'imageInsert',
			'mediaEmbed',
			'|',
			'insertTable',
			'codeBlock',
			'code',
			'htmlEmbed',
			'showBlocks',
			'pageBreak',
			'restrictedEditingException',
			'|',
			'findAndReplace',
			'undo',
			'redo',
		],
		shouldNotGroupWhenFull: true,
	},
	language: 'vi',
	image: {
		toolbar: ['imageTextAlternative', 'toggleImageCaption', 'imageStyle:inline', 'imageStyle:block', 'imageStyle:side', 'linkImage'],
	},
	table: {
		contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'],
	},
};

const TextEditor = ({ value, name, onValueChanged, saveFileName }: ITextEditorProps) => {
	const [imagesCount, setImageCount] = useState(0);
	const { account } = useAuth();
	const cdnDomain = useMemo(() => {
		return account?.website_confifg?.cdn_domain || '';
	}, [account]);

	function uploadAdapter(loader: any) {
		return {
			upload: () => {
				return new Promise((resolve, reject) => {
					loader.file.then((file: File) => {
						// body.append("files", file);
						const files: File[] = [file];
						uploadApi.UploadImagesAsync({ files, save_file_name: '' }).then((response) => {
							if (response && response.isSuccess) {
								const images = response?.result as UploadFile[];
								if (images && images.length > 0) {
									resolve({
										default: `${images[0]?.path}`,
									});
								}
							}
						});
					});
				});
			},
		};
	}

	function uploadPlugin(editor: any) {
		editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
			return uploadAdapter(loader);
		};
	}

	const handleFormatContent = useCallback(() => {
		// Upload image to Server
		try {
			if (value) {
				uploadApi.CleanAllStyleContentAsync({ content_html: value }).then((response) => {
					NotifyHelper.Success(response.message);
					if (response && response.isSuccess) {
						const outPutHtml = response?.result as string;
						if (outPutHtml && outPutHtml.length > 0) {
							onValueChanged(outPutHtml);
							checkImage();
						}
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const handleDownloadImageInContent = useCallback(() => {
		// Upload image to Server
		try {
			if (value) {
				uploadApi.DownloadImageInContentHtmlAsync({ content_html: value }).then((response) => {
					NotifyHelper.Success(response.message);
					if (response && response.isSuccess) {
						const outPutHtml = response?.result as string;
						if (outPutHtml && outPutHtml.length > 0) {
							onValueChanged(outPutHtml);
							checkImage();
						}
					}
				});
			}
		} catch (error) {
			console.log(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const checkImage = useCallback(() => {
		const images = getImages(value);
		let count = 0;
		images?.forEach((item) => {
			const domain = extractHostname(item);
			if (domain.length > 0 && !domain.includes(cdnDomain)) {
				count++;
			} else {
				if (item.includes('data:image')) {
					count++;
				}
			}
		});
		setImageCount(count);
	}, [cdnDomain, value]);

	const changeToolbarPosition = () => {
		const toolbar = document.querySelector('.ck-sticky-panel__content') as HTMLElement;
		if (toolbar) {
			toolbar.style.top = '65px';
		}
	};

	return (
		<>
			<div className='edit_content_container border rounded p-2'>
				<Button
					onClick={() => handleFormatContent()}
					text='Chuẩn định dạng SEO'
					theme='primary'
					icon='icon ni ni-article'
					className='mb-2'
				/>
				{imagesCount > 0 && (
					<>
						<div className='alert alert-danger alert-icon'>
							<em className='icon ni ni-cross-circle'></em> <strong>Cảnh báo</strong>! Có <b>{imagesCount}</b> ảnh không thuộc hệ thống.{' '}
							<Button
								onClick={() => handleDownloadImageInContent()}
								text='Lưu ảnh trong nội dung'
								theme='info'
								icon='icon ni ni-download'
							/>
						</div>
					</>
				)}
				<CKEditor
					editor={Editor}
					// onReady={(editor) => {
					// 	// You can store the "editor" and use when it is needed.
					// 	console.log('Editor is ready to use!', editor);
					// }}
					// onChange={(event) => {
					// 	console.log(event);
					// }}
					// onBlur={(event, editor) => {
					// 	console.log('Blur.', editor);
					// }}
					// onFocus={(event, editor) => {
					// 	console.log('Focus.', editor);
					// }}
					onChange={(event: any, editor: any) => {
						const data = editor.getData();
						onValueChanged(data);
						checkImage();
					}}
					// onBlur={(event, editor) => {
					// 	console.log('Blur.', editor);
					// }}
					onFocus={() => {
						changeToolbarPosition();
					}}
					data={value}
					config={{ ...ckDefaultConfig, extraPlugins: [uploadPlugin], removePlugins: ['MediaEmbedToolbar'] }}
					// config={{
					// 	extraPlugins: [uploadPlugin],
					// 	removePlugins: ['MediaEmbedToolbar'],
					// 	toolbar: ckDefaultConfig.toolbar,
					// 	heading: {
					// 		options: [
					// 			{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
					// 			{ model: 'heading2', view: 'h2', title: 'Tiêu đề 2', class: 'ck-heading_heading2' },
					// 			{ model: 'heading3', view: 'h3', title: 'Tiêu đề 3', class: 'ck-heading_heading3' },
					// 			{ model: 'heading4', view: 'h4', title: 'Tiêu đề 4', class: 'ck-heading_heading4' },
					// 		],
					// 	},
					// 	image: {
					// 		toolbar: [
					// 			'imageStyle:alignLeft',
					// 			'imageStyle:alignCenter',
					// 			'imageStyle:alignRight',
					// 			'|',
					// 			'imageResize:50',
					// 			'imageResize:75',
					// 			'imageResize:original',
					// 			'|',
					// 			'linkImage',
					// 			'imageTextAlternative',
					// 			'toggleImageCaption',
					// 		],
					// 		// Configure the available image resize options.
					// 		resizeOptions: [
					// 			{
					// 				name: 'imageResize:original',
					// 				value: null,
					// 				icon: 'original',
					// 			},
					// 			{
					// 				name: 'imageResize:50',
					// 				value: '50',
					// 				icon: 'medium',
					// 			},
					// 			{
					// 				name: 'imageResize:75',
					// 				value: '75',
					// 				icon: 'large',
					// 			},
					// 		],
					// 		styles: {
					// 			options: [
					// 				// This option is equal to a situation where no style is applied.
					// 				'full',

					// 				// This represents an image aligned to the left.
					// 				'alignLeft',

					// 				// This represents an image aligned to the right.
					// 				'alignRight',
					// 				'side',
					// 				'alignCenter',
					// 			],
					// 		},
					// 	},
					// }}
				/>
			</div>
		</>
	);
};

export default TextEditor;

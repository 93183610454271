import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { BaseForm, ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader, TextBox } from '../../../components/base';
import { KeywordSeoModel, dfKeywordSeoModel, valid_KeywordSeo } from '../../../context/models/seos/KeywordSeoModel';

type IKeywordSeoDetailProps = {
	isShow: boolean;
	title: string;
	detailModel?: KeywordSeoModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: KeywordSeoModel) => void;
};

const KeywordSeoDetail = (props: IKeywordSeoDetailProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<KeywordSeoModel>(dfKeywordSeoModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<KeywordSeoModel>({
		resolver: yupResolver(valid_KeywordSeo()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: KeywordSeoModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({
				...prev,
				...detailModel,
			}));
		}
	}, [detailModel]);

	return (
		<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
			<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
			<ModalBody>
				<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
					<TextBox
						name={'name'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, name: value }));
						}}
						value={initialValues.name}
						hasValid={true}
						label='Từ khóa'
					/>
					<TextBox
						name={'nameParent'}
						error={errors.name?.message}
						onValueChanged={(value) => setInitialValues((prev) => ({ ...prev, nameParent: value }))}
						value={initialValues.nameParent}
						hasValid={true}
						label='Nhóm từ khóa'
					/>
					<TextBox
						name={'volume'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, volume: value }));
						}}
						value={initialValues.volume.toString().replace('.', '')}
						hasValid={true}
						label='Volume'
					/>
					<TextBox
						name={'kd'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, kd: value }));
						}}
						value={initialValues.kd.toString()}
						hasValid={true}
						label='KD'
					/>
					<TextBox
						name={'cpc'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, cpc: value }));
						}}
						value={initialValues.cpc.toString()}
						hasValid={true}
						label='CPC'
					/>
					<TextBox
						name={'keywordType'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, keywordType: value }));
						}}
						value={initialValues.keywordType.toString()}
						hasValid={true}
						label='Loại từ khóa'
					/>
					<TextBox
						name={'link'}
						error={errors.name?.message}
						onValueChanged={(value) => {
							setInitialValues((prev) => ({ ...prev, link: value }));
						}}
						value={initialValues.link.toString()}
						hasValid={true}
						label='Link'
					/>
				</BaseForm>
			</ModalBody>
			<ModalFooter>
				<ButtonCancel onClick={onClose} isDisabled={isSaving} />
				<ButtonSave
					type='button'
					isLoading={isSaving}
					isDisabled={isSaving}
					onClick={handleSubmit(onSubmitHandler)}
					text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
				/>
			</ModalFooter>
		</ModalDraggable>
	);
};

export default KeywordSeoDetail;

import DataGrid, {
	Column,
	Export,
	FilterPanel,
	FilterRow,
	HeaderFilter,
	Pager,
	Paging,
	Selection,
	Sorting,
	RowDragging,
} from 'devextreme-react/data-grid';
import { exportDataGrid as dxExportExcelFn } from 'devextreme/excel_exporter';
import { exportDataGrid as dxExportPdfFn } from 'devextreme/pdf_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { createUUID } from '../../../utils/createUUID';
import { slugHelper } from '../../../utils/slugHelper';
import { Button } from '../../base';
import { DxToolbar, DxToolbarItem } from './dxtable-control/DxTableControl';
import { dxFont } from './dxtable-control/dxFont';

export type IDxTableSelectionProps = {
	mode: 'multiple' | 'single';
	onSelectionChanged: (e: any) => void;
	selectedRowKeys?: any[];
};

export type IDxTableFilterProps = {
	refreshDataGrid: () => void;
};

export type IDxTableExportProps = {
	hasExportSelected: boolean | false;
	title: string;
	hasHeader: boolean | false;
	headerMerge: { leftIndex: number | 2; rightIndex: number | 3 };
};

export type IDxTableProps = {
	dataSource: any[];
	keyExpr: string;
	selection?: IDxTableSelectionProps;
	filters?: IDxTableFilterProps;
	toolbars?: any;
	columns?: any;
	isLoading?: boolean;
	isShowIdx?: boolean;
	defaultPageSize?: number;
	showFilterPanel?: boolean;
	children?: React.ReactNode;
	wordWrapEnabled?: boolean;
	height?: any;
	width?: any;
	isHeaderFilter?: boolean;
	hasFixed?: boolean;
	exportOptions?: IDxTableExportProps;
	summaries?: any;
	allowReordering?: boolean;
	onReorder?: any;
	showToolbar?: boolean;
};

const DxTable = React.memo((props: IDxTableProps) => {
	const {
		dataSource,
		keyExpr,
		columns,
		isShowIdx,
		isLoading,
		defaultPageSize,
		showFilterPanel,
		children,
		selection,
		wordWrapEnabled,
		exportOptions,
		height,
		width,
		toolbars,
		filters,
		isHeaderFilter,
		hasFixed,
		summaries,
		allowReordering,
		onReorder,
		showToolbar,
	} = props;
	const [isDataLoading] = useDebounce<boolean>(isLoading || false, 800);

	const onExporting = React.useCallback(
		(e: any) => {
			if (e?.format === 'pdf') {
				const doc = new jsPDF({
					orientation: 'landscape',
					unit: 'mm',
					format: [210, 297],
				});

				doc.addFileToVFS('NotoSerif-Regular-normal.ttf', dxFont.font);
				doc.addFont('NotoSerif-Regular-normal.ttf', 'NotoSerif-Regular', 'normal');
				doc.setFont('NotoSerif-Regular');
				autoTable(doc, {
					theme: 'grid',
					margin: { horizontal: 7 },
					bodyStyles: { valign: 'top' },
					styles: { overflow: 'linebreak', cellWidth: 'auto' },
				});
				dxExportPdfFn({
					jsPDFDocument: doc,
					component: e.component,
					indent: 2,
				}).then(() => {
					doc.save(`${slugHelper.toSlug(exportOptions?.title || `Xuất excel ${moment().format('dd/MM/yyyy HH:mm:sss')}`)}.pdf`);
				});
			}

			if (e?.format === 'xlsx') {
				const workbook = new Workbook();
				const worksheet = workbook.addWorksheet('DATA');
				dxExportExcelFn({
					component: e.component,
					worksheet,
					autoFilterEnabled: true,
				}).then(() => {
					workbook.xlsx.writeBuffer().then((buffer) => {
						saveAs(
							new Blob([buffer], { type: 'application/octet-stream' }),
							`${slugHelper.toSlug(exportOptions?.title || `Xuất excel ${moment().format('dd/MM/yyyy HH:mm:sss')}`)}.xlsx`
						);
					});
				});
			}
			e.cancel = true;
		},
		[exportOptions]
	);

	const tableHeight = useMemo(() => {
		if (window.innerHeight <= 300 || height <= 300) return 600;
		return height || window.innerHeight - 210;
	}, [height]);
	
	const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

	const onSelectionChanged = useCallback((e: any) => {
		setSelectedRowKeys(e.changedRowKeys);
	}, []);

	return (
		<>
			<DataGrid
				dataSource={dataSource}
				keyExpr={keyExpr || 'id'}
				showBorders={true}
				columnAutoWidth={true}
				showColumnLines={true}
				showRowLines={true}
				allowColumnResizing={true}
				columnResizingMode={'nextColumn'}
				hoverStateEnabled={true}
				className='table-noborder-left'
				noDataText={isDataLoading ? 'Đang tải dữ liệu ...' : 'Không có dữ liệu'}
				onSelectionChanged={selection != undefined ? selection.onSelectionChanged : onSelectionChanged}
				selectedRowKeys={selectedRowKeys}
				wordWrapEnabled={wordWrapEnabled || false}
				height={tableHeight || 'auto'}
				width={width || '100%'}
				loadPanel={{ enabled: true }}
				allowColumnReordering={true}
				rowAlternationEnabled={true}
				onExporting={(e) => onExporting(e)}
			>
				{allowReordering && <RowDragging allowReordering={true} onReorder={onReorder} showDragIcons={true} />}
				<Sorting mode='multiple' />
				<HeaderFilter visible={isHeaderFilter || false} />
				<FilterRow visible={isHeaderFilter !== undefined ? isHeaderFilter : true} />
				<Export
					enabled={exportOptions !== undefined || false}
					allowExportSelectedData={exportOptions?.hasExportSelected || false}
					texts={{ exportAll: 'Xuất {0}', exportSelectedRows: 'Xuất {0} những dòng đã chọn', exportTo: 'Xuất dữ liệu' }}
					formats={['xlsx', 'pdf']}
				/>
				{showFilterPanel === true && <FilterPanel visible={true} />}

				<Paging enabled={(defaultPageSize || 0) > 0} defaultPageSize={defaultPageSize || 50} />
				{defaultPageSize && (
					<Pager
						showPageSizeSelector={true}
						allowedPageSizes={[10, 20, 50, 100, 200, 500]}
						// infoText='Trang {0} /{1} ({2} dòng)'
						// showInfo={true}
					/>
				)}
				{(isShowIdx === true || isShowIdx === undefined) && (
					<Column
						visibleIndex={0}
						caption={'#'}
						cellRender={(cell: any) => {
							return <span>{cell.rowIndex + 1}</span>;
						}}
						width={40}
						alignment='center'
						allowExporting={false}
						fixed={hasFixed || false}
					/>
				)}
				{selection && <Selection mode={selection.mode}  />}
				{selection && (
					<Column
						width={40}
						key={createUUID()}
						type={'selection'}
						visibleIndex={1}
						allowExporting={false}
						fixed={hasFixed || false}
					></Column>
				)}
				{columns ? columns : null}
				{children}
				<DxToolbar visible={showToolbar ?? true}>
					{toolbars}
					{filters && (
						<DxToolbarItem location='after' key={createUUID()}>
							<Button text='Làm mới' icon='icon ni ni-reload-alt' onClick={() => filters.refreshDataGrid()} />
						</DxToolbarItem>
					)}
					<DxToolbarItem location='after' name={'exportButton'} key={createUUID()} />
				</DxToolbar>
				{summaries ? summaries : null}
			</DataGrid>
		</>
	);
});

export default DxTable;

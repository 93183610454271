import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { ArticleCategoryModel } from '../../models/articles-category/ArticleCategoryModel';
import {
	ArticleCategoryAction_CHANGE_SELECTED_IDS,
	ArticleCategoryAction_DELETE_Failure,
	ArticleCategoryAction_DELETE_Request,
	ArticleCategoryAction_DELETE_Success,
	ArticleCategoryAction_GETALL_Failure,
	ArticleCategoryAction_GETALL_Request,
	ArticleCategoryAction_GETALL_Success,
	ArticleCategoryAction_GETPAGINATION_Failure,
	ArticleCategoryAction_GETPAGINATION_Request,
	ArticleCategoryAction_GETPAGINATION_Success,
	ArticleCategoryAction_RELOAD,
	ArticleCategoryAction_SAVE_Failure,
	ArticleCategoryAction_SAVE_Request,
	ArticleCategoryAction_SAVE_Success,
	ArticleCategoryAction_SHOW_CONFIRM,
	ArticleCategoryAction_SHOW_DETAIL,
	eArticleCategoryActionTypeIds,
} from './IArticleCategoryActionsTypes';

export const articleCategoryActions = {
	//ALL
	getAllRequest: (): ArticleCategoryAction_GETALL_Request => BaseAction(eArticleCategoryActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: ArticleCategoryModel[]): ArticleCategoryAction_GETALL_Success =>
		BaseAction(eArticleCategoryActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): ArticleCategoryAction_GETALL_Failure =>
		BaseAction(eArticleCategoryActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): ArticleCategoryAction_GETPAGINATION_Request =>
		BaseAction(eArticleCategoryActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationResponse<ArticleCategoryModel>): ArticleCategoryAction_GETPAGINATION_Success =>
		BaseAction(eArticleCategoryActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): ArticleCategoryAction_GETPAGINATION_Failure =>
		BaseAction(eArticleCategoryActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: ArticleCategoryModel): ArticleCategoryAction_SAVE_Request =>
		BaseAction(eArticleCategoryActionTypeIds.SAVE_REQUEST, entity),
	saveSuccess: (message: string): ArticleCategoryAction_SAVE_Success => BaseAction(eArticleCategoryActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): ArticleCategoryAction_SAVE_Failure => BaseAction(eArticleCategoryActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): ArticleCategoryAction_DELETE_Request => BaseAction(eArticleCategoryActionTypeIds.DELETE_REQUEST, ids),
	deleteSuccess: (message: string): ArticleCategoryAction_DELETE_Success =>
		BaseAction(eArticleCategoryActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): ArticleCategoryAction_DELETE_Failure =>
		BaseAction(eArticleCategoryActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): ArticleCategoryAction_RELOAD => BaseAction(eArticleCategoryActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): ArticleCategoryAction_CHANGE_SELECTED_IDS =>
		BaseAction(eArticleCategoryActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<ArticleCategoryModel>): ArticleCategoryAction_SHOW_DETAIL =>
		BaseAction(eArticleCategoryActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): ArticleCategoryAction_SHOW_CONFIRM => BaseAction(eArticleCategoryActionTypeIds.SHOW_CONFIRM, show),
};

import { BasePaginationResponse } from '../../../base/BasePaginationResponse';
import { BaseShowDetail } from '../../../base/BaseShowDetail';
import { IActionTypeBase } from '../../../base/IBaseActionTypes';
import { UserModel } from '../../../models/my-system/users/UserModel';
import { UserSelectRequest } from '../../../models/my-system/users/UserSelectRequest';

export enum eUsersActionTypeIds {
	GET_ALL_REQUEST = 'USER_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'USER_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'USER_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'USER_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'USER_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'USER_GET_PAGINATION_FAILURE',

	SAVE_SAVING = 'USER_SAVE_SAVING',
	SAVE_SUCCESS = 'USER_SAVE_SUCCESS',
	SAVE_FAILURE = 'USER_SAVE_FAILURE',

	DELETE_SAVING = 'USER_DELETE_SAVING',
	DELETE_SUCCESS = 'USER_DELETE_SUCCESS',
	DELETE_FAILURE = 'USER_DELETE_FAILURE',

	NEED_RELOAD = 'USER_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'USER_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'USER_SHOW_DETAIL',
	CLOSE_DETAIL = 'USER_CLOSE_DETAIL',
	SHOW_CONFIRM = 'USER_SHOW_CONFIRM',
	CLOSE_CONFIRM = 'USER_CLOSE_CONFIRM',
}

export interface UsersAction_GETALL_Request extends IActionTypeBase<eUsersActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface UsersAction_GETALL_Success extends IActionTypeBase<eUsersActionTypeIds.GET_ALL_SUCCESS, UserModel[]> {}
export interface UsersAction_GETALL_Failure extends IActionTypeBase<eUsersActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface UsersAction_GETPAGINATION_Request extends IActionTypeBase<eUsersActionTypeIds.GET_PAGINATION_REQUEST, UserSelectRequest> {}
export interface UsersAction_GETPAGINATION_Success
	extends IActionTypeBase<eUsersActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationResponse<UserModel> | undefined> {}
export interface UsersAction_GETPAGINATION_Failure extends IActionTypeBase<eUsersActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface UsersAction_SAVE_Request extends IActionTypeBase<eUsersActionTypeIds.SAVE_SAVING, UserModel> {}
export interface UsersAction_SAVE_Success extends IActionTypeBase<eUsersActionTypeIds.SAVE_SUCCESS, string> {}
export interface UsersAction_SAVE_Failure extends IActionTypeBase<eUsersActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface UsersAction_DELETE_Request extends IActionTypeBase<eUsersActionTypeIds.DELETE_SAVING, number[]> {}
export interface UsersAction_DELETE_Success extends IActionTypeBase<eUsersActionTypeIds.DELETE_SUCCESS, string> {}
export interface UsersAction_DELETE_Failure extends IActionTypeBase<eUsersActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface UsersAction_RELOAD extends IActionTypeBase<eUsersActionTypeIds.NEED_RELOAD, undefined> {}

export interface UsersAction_SHOW_DETAIL extends IActionTypeBase<eUsersActionTypeIds.SHOW_DETAIL, BaseShowDetail<UserModel>> {}
export interface UsersAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eUsersActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface UsersAction_SHOW_CONFIRM extends IActionTypeBase<eUsersActionTypeIds.SHOW_CONFIRM, boolean> {}

export type UsersActionTypes =
	| UsersAction_GETALL_Request
	| UsersAction_GETALL_Success
	| UsersAction_GETALL_Failure
	| UsersAction_GETPAGINATION_Request
	| UsersAction_GETPAGINATION_Success
	| UsersAction_GETPAGINATION_Failure
	| UsersAction_SAVE_Request
	| UsersAction_SAVE_Success
	| UsersAction_SAVE_Failure
	| UsersAction_DELETE_Request
	| UsersAction_DELETE_Success
	| UsersAction_DELETE_Failure
	| UsersAction_RELOAD
	| UsersAction_SHOW_DETAIL
	| UsersAction_CHANGE_SELECTED_IDS
	| UsersAction_SHOW_CONFIRM;

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api/rootApi';
import { BaseResponse } from '../../base/BaseResponse';
import { actions } from '../rootActions';
import { CrawlArticleModel } from '../../models/crawl-articles/CrawlArticleModel';
import {
	CrawlArticleAction_GETPAGINATION_Request,
	eCrawlArticleActionTypeIds,
} from './ICrawlArticlesActionTypes';

const _sagaApi = rootApi.crawlArticle;

function* onLoadAllCrawlArticles() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const articles = res.result as CrawlArticleModel;
			yield put(actions.crawlArticle.getAllSuccess(articles));
		} else {
			yield put(actions.crawlArticle.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.crawlArticle.getAllFailure(err || 'Có lỗi'));
	}
}

function* onLoadPaginationCrawlArticles(action: CrawlArticleAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const articles = res.result as CrawlArticleModel[];
			yield put(actions.crawlArticle.getPaginationSuccess({ listDatas: articles, pagination: res.pagination }));
		} else {
			yield put(actions.crawlArticle.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.crawlArticle.getPaginationFailure(err || 'Có lỗi'));
	}
}

// function* onSaveCrawlArticle(action: CrawlArticleAction_SAVE_Request) {
// 	try {
// 		if (action.payload.id > 0) {
// 			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
// 			if (res.isSuccess) {
// 				yield put(actions.crawlArticle.saveSuccess(res.message));
// 				yield put(actions.crawlArticle.reload());
// 			} else {
// 				yield put(actions.crawlArticle.saveFailure(res.message));
// 			}
// 		} else {
// 			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
// 			if (res.isSuccess) {
// 				yield put(actions.crawlArticle.saveSuccess(res.message));
// 				yield put(actions.crawlArticle.reload());
// 			} else {
// 				yield put(actions.crawlArticle.saveFailure(res.message));
// 			}
// 		}
// 	} catch (err: any) {
// 		yield put(actions.crawlArticle.saveFailure(err || 'Có lỗi'));
// 	}
// }

// function* onDeleteCrawlArticle(action: CrawlArticleAction_DELETE_Request) {
// 	try {
// 		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
// 		if (res.isSuccess) {
// 			yield put(actions.crawlArticle.deleteSuccess(res.message));
// 			yield put(actions.crawlArticle.reload());
// 		} else {
// 			yield put(actions.crawlArticle.deleteFailure(res.message));
// 		}
// 	} catch (error: any) {
// 		yield put(actions.crawlArticle.deleteFailure(error || 'Có lỗi'));
// 	}
// }

function* watchOnLoadAllCrawlArticles() {
	yield takeEvery(eCrawlArticleActionTypeIds.GET_ALL_REQUEST, onLoadAllCrawlArticles);
}

function* watchOnLoadPaginationCrawlArticles() {
	yield takeEvery(eCrawlArticleActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationCrawlArticles);
}

// function* watchOnSaveCrawlArticle() {
// 	yield takeEvery(eCrawlArticleActionTypeIds.SAVE_REQUEST, onSaveCrawlArticle);
// }

// function* watchOnDeleteCrawlArticle() {
// 	yield takeEvery(eCrawlArticleActionTypeIds.DELETE_REQUEST, onDeleteCrawlArticle);
// }

function* crawlArticleSaga() {
	yield all([fork(watchOnLoadAllCrawlArticles)]);
	yield all([fork(watchOnLoadPaginationCrawlArticles)]);
	// yield all([fork(watchOnSaveCrawlArticle)]);
	// yield all([fork(watchOnDeleteCrawlArticle)]);
}

export default crawlArticleSaga;

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	PasswordBox,
	SwitchBox,
	TextBox,
} from '../../../../components/base';
import DxTreeList, { DxTreeListColumn } from '../../../../components/common/DxTreeList';
import { useLookupArticleCategory } from '../../../../components/shared/hoc-article-category/useLookupArticleCategory';
import { CheckedBoxListRole, SelectBoxUserGroup } from '../../../../components/shared/system';
import { UserGroupModel } from '../../../../context/models/my-system/users-group/UserGroupModel';
import { UserModel, df_UserModel, valid_UserModel } from '../../../../context/models/my-system/users/UserModel';

type IAccountDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: UserModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: UserModel) => void;
};

const AccountDetail = (props: IAccountDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<UserModel>(df_UserModel);
	const [userGroupSelected, setUserGroupSelected] = useState<UserGroupModel | undefined>();
	const { article_category_all } = useLookupArticleCategory();
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<UserModel>({
		resolver: yupResolver(valid_UserModel(isAddMode ? 'add' : 'edit')),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: UserModel) => {
		onSubmit({ ...initialValues, ...values });
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			const {
				avatar,
				email,
				full_name,
				id,
				but_danh,
				is_active,
				is_protected,
				role_ids,
				user_group_id,
				user_name,
				password_confirmed,
				password_new,
				phone,
				categrory_ids,
			} = detailModel;
			setInitialValues((prev) => ({
				...prev,
				avatar,
				email,
				full_name,
				id,
				but_danh,
				is_active,
				is_protected,
				role_ids,
				user_group_id,
				user_name,
				password_confirmed,
				password_new,
				phone,
				categrory_ids,
			}));
		}
	}, [detailModel]);

	useEffect(() => {
		if (userGroupSelected) {
			setInitialValues((prev) => ({ ...prev, role_ids: userGroupSelected.role_ids?.split(',').map((x: any) => parseInt(x)) }));
		}
	}, [userGroupSelected]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='full' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='row g-gs'>
							<div className='col-lg-4'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Thông tin tài khoản</h5>
										</div>
										<div className='gy-3'>
											<div className='row g-3 align-center'>
												<div className='col-lg-12'>
													<TextBox
														name='user_name'
														error={errors.user_name?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, user_name: value }));
														}}
														isDisabled={!isAddMode}
														value={initialValues.user_name}
														hasValid={true}
														label='Tên đăng nhập'
													/>
													<FormGroup helpBlock={isAddMode ? 'Mật khẩu đăng nhập hệ thống.' : 'Chỉ nhập khi cần thay mật khẩu.'}>
														<PasswordBox
															name='password_new'
															label='Mật khẩu mới'
															labelSmall={true}
															error={errors.password_new?.message}
															onValueChanged={(value) => {
																setInitialValues((prev) => ({ ...prev, password_new: value }));
															}}
															value={initialValues.password_new || ''}
															hasValid={true}
														/>
														<PasswordBox
															name='password_confirmed'
															label='Xác nhận mật khẩu'
															labelSmall={true}
															error={errors.password_confirmed?.message}
															onValueChanged={(value) => {
																setInitialValues((prev) => ({ ...prev, password_confirmed: value }));
															}}
															value={initialValues.password_confirmed || ''}
															hasValid={true}
														/>
													</FormGroup>
													<TextBox
														name='full_name'
														error={errors.full_name?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, full_name: value }));
														}}
														value={initialValues.full_name}
														hasValid={true}
														label='Họ và tên'
													/>
													<TextBox
														name='email'
														type='email'
														error={errors.email?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, email: value }));
														}}
														value={initialValues.email || ''}
														hasValid={true}
														label='Email'
													/>
													<TextBox
														name='but_danh'
														type='text'
														error={errors.but_danh?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, but_danh: value }));
														}}
														value={initialValues.but_danh || ''}
														hasValid={true}
														label='Bút danh'
														helpBlock='Có thể nhập nhiều bút danh cách nhau bởi dấu ,'
													/>
													<SwitchBox
														name='is_active'
														label='Hoạt động'
														error={errors.is_active?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, is_active: value }));
														}}
														value={initialValues.is_active}
														hasValid={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-4'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Phân quyền</h5>
										</div>
										<SelectBoxUserGroup
											name='user_group_id'
											label='Vai trò'
											error={errors.user_group_id?.message}
											handleOnValueChanged={(value, data) => {
												setInitialValues((prev) => ({ ...prev, user_group_id: value }));
												setUserGroupSelected(data);
											}}
											value={initialValues.user_group_id}
											hasValid={true}
											placeholder='Chọn vai trò'
										/>
										<CheckedBoxListRole
											name='role_ids'
											label='Quyền hạn'
											hasValid={true}
											error={errors.role_ids?.message}
											onSelectionChanged={(value: number[]) => {
												setInitialValues((prev) => ({ ...prev, role_ids: value?.map((x: any) => parseInt(x)) }));
											}}
											selectedValue={initialValues.role_ids}
										/>
									</div>
								</div>
							</div>
							<div className='col-lg-4'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Chuyên mục</h5>
											<DxTreeList
												dataSource={article_category_all}
												keyExpr='id'
												parentIdExpr='parentId'
												selectionMode='multiple'
												value={initialValues.categrory_ids}
												onValueChanged={(selected) => {
													setInitialValues((prev) => ({ ...prev, categrory_ids: selected }));
												}}
											>
												<DxTreeListColumn dataField='name' caption='Chuyên mục' />
											</DxTreeList>
										</div>
									</div>
								</div>
							</div>
						</div>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default AccountDetail;

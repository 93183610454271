import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { ArticleTypeModel } from '../../models/articles-type/ArticleTypeModel';

export enum eArticleTypeActionTypeIds {
	GET_ALL_REQUEST = 'ArticleType_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'ArticleType_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'ArticleType_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'ArticleType_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'ArticleType_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'ArticleType_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'ArticleType_SAVE_REQUEST',
	SAVE_SUCCESS = 'ArticleType_SAVE_SUCCESS',
	SAVE_FAILURE = 'ArticleType_SAVE_FAILURE',

	DELETE_REQUEST = 'ArticleType_DELETE_REQUEST',
	DELETE_SUCCESS = 'ArticleType_DELETE_SUCCESS',
	DELETE_FAILURE = 'ArticleType_DELETE_FAILURE',

	RELOAD = 'ArticleType_RELOAD',

	CHANGE_SELECTED_IDS = 'ArticleType_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'ArticleType_SHOW_DETAIL',
	SHOW_CONFIRM = 'ArticleType_SHOW_CONFIRM',
}

export interface ArticleTypeAction_GETALL_Request extends IActionTypeBase<eArticleTypeActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface ArticleTypeAction_GETALL_Success extends IActionTypeBase<eArticleTypeActionTypeIds.GET_ALL_SUCCESS, ArticleTypeModel[]> {}
export interface ArticleTypeAction_GETALL_Failure extends IActionTypeBase<eArticleTypeActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface ArticleTypeAction_GETPAGINATION_Request
	extends IActionTypeBase<eArticleTypeActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface ArticleTypeAction_GETPAGINATION_Success
	extends IActionTypeBase<eArticleTypeActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationResponse<ArticleTypeModel>> {}
export interface ArticleTypeAction_GETPAGINATION_Failure
	extends IActionTypeBase<eArticleTypeActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface ArticleTypeAction_SAVE_Request extends IActionTypeBase<eArticleTypeActionTypeIds.SAVE_REQUEST, ArticleTypeModel> {}
export interface ArticleTypeAction_SAVE_Success extends IActionTypeBase<eArticleTypeActionTypeIds.SAVE_SUCCESS, string> {}
export interface ArticleTypeAction_SAVE_Failure extends IActionTypeBase<eArticleTypeActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface ArticleTypeAction_DELETE_Request extends IActionTypeBase<eArticleTypeActionTypeIds.DELETE_REQUEST, number[]> {}
export interface ArticleTypeAction_DELETE_Success extends IActionTypeBase<eArticleTypeActionTypeIds.DELETE_SUCCESS, string> {}
export interface ArticleTypeAction_DELETE_Failure extends IActionTypeBase<eArticleTypeActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface ArticleTypeAction_RELOAD extends IActionTypeBase<eArticleTypeActionTypeIds.RELOAD, undefined> {}

export interface ArticleTypeAction_SHOW_DETAIL
	extends IActionTypeBase<eArticleTypeActionTypeIds.SHOW_DETAIL, BaseShowDetail<ArticleTypeModel>> {}
export interface ArticleTypeAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eArticleTypeActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface ArticleTypeAction_SHOW_CONFIRM extends IActionTypeBase<eArticleTypeActionTypeIds.SHOW_CONFIRM, boolean> {}

export type ArticleTypeActionTypes =
	| ArticleTypeAction_GETALL_Request
	| ArticleTypeAction_GETALL_Success
	| ArticleTypeAction_GETALL_Failure
	| ArticleTypeAction_GETPAGINATION_Request
	| ArticleTypeAction_GETPAGINATION_Success
	| ArticleTypeAction_GETPAGINATION_Failure
	| ArticleTypeAction_SAVE_Request
	| ArticleTypeAction_SAVE_Success
	| ArticleTypeAction_SAVE_Failure
	| ArticleTypeAction_DELETE_Request
	| ArticleTypeAction_DELETE_Success
	| ArticleTypeAction_DELETE_Failure
	| ArticleTypeAction_RELOAD
	| ArticleTypeAction_SHOW_DETAIL
	| ArticleTypeAction_CHANGE_SELECTED_IDS
	| ArticleTypeAction_SHOW_CONFIRM;

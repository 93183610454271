import { valid_base, valid_base_object } from '../../base/valid_base';

export type KeywordSeoModel = {
	id: number;
	idHash: number;
	name: string;
	nameParent: string;
	volume: string;
	kd: string;
	cpc: string;
	keywordType: string;
	link: string;
};

export const dfKeywordSeoModel: KeywordSeoModel = {
	id: 0,
	idHash: 0,
	name: '',
	nameParent: '',
	volume: '0',
	kd: '0',
	cpc: '0',
	keywordType: '0',
	link: '',
};

export const valid_KeywordSeo = () =>
	valid_base_object.object<KeywordSeoModel>().shape({
		id: valid_base.number_required(),
		idHash: valid_base.number_required(),
		name: valid_base.string_required(),
		nameParent: valid_base.string_required(),
		volume: valid_base.string_required(),
		kd: valid_base.string_required(),
		cpc: valid_base.string_required(),
		keywordType: valid_base.string_required(),
		link: valid_base.string_required(),
	});

import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { ChannelModel } from '../../models/channel/ChannelModel';
import {
	ChannelTypeAction_GETALL_Request,
	ChannelTypeAction_GETALL_Success,
	ChannelTypeAction_GETALL_Failure,
	ChannelTypeAction_GETPAGINATION_Request,
	ChannelTypeAction_GETPAGINATION_Success,
	ChannelTypeAction_GETPAGINATION_Failure,
	ChannelTypeAction_SAVE_Request,
	ChannelTypeAction_SAVE_Success,
	ChannelTypeAction_DELETE_Request,
	ChannelTypeAction_DELETE_Success,
	ChannelTypeAction_RELOAD,
	ChannelTypeAction_SHOW_DETAIL,
	ChannelTypeAction_SHOW_CONFIRM,
	ChannelTypeAction_CHANGE_SELECTED_IDS,
	eChannelActionTypeIds,
} from './IChannelActionsTypes';

export const channelTypeActions = {
	getAllRequest: (): ChannelTypeAction_GETALL_Request => BaseAction(eChannelActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: ChannelModel[]): ChannelTypeAction_GETALL_Success => BaseAction(eChannelActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (err: Error | string): ChannelTypeAction_GETALL_Failure => BaseAction(eChannelActionTypeIds.GET_ALL_FAILURE, err),

	getPaginationRequest: (req: BaseSelectRequest): ChannelTypeAction_GETPAGINATION_Request =>
		BaseAction(eChannelActionTypeIds.GET_PAGINATION_REQUEST, req),
	getPaginationSuccess: (data: BasePaginationResponse<ChannelModel>): ChannelTypeAction_GETPAGINATION_Success =>
		BaseAction(eChannelActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (err: Error | string): ChannelTypeAction_GETPAGINATION_Failure =>
		BaseAction(eChannelActionTypeIds.GET_PAGINATION_FAILURE, err),

	saveRequest: (payload: ChannelModel): ChannelTypeAction_SAVE_Request => BaseAction(eChannelActionTypeIds.SAVE_REQUEST, payload),
	saveSuccess: (msg: string): ChannelTypeAction_SAVE_Success => BaseAction(eChannelActionTypeIds.SAVE_SUCCESS, msg),
	saveFailure: (err: Error | string) => BaseAction(eChannelActionTypeIds.SAVE_FAILURE, err),

	deleteRequest: (payload: number[]): ChannelTypeAction_DELETE_Request => BaseAction(eChannelActionTypeIds.DELETE_REQUEST, payload),
	deleteSuccess: (msg: string): ChannelTypeAction_DELETE_Success => BaseAction(eChannelActionTypeIds.DELETE_SUCCESS, msg),
	deleteFailure: (err: Error | string) => BaseAction(eChannelActionTypeIds.DELETE_FAILURE, err),

	reload: (): ChannelTypeAction_RELOAD => BaseAction(eChannelActionTypeIds.RELOAD, undefined),
	showDetail: (detail: BaseShowDetail<ChannelModel>): ChannelTypeAction_SHOW_DETAIL =>
		BaseAction(eChannelActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (isShow: boolean): ChannelTypeAction_SHOW_CONFIRM => BaseAction(eChannelActionTypeIds.SHOW_CONFIRM, isShow),
	changeSelectedIds: (payload: number[]): ChannelTypeAction_CHANGE_SELECTED_IDS =>
		BaseAction(eChannelActionTypeIds.CHANGE_SELECTED_IDS, payload),
};

import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationResponse, defaultBasePagination } from '../../base/BasePaginationResponse';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { ArticleTypeModel } from '../../models/articles-type/ArticleTypeModel';
import { ArticleTypeActionTypes, eArticleTypeActionTypeIds } from './IArticleTypeActionsTypes';
import { IArticleTypeState } from './IArticleTypeState';

const initPaginationResponse: BasePaginationResponse<ArticleTypeModel> = {
	listDatas: [],
	pagination: defaultBasePagination,
};

const initialState: IArticleTypeState = {
	status: eBaseActionStatus.idle,
	allArticleTypes: [],
	selectedIds: [],
	showConfirm: false,
	showDetail: { isShow: false },
	paginationResponse: initPaginationResponse,
};

const articleTypeReducer = (state: IArticleTypeState = initialState, action: ArticleTypeActionTypes): IArticleTypeState => {
	switch (action.type) {
		case eArticleTypeActionTypeIds.GET_ALL_REQUEST:
		case eArticleTypeActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allArticleTypes: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allArticleTypes: [],
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				allArticleTypes: [],
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eArticleTypeActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eArticleTypeActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm loại tin bài thành công!');
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Xóa loại tin bài thành công!');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eArticleTypeActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		default:
			return state;
	}
};

export default articleTypeReducer;

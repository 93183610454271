import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../../rootActions';
import { BaseResponse } from '../../../base/BaseResponse';
import { rootApi } from '../../../api/rootApi';
import {
	MenusAction_CHANGE_ROLE_MULTI_Request,
	MenusAction_CHANGE_ROLE_Request,
	MenusAction_REMOVE_ROLE_Request,
	eMenusActionTypeIds,
} from './IMenuActionTypes';
import { MenuItemResponse } from '../../../models/my-system/menus/MenuItemResponse';

const _sagaApi = rootApi.menus;

function* onLoadAllMenus() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const data = res.result as MenuItemResponse[];
			yield put(actions.menus.getAllSuccess(data));
		} else {
			yield put(actions.menus.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.menus.getAllFailure(error || 'Có lỗi'));
	}
}

function* onChangeRoleForMenu(action: MenusAction_CHANGE_ROLE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.ChangeRoleForMenuAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.menus.changeRoleSuccess(res.message));
			yield put(actions.menus.needReload());
		} else {
			yield put(actions.menus.changeRoleFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.menus.changeRoleFailure(error || 'Có lỗi'));
	}
}

function* onChangeRoleForMenuMultiple(action: MenusAction_CHANGE_ROLE_MULTI_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.ChangeRoleForMenuMultipleAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.menus.changeRoleMultiSuccess(res.message));
			yield put(actions.menus.needReload());
		} else {
			yield put(actions.menus.changeRoleMultiFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.menus.changeRoleMultiFailure(error || 'Có lỗi'));
	}
}

function* onRemoveRoleForMenu(action: MenusAction_REMOVE_ROLE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.RemoveRoleForMenusAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.menus.removeRoleSuccess(res.message));
			yield put(actions.menus.needReload());
		} else {
			yield put(actions.menus.removeRoleFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.menus.removeRoleFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllMenus() {
	yield takeEvery(eMenusActionTypeIds.GET_ALL_REQUEST, onLoadAllMenus);
}

function* watchOnChangeRoleForMenu() {
	yield takeEvery(eMenusActionTypeIds.CHANGE_ROLE_REQUEST, onChangeRoleForMenu);
}

function* watchOnChangeRoleForMenuMultiple() {
	yield takeEvery(eMenusActionTypeIds.CHANGE_ROLE_MULTI_REQUEST, onChangeRoleForMenuMultiple);
}

function* watchOnRemoveRoleForMenu() {
	yield takeEvery(eMenusActionTypeIds.REMOVE_ROLE_REQUEST, onRemoveRoleForMenu);
}

function* menusSaga() {
	yield all([fork(watchOnLoadAllMenus)]);
	yield all([fork(watchOnChangeRoleForMenu)]);
	yield all([fork(watchOnChangeRoleForMenuMultiple)]);
	yield all([fork(watchOnRemoveRoleForMenu)]);
}

export default menusSaga;

import moment from 'moment';
import { eArticleStatus } from './eArticleStatus';
import { eArticleGenres } from './eArticleGenres';
import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';
import { valid_base, valid_base_object } from '../../base/valid_base';

export type ArticleItemResponse = {
	id: number;
	title: string;
	titleSlug: string;
	catId: number[];
	initContent: string;
	image: string;
	status: string;
	genre: string;
	publishedOnDate: string;
	lockedBy: string;
	lockedOnDate: string;
	viewCount: number;
	wordCount: number;
	charCount: number;
	createdBy: string;
	createdOnDate: string;
	lastModifiedBy: string;
	lastModifiedOnDate: string;
	categoryName: string;
	publishType: string;
};

export type ArticleModel = {
	id: number;
	title: string;
	titleSlug: string;
	catId: number;
	initContent: string;
	contentHtml: string;
	author: string;
	authorPublish: string;
	image: string;
	imageCaption: string;
	audio: string;
	metaDescription: string;
	metaTitle: string;
	sourceLink: string;
	status: string;
	genre: string;
	isYoutube: boolean;
	linkYoutube: string;
	videoPath: string;
	publishedOnDate: string;
	relationIds: string;
	relationProductIds: string;
	tags: string;
	viewCount: number;
	wordCount: number;
	charCount: number;
	langCode: string;
	isHiddenContent: boolean;
	isAMP: boolean;
	actionStatus: string;
	publishType: string;
};

export type ArticleSaveModel = {
	id: number;
	title: string;
	titleSlug: string;
	catId: number;
	subCatIds: string | undefined;
	initContent: string | undefined;
	contentHtml: string | undefined;
	authorPublish: string | undefined;
	image: string | undefined;
	imageCaption: string | undefined;
	audio: string | undefined;
	metaDescription: string | undefined;
	metaTitle: string | undefined;
	sourceLink: string | undefined;
	status: string | undefined;
	genre: string;
	articleType: number;
	videoPath: string | undefined;
	publishedOnDate: string;
	tags: string;
	isHiddenContent: boolean;
	// isAMP: boolean;
	actionStatus: string;
	articleTypeNewsConfigs: string | undefined;
	relationIds: string | undefined;
};

export const dfArticleModel: ArticleSaveModel = {
	id: 0,
	title: '',
	titleSlug: '',
	catId: 0,
	initContent: '',
	contentHtml: '',
	authorPublish: '',
	image: '',
	imageCaption: '',
	audio: '',
	metaDescription: '',
	metaTitle: '',
	sourceLink: '',
	status: eArticleStatus.DRAFT,
	genre: eArticleGenres.CONTENT,
	articleType: 0,
	videoPath: '',
	publishedOnDate: moment().format(),
	tags: '',
	isHiddenContent: false,
	// isAMP: false,
	actionStatus: eArticleStatus.DRAFT,
	subCatIds: '',
	articleTypeNewsConfigs: '',
	relationIds: '',
};

export const validArticleSchema = valid_base_object
	.object<ArticleSaveModel>()
	.shape({
		title: valid_base.string_required('Vui lòng nhập tiêu đề bài viết.'),
		titleSlug: valid_base.string_required('Vui lòng nhập slug bài viết.'),
		catId: valid_base.number_min_required(1, 'Vui lòng chọn chuyên mục.'),
		subCatIds: valid_base.string(),
		initContent: valid_base.string(),
		contentHtml: valid_base.string(),
		authorPublish: valid_base.string(),
		image: valid_base.string(),
		imageCaption: valid_base.string(),
		audio: valid_base.string(),
		metaDescription: valid_base.string(),
		metaTitle: valid_base.string(),
		sourceLink: valid_base.string(),
		status: valid_base.string(),
		genre: valid_base.string_required('Vui lòng chọn thể loại bài viết.'),
		videoPath: valid_base.string(),
		publishedOnDate: valid_base.string_required('Vui lòng chọn ngày đăng bài viết.'),
		tags: valid_base.string_required('Vui lòng chọn tag cho bài viết.'),
		// isHiddenContent: valid_base.bool_required(),
		// isAMP: valid_base.bool_required(),
		id: valid_base.number_required(),
		actionStatus: valid_base.string_required(),
		articleType: valid_base.number_min_required(1, 'Vui lòng chọn phân loại bài viết.'),
	})
	.required();

import { valid_base, valid_base_object } from '../../../base/valid_base';

export type RoleModel = {
	id: number;
	name: string;
	description: string;
	is_active: boolean;
	is_protected: boolean;
	role_scope_ids: number[];
};

export const df_RoleModel: RoleModel = {
	id: 0,
	name: '',
	description: '',
	is_active: true,
	role_scope_ids: [],
	is_protected: false,
};

export const valid_RoleModel = valid_base_object.object().shape({
	id: valid_base.number_required(),
	name: valid_base.string_required(),
	description: valid_base.string_required(),
	is_active: valid_base.bool_required(),
	is_protected: valid_base.bool_required(),
	role_scope_ids: valid_base.number_array_required(),
});

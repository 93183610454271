export enum eArticleArchives {
	DOC_NHIEU = 'DOC_NHIEU',
	DOC_NHIEU_MUC = 'DOC_NHIEU_MUC',
	DONG_SU_KIEN = 'DONG_SU_KIEN',
	NOI_BAT_MUC = 'NOI_BAT_MUC',
	NOI_BAT_TRANG_CHU = 'NOI_BAT_TRANG_CHU',
	TIEU_DIEM_MUC = 'TIEU_DIEM_MUC',
	TIEU_DIEM_TRANG_CHU = 'TIEU_DIEM_TRANG_CHU',
	TIN_NONG = 'TIN_NONG',
	XU_HUONG_DOC = 'XU_HUONG_DOC',
}

export interface IArticleArchives {
	key: string;
	title: string;
	by_category: boolean;
	sort_order: number;
}

export const eArticleArchivesData: IArticleArchives[] = [
	{ key: eArticleArchives.DOC_NHIEU , title: 'Đọc nhiều', by_category: false, sort_order: 5 },
	{ key: eArticleArchives.DOC_NHIEU_MUC , title: 'Đọc nhiều mục', by_category: true, sort_order: 9 },
	{ key: eArticleArchives.DONG_SU_KIEN , title: 'Dòng sự kiện', by_category: false, sort_order: 6 },
	{ key: eArticleArchives.NOI_BAT_MUC , title: 'Nổi bật mục', by_category: true, sort_order: 7 },
	{ key: eArticleArchives.NOI_BAT_TRANG_CHU , title: 'Nổi bật trang chủ', by_category: false, sort_order: 1 },
	{ key: eArticleArchives.TIEU_DIEM_MUC , title: 'Tiêu điểm mục', by_category: true, sort_order: 8 },
	{ key: eArticleArchives.TIEU_DIEM_TRANG_CHU , title: 'Tiêu điểm trang chủ', by_category: false , sort_order: 2},
	{ key: eArticleArchives.TIN_NONG , title: 'Tin nóng/mới', by_category: false, sort_order: 3 },
	{ key: eArticleArchives.XU_HUONG_DOC , title: 'Xu hướng đọc', by_category: false, sort_order: 4},
];

import TagBox from 'devextreme-react/tag-box';
import ArrayStore from 'devextreme/data/array_store';
import { useCallback, useState } from 'react';

type ITagBoxRoleProps = {
	name: string;
	inputAttr: string;
	value: any;
	handleValueChange: (value: any) => void;
	label?: string;
	isLabelSmall?: boolean;
	error?: string;
	helpBlock?: string;
	placeholder?: string;
	isClearable?: boolean;
	isDisabled?: boolean;
	isSearchable?: boolean;
	isRequired?: boolean;
	itemRender?: (e: any) => React.ReactNode;
};

const TAGS = [
	{
		id: '1',
		name: 'vĩ mô',
	},
	{
		id: '2',
		name: 'chứng khoán',
	},
	{
		id: '3',
		name: 'thị trường',
	},
	{
		id: '4',
		name: 'tài chính',
	},
	{
		id: '5',
		name: 'chuyển động',
	},
	{
		id: '6',
		name: 'giá xăng',
	},
	{
		id: '7',
		name: 'giá vàng',
	},
	{
		id: '8',
		name: 'giá dầu',
	},
	{
		id: '9',
		name: 'giá lợn hơi',
	},
	{
		id: '9',
		name: 'bitcoin',
	},
	{
		id: '10',
		name: 'ethereum',
	},
];

export const TagBoxArticleTag = ({
	value,
	handleValueChange,
	name,
	label,
	isLabelSmall,
	error,
	helpBlock,
	isRequired,
	inputAttr,
	isClearable,
	isDisabled,
	isSearchable,
	placeholder,
}: ITagBoxRoleProps) => {
	const [data, setData] = useState(TAGS);

	const onCustomItemCreating = useCallback(
		(args: any) => {
			let newValue: any = args.text;
			const isItemInDataSource = data.some((item) => item === newValue);
			if (!isItemInDataSource) {
				const newValueId = data.length + 1;
				newValue = { id: newValueId, name: newValue };
				setData([newValue, ...data]);
			}
			args.customItem = newValue;
		},
		[data]
	);

	const isErrorShow = !!error;

	const convertedDataSource = new ArrayStore({ data, key: 'id' });

	return (
		<div className='form-group'>
			{label && (
				<div className='form-label-group'>
					<label className={`form-label ${isLabelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
						{label} {isRequired && <em className='text-danger'>(*)</em>}
					</label>
				</div>
			)}
			<div className='form-control-wrap'>
				<TagBox
					id={name}
					dataSource={convertedDataSource}
					value={value}
					onValueChange={(e) => handleValueChange(e)}
					valueExpr='name'
					displayExpr='name'
					searchEnabled={isSearchable}
					showClearButton={isClearable}
					disabled={isDisabled}
					inputAttr={{ 'aria-label': inputAttr }}
					placeholder={placeholder}
					acceptCustomValue
					onCustomItemCreating={onCustomItemCreating}
				/>
			</div>
			{isErrorShow && <span className='form-note invalid'>{error}</span>}
			{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
		</div>
	);
};

import { articleCategoryActions } from './articles-category/articleCategoryActions';
import { articleTypesActions } from './articles-type/articleTypeActions';
import { articleActions } from './articles/articleActions';
import { channelTypeActions } from './channel/channelTypeActions';
import { crawlArticleActions } from './crawl-articles/crawlArticleAction';
import { accountActions } from './my-system/auth/accountActions';
import { commonActions } from './my-system/common/commonActions';
import { menusActions } from './my-system/menus/menusActions';
import { roleScopesActions } from './my-system/roles/roleScopesActions';
import { rolesActions } from './my-system/roles/rolesActions';
import { userGroupActions } from './my-system/users-group/userGroupActions';
import { usersActions } from './my-system/users/usersActions';
import { royaltyActions } from './royalty/royaltyActions';
import { keywordSeoActions } from './seos/keywordSeoActions';

export const actions = {
	common: commonActions,
	menus: menusActions,
	users: usersActions,
	usersGroup: userGroupActions,
	account: accountActions,
	roles: rolesActions,
	roleScopes: roleScopesActions,
	articleCategory: articleCategoryActions,
	article: articleActions,
	articleType: articleTypesActions,
	royalty: royaltyActions,
	channel: channelTypeActions,
	keywordSeo: keywordSeoActions,
	crawlArticle: crawlArticleActions
};

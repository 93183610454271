/* eslint-disable react-refresh/only-export-components */

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { KeywordSeoModel } from '../../../context/models/seos/KeywordSeoModel';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { AppState } from '../../../context/stores/rootReducers';
import { KeywordSeoActionTypes } from '../../../context/stores/seos/IKeywordSeoActionTypes';
import { actions } from '../../../context/stores/rootActions';
import { useEffect, useMemo } from 'react';
import DxTable, { DxLookup, DxTableColumn } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import React from 'react';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import KeywordSeoDetail from './KeywordSeoDetail';
import { ConfirmModal } from '../../../components/common/ConfirmModal';

type IKeywordSeoPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allKeywords: KeywordSeoModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<KeywordSeoModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: KeywordSeoModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<KeywordSeoModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `từ khóa SEO`,
		status: state.keywordSeo.status,
		allKeywords: state.keywordSeo.allKeywords,
		selectedIds: state.keywordSeo.selectedIds,
		showDetail: state.keywordSeo.showDetail,
		showConfirm: state.keywordSeo.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<KeywordSeoActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.keywordSeo.getAllRequest());
	},
	handleSaveData: (data: KeywordSeoModel) => {
		dispatch(actions.keywordSeo.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.keywordSeo.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.keywordSeo.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<KeywordSeoModel>) => {
		dispatch(actions.keywordSeo.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.keywordSeo.showConfirm(isShow));
	},
});

const KeywordSeoPage = (props: IKeywordSeoPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allKeywords,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	// const dataSource = useMemo(() => {
	// 	return allRoyalties.map((royalty) => ({
	// 		...royalty,
	// 		minView: toNumberWithPeriod(parseInt(royalty.minView)),
	// 		money: `${toNumberWithPeriod(parseInt(royalty.money, 10))} ₫`,
	// 	}));
	// }, [allRoyalties]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên`} width={300}></DxTableColumn>,
			<DxTableColumn
				groupIndex={0}
				key={createUUID()}
				dataField='channelId'
				width={150}
				caption={`Cha`}
				autoExpandGroup={true}
				calculateCellValue={(rowData: any) => {
					return rowData.nameParent;
				}}
				groupCellRender={(cell: any) => {
					const count = cell.data.items == null ? cell.data.collapsedItems?.length : cell.data.items?.length;
					return (
						<span className='text-primary'>
							{cell.key || 'Nhóm từ khóa'} <em>({count})</em>
						</span>
					);
				}}
			>
				<DxLookup dataSource={allKeywords} valueExpr='id' displayExpr='name' />
			</DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='volume' width={100} caption={`Volume`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='kd' caption={`KD`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='cpc' caption={`CPC`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='keywordType' caption={`Loại từ khóa`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='link' caption={`Link`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allKeywords]);

	return (
		<React.Fragment>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allKeywords}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<KeywordSeoDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) =>
							handleSaveData(
								Object.assign(data, {
									volume: data.volume.replace('.', ''),
								})
							)
						}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</React.Fragment>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(KeywordSeoPage);

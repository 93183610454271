import { BasePaginationResponse } from '../../../base/BasePaginationResponse';
import { UserModel } from '../../../models/my-system/users/UserModel';
import { UserSelectRequest } from '../../../models/my-system/users/UserSelectRequest';
import { BaseAction } from '../../../base/IBaseActionRespone';
import {
	UsersAction_CHANGE_SELECTED_IDS,
	UsersAction_DELETE_Failure,
	UsersAction_DELETE_Request,
	UsersAction_DELETE_Success,
	UsersAction_GETALL_Failure,
	UsersAction_GETALL_Request,
	UsersAction_GETALL_Success,
	UsersAction_GETPAGINATION_Failure,
	UsersAction_GETPAGINATION_Request,
	UsersAction_GETPAGINATION_Success,
	UsersAction_RELOAD,
	UsersAction_SAVE_Failure,
	UsersAction_SAVE_Request,
	UsersAction_SAVE_Success,
	UsersAction_SHOW_CONFIRM,
	UsersAction_SHOW_DETAIL,
	eUsersActionTypeIds,
} from './IUsersActionsTypes';
import { BaseShowDetail } from '../../../base/BaseShowDetail';

export const usersActions = {
	//ALL
	getAllRequest: (): UsersAction_GETALL_Request => BaseAction(eUsersActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: UserModel[]): UsersAction_GETALL_Success => BaseAction(eUsersActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): UsersAction_GETALL_Failure => BaseAction(eUsersActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: UserSelectRequest): UsersAction_GETPAGINATION_Request =>
		BaseAction(eUsersActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data?: BasePaginationResponse<UserModel>): UsersAction_GETPAGINATION_Success =>
		BaseAction(eUsersActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): UsersAction_GETPAGINATION_Failure =>
		BaseAction(eUsersActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: UserModel): UsersAction_SAVE_Request => BaseAction(eUsersActionTypeIds.SAVE_SAVING, entity),
	saveSuccess: (message: string): UsersAction_SAVE_Success => BaseAction(eUsersActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): UsersAction_SAVE_Failure => BaseAction(eUsersActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): UsersAction_DELETE_Request => BaseAction(eUsersActionTypeIds.DELETE_SAVING, ids),
	deleteSuccess: (message: string): UsersAction_DELETE_Success => BaseAction(eUsersActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): UsersAction_DELETE_Failure => BaseAction(eUsersActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): UsersAction_RELOAD => BaseAction(eUsersActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): UsersAction_CHANGE_SELECTED_IDS => BaseAction(eUsersActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<UserModel>): UsersAction_SHOW_DETAIL => BaseAction(eUsersActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): UsersAction_SHOW_CONFIRM => BaseAction(eUsersActionTypeIds.SHOW_CONFIRM, show),
};

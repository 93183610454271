/* eslint-disable react-refresh/only-export-components */

import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import no_img from '../../../assets/images/picture.png';
import { ButtonLink, LoadingTable } from '../../../components/base';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { aticleCrawlerApi } from '../../../context/api/articles/aticleCrawlerApi';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { ArticleCrawlerModel } from '../../../context/models/crawlers/ArticleCrawlerModel';
import { createUUID } from '../../../utils/createUUID';

const ArticleCrawlerPage = () => {
	const titlePage = 'Danh sách tin bài mới';
	const navigate = useNavigate();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [articles, setArticles] = useState<ArticleCrawlerModel[]>([]);

	const handleReloadAllData = async () => {
		setIsLoading(true);
		setArticles([]);
		const response = await aticleCrawlerApi.GetAsync();
		if (response.isSuccess) {
			setArticles(response.result as ArticleCrawlerModel[]);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		handleReloadAllData();
	}, []);

	// const dataSource = useMemo(() => {
	// 	return allRoyalties.map((royalty) => ({
	// 		...royalty,
	// 		minView: toNumberWithPeriod(parseInt(royalty.minView)),
	// 		money: `${toNumberWithPeriod(parseInt(royalty.money, 10))} ₫`,
	// 	}));
	// }, [allRoyalties]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='title'
				caption={`Tiêu đề`}
				minWidth={300}
				fixed
				cellRender={(cell: any) => {
					const article = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='article-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={article.images?.length > 0 ? article.images : no_img} alt='ảnh đại diện' />
								</div>
								<div className='user-info'>
									<h6 className='tb-lead'>
										<div className='fw-bold'>
											<em className='ni ni-article text-azure fs-16px'></em>
											<span className='bold _neo_article_TITLE fs-18px'>{article.title}</span>
										</div>
									</h6>
									<p className='tb-sapo d-none d-sm-block d-md-block p-0 m-0 pb-1'>
										<em>{article.summary}</em>
									</p>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Tác giả: <b>{article.author}</b>, NXB: <b>{article.datePublic}</b>
									</p>
									<p className='tb-sapo d-none d-sm-block d-md-block p-0 m-0 pb-1'>
										<small>{article.tag}</small>
									</p>
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='category' width={150} caption={`Chuyên mục`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={80}
				caption={'Xem chi tiết'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink
							onClick={() => navigate(`${location.pathname}/preview/${cell.data.id}`, { replace: false })}
							title='Xem'
							icon='ni ni-eye'
							theme='link'
						/>
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'></div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						<DxTable
							dataSource={articles}
							keyExpr='id'
							columns={columns}
							hasFixed={true}
							isLoading={isLoading}
							defaultPageSize={50}
							filters={{
								refreshDataGrid: handleReloadAllData,
							}}
							wordWrapEnabled={true}
						/>
					</BlockCard>
				</PageBody>
			</PageContainer>
		</React.Fragment>
	);
};

export default ArticleCrawlerPage;

import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { CrawlArticleModel } from '../../models/crawl-articles/CrawlArticleModel';
import {
	CrawlArticleAction_CHANGE_SELECTED_IDS,
	CrawlArticleAction_DELETE_Request,
	CrawlArticleAction_DELETE_Success,
	CrawlArticleAction_GETALL_Failure,
	CrawlArticleAction_GETALL_Request,
	CrawlArticleAction_GETALL_Success,
	CrawlArticleAction_GETPAGINATION_Failure,
	CrawlArticleAction_GETPAGINATION_Request,
	CrawlArticleAction_GETPAGINATION_Success,
	CrawlArticleAction_RELOAD,
	CrawlArticleAction_SAVE_Request,
	CrawlArticleAction_SAVE_Success,
	CrawlArticleAction_SHOW_CONFIRM,
	CrawlArticleAction_SHOW_DETAIL,
	eCrawlArticleActionTypeIds,
} from './ICrawlArticlesActionTypes';

export const crawlArticleActions = {
	getAllRequest: (): CrawlArticleAction_GETALL_Request => BaseAction(eCrawlArticleActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: CrawlArticleModel): CrawlArticleAction_GETALL_Success =>
		BaseAction(eCrawlArticleActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (err: Error | string): CrawlArticleAction_GETALL_Failure => BaseAction(eCrawlArticleActionTypeIds.GET_ALL_FAILURE, err),

	getPaginationRequest: (req: BaseSelectRequest): CrawlArticleAction_GETPAGINATION_Request =>
		BaseAction(eCrawlArticleActionTypeIds.GET_PAGINATION_REQUEST, req),
	getPaginationSuccess: (data: BasePaginationResponse<CrawlArticleModel>): CrawlArticleAction_GETPAGINATION_Success =>
		BaseAction(eCrawlArticleActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (err: Error | string): CrawlArticleAction_GETPAGINATION_Failure =>
		BaseAction(eCrawlArticleActionTypeIds.GET_PAGINATION_FAILURE, err),

	saveRequest: (payload: CrawlArticleModel): CrawlArticleAction_SAVE_Request =>
		BaseAction(eCrawlArticleActionTypeIds.SAVE_REQUEST, payload),
	saveSuccess: (msg: string): CrawlArticleAction_SAVE_Success => BaseAction(eCrawlArticleActionTypeIds.SAVE_SUCCESS, msg),
	saveFailure: (err: Error | string) => BaseAction(eCrawlArticleActionTypeIds.SAVE_FAILURE, err),

	deleteRequest: (payload: number[]): CrawlArticleAction_DELETE_Request => BaseAction(eCrawlArticleActionTypeIds.DELETE_REQUEST, payload),
	deleteSuccess: (msg: string): CrawlArticleAction_DELETE_Success => BaseAction(eCrawlArticleActionTypeIds.DELETE_SUCCESS, msg),
	deleteFailure: (err: Error | string) => BaseAction(eCrawlArticleActionTypeIds.DELETE_FAILURE, err),

	reload: (): CrawlArticleAction_RELOAD => BaseAction(eCrawlArticleActionTypeIds.RELOAD, undefined),
	showDetail: (detail: BaseShowDetail<CrawlArticleModel>): CrawlArticleAction_SHOW_DETAIL =>
		BaseAction(eCrawlArticleActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (isShow: boolean): CrawlArticleAction_SHOW_CONFIRM => BaseAction(eCrawlArticleActionTypeIds.SHOW_CONFIRM, isShow),
	changeSelectedIds: (payload: number[]): CrawlArticleAction_CHANGE_SELECTED_IDS =>
		BaseAction(eCrawlArticleActionTypeIds.CHANGE_SELECTED_IDS, payload),
};

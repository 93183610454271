import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { KeywordSeoModel } from '../../models/seos/KeywordSeoModel';
import {
	KeywordSeoAction_CHANGE_SELECTED_IDS,
	KeywordSeoAction_DELETE_Request,
	KeywordSeoAction_DELETE_Success,
	KeywordSeoAction_GETALL_Failure,
	KeywordSeoAction_GETALL_Request,
	KeywordSeoAction_GETALL_Success,
	KeywordSeoAction_GETPAGINATION_Failure,
	KeywordSeoAction_GETPAGINATION_Request,
	KeywordSeoAction_GETPAGINATION_Success,
	KeywordSeoAction_RELOAD,
	KeywordSeoAction_SAVE_Request,
	KeywordSeoAction_SAVE_Success,
	KeywordSeoAction_SHOW_CONFIRM,
	KeywordSeoAction_SHOW_DETAIL,
	eKeywordSeoActionTypeIds,
} from './IKeywordSeoActionTypes';

export const keywordSeoActions = {
	getAllRequest: (): KeywordSeoAction_GETALL_Request => BaseAction(eKeywordSeoActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: KeywordSeoModel[]): KeywordSeoAction_GETALL_Success => BaseAction(eKeywordSeoActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (err: Error | string): KeywordSeoAction_GETALL_Failure => BaseAction(eKeywordSeoActionTypeIds.GET_ALL_FAILURE, err),

	getPaginationRequest: (req: BaseSelectRequest): KeywordSeoAction_GETPAGINATION_Request =>
		BaseAction(eKeywordSeoActionTypeIds.GET_PAGINATION_REQUEST, req),
	getPaginationSuccess: (data: BasePaginationResponse<KeywordSeoModel>): KeywordSeoAction_GETPAGINATION_Success =>
		BaseAction(eKeywordSeoActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (err: Error | string): KeywordSeoAction_GETPAGINATION_Failure =>
		BaseAction(eKeywordSeoActionTypeIds.GET_PAGINATION_FAILURE, err),

	saveRequest: (payload: KeywordSeoModel): KeywordSeoAction_SAVE_Request => BaseAction(eKeywordSeoActionTypeIds.SAVE_REQUEST, payload),
	saveSuccess: (msg: string): KeywordSeoAction_SAVE_Success => BaseAction(eKeywordSeoActionTypeIds.SAVE_SUCCESS, msg),
	saveFailure: (err: Error | string) => BaseAction(eKeywordSeoActionTypeIds.SAVE_FAILURE, err),

	deleteRequest: (payload: number[]): KeywordSeoAction_DELETE_Request => BaseAction(eKeywordSeoActionTypeIds.DELETE_REQUEST, payload),
	deleteSuccess: (msg: string): KeywordSeoAction_DELETE_Success => BaseAction(eKeywordSeoActionTypeIds.DELETE_SUCCESS, msg),
	deleteFailure: (err: Error | string) => BaseAction(eKeywordSeoActionTypeIds.DELETE_FAILURE, err),

	reload: (): KeywordSeoAction_RELOAD => BaseAction(eKeywordSeoActionTypeIds.RELOAD, undefined),
	showDetail: (detail: BaseShowDetail<KeywordSeoModel>): KeywordSeoAction_SHOW_DETAIL =>
		BaseAction(eKeywordSeoActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (isShow: boolean): KeywordSeoAction_SHOW_CONFIRM => BaseAction(eKeywordSeoActionTypeIds.SHOW_CONFIRM, isShow),
	changeSelectedIds: (payload: number[]): KeywordSeoAction_CHANGE_SELECTED_IDS =>
		BaseAction(eKeywordSeoActionTypeIds.CHANGE_SELECTED_IDS, payload),
};

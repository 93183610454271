import React, { useMemo } from 'react';

interface BaiVietCount {
	key: string;
	title: string;
	totals: number;
}

const SoLieu = () => {
	const counts = useMemo(() => {
		const result: BaiVietCount[] = [
			{
				key: 'ds_tong',
				title: 'Tổng bài viết',
				totals: 149000,
			},
			{
				key: 'cvl_tong',
				title: 'Bài mới xuất bản (trong tháng)',
				totals: 3421,
			},
			{
				key: 'tn_tong',
				title: 'Tổng lượt truy cập',
				totals: 123423312,
			},
			{
				key: 'khdkt_tong',
				title: 'Tổng lượt truy cập (trong tháng)',
				totals: 127675,
			},
		];
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className='nk-block py-2'>
				<div className='row g-gs'>
					{counts.map((data, idx) => {
						return (
							<div key={idx} className='col-xxl-3 col-sm-3'>
								<div className='nk-wg-card is-s1 card card-bordered'>
									<div className='card-inner'>
										<div className='nk-iv-wg2'>
											<div className='nk-iv-wg2-title'>
												<h6 className='title'>
													{data.title} <em className='icon ni ni-info'></em>
												</h6>
											</div>
											<div className='nk-iv-wg2-text'>
												<div className='nk-iv-wg2-amount'>{new Intl.NumberFormat(['ban', 'id']).format(data.totals)}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default SoLieu;

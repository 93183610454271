import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api/rootApi';
import { BaseResponse } from '../../base/BaseResponse';
import { actions } from '../rootActions';
import { ChannelModel } from '../../models/channel/ChannelModel';
import {
	ChannelTypeAction_DELETE_Request,
	ChannelTypeAction_GETPAGINATION_Request,
	ChannelTypeAction_SAVE_Request,
	eChannelActionTypeIds,
} from './IChannelActionsTypes';

const _sagaApi = rootApi.channel;

function* onLoadAllChannels() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listChannels = res.result as ChannelModel[];
			yield put(actions.channel.getAllSuccess(listChannels));
		} else {
			yield put(actions.channel.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.channel.getAllFailure(err || 'Có lỗi'));
	}
}

function* onLoadPaginationChannels(action: ChannelTypeAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listChannels = res.result as ChannelModel[];
			yield put(actions.channel.getPaginationSuccess({ listDatas: listChannels, pagination: res.pagination }));
		} else {
			yield put(actions.channel.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.channel.getPaginationFailure(err || 'Có lỗi'));
	}
}

function* onSaveChannel(action: ChannelTypeAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.channel.saveSuccess(res.message));
				yield put(actions.channel.reload());
			} else {
				yield put(actions.channel.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.channel.saveSuccess(res.message));
				yield put(actions.channel.reload());
			} else {
				yield put(actions.channel.saveFailure(res.message));
			}
		}
	} catch (err: any) {
		yield put(actions.channel.saveFailure(err || 'Có lỗi'));
	}
}

function* onDeleteChannel(action: ChannelTypeAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.channel.deleteSuccess(res.message));
			yield put(actions.channel.reload());
		} else {
			yield put(actions.channel.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.channel.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllChannels() {
	yield takeEvery(eChannelActionTypeIds.GET_ALL_REQUEST, onLoadAllChannels);
}

function* watchOnLoadPaginationChannels() {
	yield takeEvery(eChannelActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationChannels);
}

function* watchOnSaveChannel() {
	yield takeEvery(eChannelActionTypeIds.SAVE_REQUEST, onSaveChannel);
}

function* watchOnDeleteChannel() {
	yield takeEvery(eChannelActionTypeIds.DELETE_REQUEST, onDeleteChannel);
}

function* channelSaga() {
	yield all([fork(watchOnLoadAllChannels)]);
	yield all([fork(watchOnLoadPaginationChannels)]);
	yield all([fork(watchOnSaveChannel)]);
	yield all([fork(watchOnDeleteChannel)]);
}

export default channelSaga;

import { toast } from 'react-toastify';
import { Button } from '../components/base';
import { useNavigate } from 'react-router-dom';

export type NotifyResponse = {
	title: string;
	time_ago?: string;
	message?: string;
	redirect_url?: string;
};

const MessageBody = (data: NotifyResponse) => {
	const navigate = useNavigate();
	const { title, time_ago, message, redirect_url } = data;
	return (
		<>
			<div className='toast-header'>
				<strong className='me-auto text-primary'>{title}</strong>
				<small>{time_ago || 'Vừa xong'}</small>
			</div>
			<div className='toast-body'>
				<div>{message}</div>
				{redirect_url && <Button text='Đi tới' onClick={() => navigate(redirect_url || '/', { replace: false })} />}
			</div>
		</>
	);
};

export const NotifyHelper = {
	Success: (message?: string) => (message ? toast.success(message) : null),
	Error: (message?: string) => (message ? toast.error(message) : null),
	Info: (message?: string) => (message ? toast.info(message) : null),
	Warning: (message?: string) => (message ? toast.warning(message) : null),
	Notify: (data: NotifyResponse) =>
		toast(<MessageBody {...data}/>, { position: 'bottom-right', closeButton: false, delay: 2000 }),
};

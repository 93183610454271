import { useCallback, useEffect, useMemo, useState } from 'react';
import DxTable, { DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import { Button, ButtonLink } from '../../../components/base';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { actions } from '../../../context/stores/rootActions';
import ArticlePublishedSelectionModal from '../article-published-selection';

type RelatedArticlesProps = {
	articleIds: string;
	onValueChanged: (value: string) => void;
};

export const RelatedArticles = (props: RelatedArticlesProps) => {
	const { articleIds, onValueChanged } = props;
	const dispatch = useDispatch();
	const [showAddModal, setShowAddModal] = useState(false);
	const { status, relatedResponse } = useSelector((state: AppState) => state.article);

	const relatedIds = useMemo(() => {
		const result = articleIds.length > 0 ? articleIds.split(',').map((item) => parseInt(item)) : [];
		return result;
	}, [articleIds]);

	const [relatedArticles, setRelatedArticles] = useState(relatedResponse);

	const reloadRelatedArticles = useCallback(() => {
		if (relatedIds.length > 0) dispatch(actions.article.getRelatedRequest({ article_related_ids: relatedIds }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [relatedIds]);

	const onReorder = useCallback(
		(e: any) => {
			const visibleRows = e.component.getVisibleRows();
			const newRelatedArticles = [...relatedArticles];

			const toIndex = newRelatedArticles.findIndex((item) => item.id === visibleRows[e.toIndex].data.id);
			const fromIndex = newRelatedArticles.findIndex((item) => item.id === e.itemData.id);

			newRelatedArticles.splice(fromIndex, 1);
			newRelatedArticles.splice(toIndex, 0, e.itemData);

			setRelatedArticles(newRelatedArticles);
		},
		[relatedArticles]
	);

	const handleDeleteAction = useCallback(
		(articleId: any) => {
			const newRelatedArticles = relatedArticles.filter((article) => article.id !== articleId);
			setRelatedArticles(newRelatedArticles);
			onValueChanged(newRelatedArticles.map((item) => item.id).join(','));
		},
		[relatedArticles]
	);

	useEffect(() => {
		reloadRelatedArticles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [relatedIds]);

	useEffect(() => {
		setRelatedArticles(relatedResponse);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [relatedResponse]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn key={createUUID()} dataField='title' caption={`Tiêu đề`} minWidth={160} fixed />,
			<DxTableColumn key={createUUID()} dataField='category' caption={`Chuyên mục`} width={100} />,
		];

		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Xóa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => (
					<ButtonLink onClick={() => handleDeleteAction(cell.data.id)} title='Xóa' icon='ni ni-trash' theme='link' />
				)}
			/>
		);

		return result;
	}, [handleDeleteAction]);

	const toolbars = useMemo(() => {
		const result: any = [];
		result.push(
			<DxToolbarItem key={createUUID()} location='before'>
				<Button
					text='Thêm bài viết liên quan'
					icon='icon ni ni-plus'
					theme='primary'
					className='d-md-inline-flex'
					onClick={() => setShowAddModal(true)}
				/>
			</DxToolbarItem>
		);
		return result;
	}, []);

	return (
		<>
			<DxTable
				dataSource={relatedArticles}
				keyExpr='id'
				columns={columns}
				toolbars={toolbars}
				hasFixed={true}
				// isLoading={status !== eBaseActionStatus.complete}
				defaultPageSize={50}
				wordWrapEnabled={true}
				// filters={{
				// 	refreshDataGrid: handleReloadDataWithFilter,
				// }}
				// selection={{
				// 	mode: 'multiple',
				// 	onSelectionChanged: (e: any) => {
				// 		onChangeSelectedIds(e.selectedRowKeys || []);
				// 	},
				// 	selectedRowKeys: selectedIds,
				// }}
				height='auto'
				allowReordering={true}
				onReorder={onReorder}
			/>
			{showAddModal && (
				<ArticlePublishedSelectionModal
					title='Chọn bài viết liên quan'
					onClose={() => setShowAddModal(false)}
					onSelectedValueChanged={(value) => {
						setShowAddModal(false);
						onValueChanged(value.join(','));
					}}
				/>
			)}
		</>
	);
};

import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import DxTable, { DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { useLookupRoles } from '../../../components/shared/system';
import { BadgesRole } from '../../../components/shared/system/badges-role/BadgesRole';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { RoleModel } from '../../../context/models/my-system/roles/RoleModel';
import { UserGroupModel } from '../../../context/models/my-system/users-group/UserGroupModel';
import { UserGroupActionTypes } from '../../../context/stores/my-system/users-group/IUserGroupActionTypes';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import UsersGroupDetailModal from './detail-modal';

type IUsersGroupPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	users_group_all: UserGroupModel[];
	allRoles: RoleModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<UserGroupModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: UserGroupModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<UserGroupModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Vai trò`,
		status: state.usersGroup.status,
		users_group_all: state.usersGroup.users_group_all,
		selectedIds: state.usersGroup.selectedIds,
		showDetail: state.usersGroup.showDetail,
		showConfirm: state.usersGroup.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<UserGroupActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.usersGroup.getAllRequest());
	},
	handleSaveData: (data: UserGroupModel) => {
		dispatch(actions.usersGroup.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.usersGroup.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.usersGroup.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<UserGroupModel>) => {
		dispatch(actions.usersGroup.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.usersGroup.showConfirm(isShow));
	},
});

const UsersGroupPage = (props: IUsersGroupPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		users_group_all,
		handleReloadAllData,
		onChangeSelectedIds,
		handleDeleteData,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	const { roles_lookup } = useLookupRoles();

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn allowHeaderFiltering={false} key={createUUID()} dataField='name' caption={`Tên`} width={150}></DxTableColumn>,
			<DxTableColumn allowHeaderFiltering={false} key={createUUID()} dataField='description' width={150} caption={`Mô tả`}></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='role_ids'
				minWidth={280}
				caption={`Quyền hạn`}
				// dataType={'object'}
				cellRender={(cell: any) => {
					return <BadgesRole roles_all={roles_lookup} value={cell.value.map((x: any) => parseInt(x))} />;
				}}
				calculateCellValue={(rowData: any) => {
					return rowData.role_ids.split(',').map((x: any) => parseInt(x));
				}}
			>
				{/* <DxLookup dataSource={roles_lookup} valueExpr='id' displayExpr='name' /> */}
			</DxTableColumn>,
			<DxTableColumn
				allowHeaderFiltering={false}
				key={createUUID()}
				dataField='is_active'
				caption={`Áp dụng`}
				width={80}
				trueText='Áp dụng'
				falseText='Không áp dụng'
			></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roles_lookup]);

	const toolBars = useMemo(() => {
		const result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<span>
					Tổng số: <b>{users_group_all.length}</b>{' '}
				</span>
			</DxToolbarItem>,
		];
		return result;
	}, [users_group_all]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={users_group_all}
								keyExpr='id'
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								isHeaderFilter={true}
								exportOptions={{
									title: `${titlePage}`,
									hasExportSelected: true,
									hasHeader: true,
									headerMerge: { leftIndex: 2, rightIndex: columns?.length },
								}}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								toolbars={toolBars}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
								wordWrapEnabled={true}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<UsersGroupDetailModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersGroupPage);

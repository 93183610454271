import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationResponse, defaultBasePagination } from '../../base/BasePaginationResponse';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { RoyaltyModel } from '../../models/royalty/RoyaltyModel';
import { RoyaltyActionTypes, eRoyaltyActionTypeIds } from './IRoyaltyActionsTypes';
import { IRoyaltyState } from './IRoyaltyState';

const initPaginationResponse: BasePaginationResponse<RoyaltyModel> = {
	listDatas: [],
	pagination: defaultBasePagination,
};

const initialState: IRoyaltyState = {
	status: eBaseActionStatus.idle,
	allRoyalties: [],
	selectedIds: [],
	showConfirm: false,
	showDetail: { isShow: false },
	paginationResponse: initPaginationResponse,
};

const royaltyReducer = (state: IRoyaltyState = initialState, action: RoyaltyActionTypes): IRoyaltyState => {
	switch (action.type) {
		case eRoyaltyActionTypeIds.GET_ALL_REQUEST:
		case eRoyaltyActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allRoyalties: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allRoyalties: [],
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				allRoyalties: [],
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eRoyaltyActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eRoyaltyActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm thiết lập nhuận bút mới thành công!');
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Xóa thiết lập nhuận bút thành công!');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eRoyaltyActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		default:
			return state;
	}
};

export default royaltyReducer;

import { valid_base, valid_base_object } from '../../../base/valid_base';

export type UserResponse = {
	id: number;
	user_name: string;
	full_name: string;
	user_group_id: number;
	khoi_id: number;
	chuyenvien_id: number;
	hoso_id: number;
	donvi_id: number;
	user_type: string;
	avatar: string;
	email: string;
	phone: string;
	is_active: boolean;
	is_protected: boolean;
	last_login_on_date: string;
	unlock_date: string;
	but_danh: string;
};

export type UserModel = {
	id: number;
	user_name: string;
	full_name: string;
	user_group_id: number;
	avatar?: string;
	email?: string;
	phone?: string;
	is_active: boolean;
	is_protected: boolean;
	password_new?: string;
	password_confirmed?: string;
	role_ids: number[];
	categrory_ids: number[];
	but_danh: string;
};

export const df_UserModel: UserModel = {
	id: 0,
	user_name: '',
	full_name: '',
	email: '',
	is_active: true,
	role_ids: [],
	user_group_id: 0,
	is_protected: false,
	categrory_ids: [],
	but_danh: '',
};

export const valid_UserModel = (mode: 'add' | 'edit') =>
	valid_base_object.object().shape({
		email: valid_base.email_required(true),
		user_name: valid_base.string_required('Vui lòng nhập Tài khoản.'),
		full_name: valid_base.string_required('Vui lòng nhập Tên đầy đủ.'),
		user_group_id: valid_base.number_min_required(1, 'Vui lòng chọn Tác nhân (Nhóm quyền).'),
		role_ids: valid_base.number_array_required(),
		password_new: valid_base.password(mode),
		password_confirmed: valid_base.password_confirmed('password_new', mode),
		is_active: valid_base.bool_required(),
		id: valid_base.number_required(),
		is_protected: valid_base.bool_required(),
		categrory_ids: valid_base.number_array_required(),
		but_danh: valid_base.string_required('Vui lòng nhập bút danh.'),
	});

import React, { useMemo } from 'react';
interface ITextAreaBoxProps {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	label?: string;
	error?: string;
	rows?: number;
	sizeClass?: 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	disabled?: boolean;
	hasValid?: boolean;
	className?: string;
	classWrapper?: string;
	isViewMode?: 'dot_bottom' | 'line_bottom';
}

export const TextAreaBox = (props: ITextAreaBoxProps) => {
	const {
		error,
		name,
		value,
		label,
		rows,
		sizeClass,
		disabled,
		helpBlock,
		labelSmall,
		hasValid,
		placeholder,
		className,
		classWrapper,
		onValueChanged,
		isViewMode,
	} = props;
	const showError = error ? true : false;
	const classBuild = useMemo(() => {
		let classBuild = `form-control form-control-${sizeClass || 'sm'} textarea-${sizeClass || 'sm'}`;
		if (showError) classBuild = `${classBuild} error`;
		if (className) classBuild = `${classBuild} ${className}`;
		if (isViewMode) classBuild = `${classBuild} ${isViewMode}`;
		return classBuild;
	}, [sizeClass, showError, className, isViewMode]);
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap ${classWrapper}`}>
					<textarea
						id={name}
						name={name}
						disabled={disabled || (isViewMode && isViewMode.length > 0)}
						placeholder={placeholder}
						rows={rows}
						value={value}
						onChange={(e) => onValueChanged(e.target.value)}
						className={classBuild}
					></textarea>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};

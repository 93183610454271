import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountInfo } from '../context/models/my-system/accounts';
import { AuthContext } from './AuthContext';
import { NotifyHelper } from '../utils/NotifyHelper';
import NotifyConnector  from '../utils/SignalrConnection';

const AuthProvider = ({ children, account, logOutAction }: { children: any; account: AccountInfo | null, logOutAction: () => void }) => {
	const [user, setUser] = useState<AccountInfo | null>(account);
	const navigate = useNavigate();
	const { events } = NotifyConnector();

	useEffect(() => {
		events((message: any) => {
			if (message) NotifyHelper.Notify(message);
		});
	}, [events]);

	// call this function when you want to authenticate the acount
	const login = (data: AccountInfo | null) => {
		setUser(data);
		navigate('/', { replace: true });
	};

	// call this function to sign out logged in user
	const logout = () => {
		setUser(null);
		logOutAction();
		// navigate('/login', { replace: true });
	};

	const value = useMemo(() => {
		return {
			account: user,
			menus: user?.menus || [],
			login,
			logout,
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext };
export default AuthProvider;

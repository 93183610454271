import React from 'react';
import { DxConnectorPieChart, DxFontPieChart, DxLabelPieChart, DxLegendPieChart, DxPieChart, DxSeriesPieChart, DxTitlePieChart } from '../../../components/common/DxCharts';
import { createUUID } from '../../../utils/createUUID';

const energySources = [
	{ value: 'thang_nay', name: 'Tháng này' },
	{ value: 'thang_truoc', name: 'Tháng trước' },
];

const BieuDoLuotTruyCapPieChart = () => {
    const khoiSoLieuGrouped = Array.from({ length: 10 }, (_, idx) => ({
        ten_khoi: `Chuyên mục ${idx + 1}`,
        thang_nay: Math.floor(Math.random() * 100),
        thang_truoc: Math.floor(Math.random() * 100),
    }));
	return (
		<>
			<div className='row g-gs justify-content-center'>
				{khoiSoLieuGrouped.map((khoi, idx) => {
					const data: any = khoi;
					const soLieuTheoKhois = energySources.map((x) => ({
						name: x.name,
						total: data[x.value] || 0,
					}));
					return (
						<div className='col-md-3 col-xxl-4' key={idx}>
							<div className='card h-100'>
								<div className='card-inner p-0'>
									<DxPieChart
										id={createUUID()}
										palette='Dark Violet'
										dataSource={soLieuTheoKhois}
										type='doughnut'
										// centerRender={(pieChart) => {
										//   return (
										//        <svg>
										//             <circle cx='100' cy='100' r={pieChart.getInnerRadius() - 6} fill='#CBCCFF'></circle>
										//             <text textAnchor='middle' x='100' y='105' style={{ fill: '#494949', fontWeight: 600 }}>
										//                  <tspan x='100'>{khoi.ten_khoi}</tspan>
										//             </text>
										//        </svg>
										//   );
										// }}
										resolveLabelOverlapping={'shift'}
										className='w-100'
										height={240}
									>
										<DxTitlePieChart text={khoi.ten_khoi}>
											<DxFontPieChart size={12} weight={600} />
										</DxTitlePieChart>
										<DxLegendPieChart
											orientation='horizontal'
											itemTextPosition='right'
											horizontalAlignment='center'
											verticalAlignment='bottom'
											// columnCount={5}
										/>
										<DxSeriesPieChart argumentField='name' valueField='total'>
											<DxLabelPieChart visible={true} position='columns'>
												<DxConnectorPieChart visible={true} width={0.5} />
											</DxLabelPieChart>
										</DxSeriesPieChart>
									</DxPieChart>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default BieuDoLuotTruyCapPieChart;

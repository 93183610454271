import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { CrawlArticleActionTypes, eCrawlArticleActionTypeIds } from './ICrawlArticlesActionTypes';
import { ICrawlArticlesState } from './ICrawlArticlesState';

// const initPaginationResponse: BasePaginationResponse<CrawlArticleModel> = {
// 	listDatas: [],
// 	pagination: defaultBasePagination,
// };

const initialState: ICrawlArticlesState = {
	status: eBaseActionStatus.idle,
	allArticles: {
    datasets: [],
    pagination: {
      current_page: 1,
      total_pages: 1,
      page_size: 100,
      total_records: 0
    }
  },
	selectedIds: [],
	showConfirm: false,
	showDetail: { isShow: false },
	// paginationResponse: initPaginationResponse,
};

const crawlArticleReducer = (state: ICrawlArticlesState = initialState, action: CrawlArticleActionTypes): ICrawlArticlesState => {
	switch (action.type) {
		case eCrawlArticleActionTypeIds.GET_ALL_REQUEST:
		case eCrawlArticleActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allArticles: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allArticles: {
          datasets: [],
          pagination: {
            current_page: 1,
            total_pages: 1,
            page_size: 100,
            total_records: 0
          }
        },
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				allArticles: {
          datasets: [],
          pagination: {
            current_page: 1,
            total_pages: 1,
            page_size: 100,
            total_records: 0
          }
        },
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eCrawlArticleActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eCrawlArticleActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm từ khóa mới thành công!');
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Xóa từ khóa thành công!');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eCrawlArticleActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		default:
			return state;
	}
};

export default crawlArticleReducer;

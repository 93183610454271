import { articleRoutes } from './pages/articles/articleRoutes';
import { channelRoutes } from './pages/channels/channelRoutes';
import { Dashboard } from './pages/home';
import { mySystemRoutes } from './pages/my-system/mySystemRoutes';
import { royaltyRoutes } from './pages/royalties/royaltyRoutes';
import { seoRoutes } from './pages/seos/seoRoutes';
import { videoRoutes } from './pages/videos/videoRoutes';
import { crawlerRoutes } from './pages/crawler/crawlerRoutes';

export type AppComponent = {
	title: string;
	icon?: string;
	path: string;
	component: any;
};

const AppRoutes = [
	{
		title: 'Dashboard',
		path: '/',
		component: Dashboard,
	},
	...mySystemRoutes,
	...articleRoutes,
	...royaltyRoutes,
	...channelRoutes,
	...videoRoutes,
	...seoRoutes,
	...crawlerRoutes,
] as AppComponent[];
export default AppRoutes.map((route) => {
	return {
		...route,
		Component: route.component,
	};
});

import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { ChannelModel } from '../../models/channel/ChannelModel';

export enum eChannelActionTypeIds {
	GET_ALL_REQUEST = 'Channel_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'Channel_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'Channel_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'Channel_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'Channel_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'Channel_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'Channel_SAVE_REQUEST',
	SAVE_SUCCESS = 'Channel_SAVE_SUCCESS',
	SAVE_FAILURE = 'Channel_SAVE_FAILURE',

	DELETE_REQUEST = 'Channel_DELETE_REQUEST',
	DELETE_SUCCESS = 'Channel_DELETE_SUCCESS',
	DELETE_FAILURE = 'Channel_DELETE_FAILURE',

	RELOAD = 'Channel_RELOAD',

	CHANGE_SELECTED_IDS = 'Channel_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'Channel_SHOW_DETAIL',
	SHOW_CONFIRM = 'Channel_SHOW_CONFIRM',
}

export interface ChannelTypeAction_GETALL_Request extends IActionTypeBase<eChannelActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface ChannelTypeAction_GETALL_Success extends IActionTypeBase<eChannelActionTypeIds.GET_ALL_SUCCESS, ChannelModel[]> {}
export interface ChannelTypeAction_GETALL_Failure extends IActionTypeBase<eChannelActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface ChannelTypeAction_GETPAGINATION_Request
	extends IActionTypeBase<eChannelActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface ChannelTypeAction_GETPAGINATION_Success
	extends IActionTypeBase<eChannelActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationResponse<ChannelModel>> {}
export interface ChannelTypeAction_GETPAGINATION_Failure
	extends IActionTypeBase<eChannelActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface ChannelTypeAction_SAVE_Request extends IActionTypeBase<eChannelActionTypeIds.SAVE_REQUEST, ChannelModel> {}
export interface ChannelTypeAction_SAVE_Success extends IActionTypeBase<eChannelActionTypeIds.SAVE_SUCCESS, string> {}
export interface ChannelTypeAction_SAVE_Failure extends IActionTypeBase<eChannelActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface ChannelTypeAction_DELETE_Request extends IActionTypeBase<eChannelActionTypeIds.DELETE_REQUEST, number[]> {}
export interface ChannelTypeAction_DELETE_Success extends IActionTypeBase<eChannelActionTypeIds.DELETE_SUCCESS, string> {}
export interface ChannelTypeAction_DELETE_Failure extends IActionTypeBase<eChannelActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface ChannelTypeAction_RELOAD extends IActionTypeBase<eChannelActionTypeIds.RELOAD, undefined> {}

export interface ChannelTypeAction_SHOW_DETAIL extends IActionTypeBase<eChannelActionTypeIds.SHOW_DETAIL, BaseShowDetail<ChannelModel>> {}
export interface ChannelTypeAction_SHOW_CONFIRM extends IActionTypeBase<eChannelActionTypeIds.SHOW_CONFIRM, boolean> {}
export interface ChannelTypeAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eChannelActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}

export type ChannelActionTypes =
	| ChannelTypeAction_GETALL_Request
	| ChannelTypeAction_GETALL_Success
	| ChannelTypeAction_GETALL_Failure
	| ChannelTypeAction_GETPAGINATION_Request
	| ChannelTypeAction_GETPAGINATION_Success
	| ChannelTypeAction_GETPAGINATION_Failure
	| ChannelTypeAction_SAVE_Request
	| ChannelTypeAction_SAVE_Success
	| ChannelTypeAction_SAVE_Failure
	| ChannelTypeAction_DELETE_Request
	| ChannelTypeAction_DELETE_Success
	| ChannelTypeAction_DELETE_Failure
	| ChannelTypeAction_RELOAD
	| ChannelTypeAction_SHOW_DETAIL
	| ChannelTypeAction_SHOW_CONFIRM
	| ChannelTypeAction_CHANGE_SELECTED_IDS;

import { BasePaginationResponse } from '../../../base/BasePaginationResponse';
import { BaseShowDetail } from '../../../base/BaseShowDetail';
import { BaseAction } from '../../../base/IBaseActionRespone';
import { RoleModel } from '../../../models/my-system/roles/RoleModel';
import { RoleSelectRequest } from '../../../models/my-system/roles/RoleSelectRequest';
import {
	RolesAction_CHANGE_SELECTED_IDS,
	RolesAction_DELETE_Failure,
	RolesAction_DELETE_Request,
	RolesAction_DELETE_Success,
	RolesAction_GETALL_Failure,
	RolesAction_GETALL_Request,
	RolesAction_GETALL_Success,
	RolesAction_GETPAGINATION_Failure,
	RolesAction_GETPAGINATION_Request,
	RolesAction_GETPAGINATION_Success,
	RolesAction_RELOAD,
	RolesAction_SAVE_Failure,
	RolesAction_SAVE_Request,
	RolesAction_SAVE_Success,
	RolesAction_SHOW_CONFIRM,
	RolesAction_SHOW_DETAIL,
	eRolesActionTypeIds,
} from './IRolesActionsTypes';

export const rolesActions = {
	//ALL
	getAllRequest: (): RolesAction_GETALL_Request => BaseAction(eRolesActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: RoleModel[]): RolesAction_GETALL_Success => BaseAction(eRolesActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): RolesAction_GETALL_Failure => BaseAction(eRolesActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: RoleSelectRequest): RolesAction_GETPAGINATION_Request =>
		BaseAction(eRolesActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationResponse<RoleModel>): RolesAction_GETPAGINATION_Success =>
		BaseAction(eRolesActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): RolesAction_GETPAGINATION_Failure =>
		BaseAction(eRolesActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: RoleModel): RolesAction_SAVE_Request => BaseAction(eRolesActionTypeIds.SAVE_SAVING, entity),
	saveSuccess: (message: string): RolesAction_SAVE_Success => BaseAction(eRolesActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): RolesAction_SAVE_Failure => BaseAction(eRolesActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): RolesAction_DELETE_Request => BaseAction(eRolesActionTypeIds.DELETE_SAVING, ids),
	deleteSuccess: (message: string): RolesAction_DELETE_Success => BaseAction(eRolesActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): RolesAction_DELETE_Failure => BaseAction(eRolesActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): RolesAction_RELOAD => BaseAction(eRolesActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): RolesAction_CHANGE_SELECTED_IDS => BaseAction(eRolesActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (data: BaseShowDetail<RoleModel>): RolesAction_SHOW_DETAIL => BaseAction(eRolesActionTypeIds.SHOW_DETAIL, data),
	showConfirm: (show: boolean): RolesAction_SHOW_CONFIRM => BaseAction(eRolesActionTypeIds.SHOW_CONFIRM, show),
};

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	SwitchBox,
	TextAreaBox,
	TextBox,
} from '../../../../components/base';
import { CheckedBoxListRoleScope } from '../../../../components/shared/system';
import { RoleModel, df_RoleModel, valid_RoleModel } from '../../../../context/models/my-system/roles/RoleModel';

type IRoleDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: RoleModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: RoleModel) => void;
};

const RoleDetailModal = (props: IRoleDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<RoleModel>(df_RoleModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<RoleModel>({
		resolver: yupResolver(valid_RoleModel),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: RoleModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			const { id, name, description, is_active, is_protected, role_scope_ids } = detailModel;
			setInitialValues((prev) => ({ ...prev, id, name, description, is_active, is_protected, role_scope_ids }));
		}
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='xl' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='row g-gs'>
							<div className='col-lg-6'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Thông tin quyền hạn</h5>
										</div>
										<div className='gy-3'>
											<div className='row g-3 align-center'>
												<div className='col-lg-12'>
													<TextBox
														name={'name'}
														error={errors.name?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, name: value }));
														}}
														value={initialValues.name}
														hasValid={true}
														label='Tên quyền hạn'
													/>
													<TextAreaBox
														name={'description'}
														error={errors.description?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, description: value }));
														}}
														value={initialValues.description}
														hasValid={true}
														label='Mô tả'
													/>
													<FormGroup label='Trạng thái'>
														<SwitchBox
															name='is_active'
															label='Áp dụng'
															error={errors.is_active?.message}
															onValueChanged={(value) => {
																setInitialValues((prev) => ({ ...prev, is_active: value }));
															}}
															value={initialValues.is_active}
															sizeClass='sm'
														/>
														<SwitchBox
															name='is_protected'
															label='Không sửa đổi'
															error={errors.is_protected?.message}
															onValueChanged={(value) => {
																setInitialValues((prev) => ({ ...prev, is_protected: value }));
															}}
															value={initialValues.is_protected}
															sizeClass='sm'
														/>
													</FormGroup>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-6'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Phân quyền</h5>
										</div>
										<CheckedBoxListRoleScope
											name='role_scope_ids'
											hasValid={true}
											error={errors.role_scope_ids?.message}
											selectedValue={initialValues.role_scope_ids}
											onSelectionChanged={(value: any[]) => {
												setInitialValues((prev) => ({ ...prev, role_scope_ids: value?.map((x) => parseInt(x)) }));
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default RoleDetailModal;

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	DxDateBox,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	TextBox,
} from '../../../components/base';
import { ComboBoxArticleCategory } from '../../../components/shared/hoc-article-category/ComboBoxArticleCategory';
import { ArticleSelectRequest } from '../../../context/models/articles/ArticleSelectRequest';
import ComboBoxUser from '../../../components/shared/system/hoc-users/ComboBoxUser';

type IFilterBoxProps = {
	isShow: boolean;
	filterModel: ArticleSelectRequest;
	isLoading: boolean | false;
	onClose: () => void;
	onSubmit: (data: ArticleSelectRequest) => void;
};

const FilterBox = (props: IFilterBoxProps) => {
	const { isShow, onSubmit, onClose, isLoading, filterModel } = props;
	const [initialValues, setInitialValues] = useState<ArticleSelectRequest>(filterModel);
	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm<ArticleSelectRequest>({
		mode: 'all',
		defaultValues: initialValues,
	});
	const onSubmitHandler = (values: ArticleSelectRequest) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
	}, [initialValues, reset]);

	return (
		<ModalDraggable show={isShow} sizeClass='md' type='primary' onClose={onClose}>
			<ModalHeader classDragg={true} title={'Tìm kiếm tin bài'} onClose={() => onClose()} />
			<ModalBody>
				<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
					<div className='row'>
						<div className='col-md-12'>
							<TextBox
								name={'searchString'}
								error={errors.searchString?.message}
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, searchString: value }));
								}}
								value={initialValues.searchString}
								label='Từ khóa'
								sizeClass='md'
								placeholder='Nhập từ khóa: thị trường, giá cả, ...'
							/>
						</div>
						<div className='col-md-6'>
							<ComboBoxArticleCategory
								name='catId'
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, catId: value }));
								}}
								value={initialValues.catId}
								error={errors.catId?.message}
								label='Chuyên mục'
							/>
						</div>
						<div className='col-md-6'>
							<ComboBoxUser
								name='author'
								onValueChanged={(value) => {
									setInitialValues((prev) => ({ ...prev, author: value }));
								}}
								value={initialValues.author}
								error={errors.author?.message}
								label='Tác giả'
							/>
						</div>
						<div className='col-md-12'>
							<FormGroup label='Ngày xuất bản'>
								<div className='row'>
									<div className='col-md-6'>
										<DxDateBox
											name={'beginDate'}
											error={errors.beginDate?.message}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, beginDate: value }));
											}}
											value={initialValues.beginDate}
											label='Từ ngày'
											placeholder='ngày/tháng/năm'
										/>
									</div>
									<div className='col-md-6'>
										<DxDateBox
											name={'endDate'}
											error={errors.endDate?.message}
											onValueChanged={(value) => {
												setInitialValues((prev) => ({ ...prev, endDate: value }));
											}}
											value={initialValues.endDate}
											label='Đến ngày'
											placeholder='ngày/tháng/năm'
										/>
									</div>
								</div>
							</FormGroup>
						</div>
					</div>
				</BaseForm>
			</ModalBody>
			<ModalFooter>
				<ButtonCancel text='Đóng' onClick={onClose} isDisabled={isLoading} />
				<ButtonSave type='button' isLoading={isLoading} isDisabled={isLoading} onClick={handleSubmit(onSubmitHandler)} text={`Tìm kiếm`} />
			</ModalFooter>
		</ModalDraggable>
	);
};

export default FilterBox;

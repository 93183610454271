import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api/rootApi';
import { BaseResponse } from '../../base/BaseResponse';
import { actions } from '../rootActions';
import { KeywordSeoModel } from '../../models/seos/KeywordSeoModel';
import {
	KeywordSeoAction_DELETE_Request,
	KeywordSeoAction_GETPAGINATION_Request,
	KeywordSeoAction_SAVE_Request,
	eKeywordSeoActionTypeIds,
} from './IKeywordSeoActionTypes';

const _sagaApi = rootApi.keywordSeo;

function* onLoadAllKeywords() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listKeywords = res.result as KeywordSeoModel[];
			yield put(actions.keywordSeo.getAllSuccess(listKeywords));
		} else {
			yield put(actions.keywordSeo.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.keywordSeo.getAllFailure(err || 'Có lỗi'));
	}
}

function* onLoadPaginationKeywords(action: KeywordSeoAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listKeywords = res.result as KeywordSeoModel[];
			yield put(actions.keywordSeo.getPaginationSuccess({ listDatas: listKeywords, pagination: res.pagination }));
		} else {
			yield put(actions.keywordSeo.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.keywordSeo.getPaginationFailure(err || 'Có lỗi'));
	}
}

function* onSaveKeyword(action: KeywordSeoAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.keywordSeo.saveSuccess(res.message));
				yield put(actions.keywordSeo.reload());
			} else {
				yield put(actions.keywordSeo.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.keywordSeo.saveSuccess(res.message));
				yield put(actions.keywordSeo.reload());
			} else {
				yield put(actions.keywordSeo.saveFailure(res.message));
			}
		}
	} catch (err: any) {
		yield put(actions.keywordSeo.saveFailure(err || 'Có lỗi'));
	}
}

function* onDeleteKeyword(action: KeywordSeoAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.keywordSeo.deleteSuccess(res.message));
			yield put(actions.keywordSeo.reload());
		} else {
			yield put(actions.keywordSeo.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.keywordSeo.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllKeywords() {
	yield takeEvery(eKeywordSeoActionTypeIds.GET_ALL_REQUEST, onLoadAllKeywords);
}

function* watchOnLoadPaginationKeywords() {
	yield takeEvery(eKeywordSeoActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationKeywords);
}

function* watchOnSaveKeyword() {
	yield takeEvery(eKeywordSeoActionTypeIds.SAVE_REQUEST, onSaveKeyword);
}

function* watchOnDeleteKeyword() {
	yield takeEvery(eKeywordSeoActionTypeIds.DELETE_REQUEST, onDeleteKeyword);
}

function* keywordSeoSaga() {
	yield all([fork(watchOnLoadAllKeywords)]);
	yield all([fork(watchOnLoadPaginationKeywords)]);
	yield all([fork(watchOnSaveKeyword)]);
	yield all([fork(watchOnDeleteKeyword)]);
}

export default keywordSeoSaga;

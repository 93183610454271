import * as Yup from 'yup';
import { valid_base, valid_base_object } from '../../../base/valid_base';

export type UserGroupModel = {
	id: number;
	name: string;
	description: string;
	sort_order: number;
	role_ids: string;
	is_active: boolean;
	is_protected: boolean;
};

export const df_UserGroupModel: UserGroupModel = {
	id: 0,
	name: '',
	description: '',
	is_active: true,
	role_ids: '',
	sort_order: 0,
	is_protected: false,
};

export const valid_UserGroupModel = valid_base_object.object().shape({
	id: valid_base.number_required(),
	name: valid_base.string_required(),
	description: valid_base.string_required(),
	sort_order: valid_base.number_required(),
	role_ids: valid_base.string_required(),
	is_active: valid_base.bool_required(),
	is_protected: valid_base.bool_required(),
});

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppState } from '../../../../context/stores/rootReducers';
import { useEffect } from 'react';
import { actions } from '../../../../context/stores/rootActions';
import { eBaseActionStatus } from '../../../../context/base/eBaseActionStatus';

export function useLookupUsers() {
	const dispatch = useDispatch();
	const { status, allUsers } = useSelector((state: AppState) => state.users);
	useEffect(() => {
		if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload) dispatch(actions.users.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { users_all: allUsers };
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AppState } from '../../../context/stores/rootReducers';
import { useDispatch, useSelector } from 'react-redux';
import { ArticleSelectRequest, df_ArticleSelectRequest } from '../../../context/models/articles/ArticleSelectRequest';
import { actions } from '../../../context/stores/rootActions';
import { eArticleStatus } from '../../../context/models/articles/eArticleStatus';
import { useLookupArticleCategory } from '../../../components/shared/hoc-article-category/useLookupArticleCategory';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import no_img from '../../../assets/images/picture.png';
import {
	ButtonCancel,
	ButtonSave,
	LoadingTable,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	TextBox,
} from '../../../components/base';
import { BlockCard } from '../../../components/container';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';
import { DxSelectBox } from '../../../components/base/DxSelectBox';
import { PaginationRender } from '../../../utils/PaginationHelper';
import { SearchBox } from '../../../components/base/SearchBox';
import { DxDropDownBox } from '../../../components/base/DxDropDownBox';

type ArticlePublishedSelectionModalProps = {
	title: string;
	onClose: () => void;
	onSelectedValueChanged: (selectedIds: number[]) => void;
};

const ArticlePublishedSelectionModal = (props: ArticlePublishedSelectionModalProps) => {
	const dispatch = useDispatch();
	const articleStatus = eArticleStatus.PUBLISHED;
	const { title, onClose, onSelectedValueChanged } = props;
	const { article_category_all } = useLookupArticleCategory();
	const [searchString, setSearchString] = useState<string>('');
	const [selectedChangeIds, setSelectedChangeIds] = useState<number[]>([]);
	const [filter, setFilter] = useState<ArticleSelectRequest>({
		...df_ArticleSelectRequest,
		status: articleStatus,
		langCode: jwtTokenHelper.LANGCODE(),
	});
	const { status, paginationResponse } = useSelector((state: AppState) => state.article);
	const { listDatas, pagination } = paginationResponse;

	const handleReloadData = useCallback(() => {
		dispatch(actions.article.getPublishedRequest(filter));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn key={createUUID()} dataField='title' caption={`Tiêu đề`} minWidth={260}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='catId' width={150} caption={`Chuyên mục`}>
				<DxLookup dataSource={article_category_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
		];
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article_category_all]);

	const toolBars = useMemo(() => {
		const result = [
			<DxToolbarItem location='before' key={createUUID()}>
				<DxDropDownBox
					dataSource={article_category_all}
					value={[filter.catId]}
					width={340}
					isSearchable={true}
					onValueChanged={(value) => {
						const seleted_id = value && value.length > 0 ? value[0] : 0;
						setFilter({ ...filter, catId: seleted_id || 0 })
					}}
					valueExpr='id'
					displayExpr='name'
					parentIdExpr='parentId'
					placeholder='Chọn Chuyên mục'
					name='filter_chuyenmuc'
				/>
			</DxToolbarItem>,
			<DxToolbarItem location='before' key={createUUID()}>
				<SearchBox
					name='filter_page'
					placeholder='Nhập từ khóa tìm kiếm'
					value={filter.searchString}
					onValueChanged={(value) => setFilter({ ...filter, searchString: value })}
					classWrapper='m-0 p0'
					sizeClass='sm'
				/>
			</DxToolbarItem>,
			<DxToolbarItem location='before' key={createUUID()}>
				<p className='m-0 p-0'>
					Đã chọn <b>{selectedChangeIds.length}</b> bài viết
				</p>
			</DxToolbarItem>,
		];
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article_category_all, selectedChangeIds, filter]);

	return (
		<>
			<ModalDraggable show={true} sizeClass='xl' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					{status === eBaseActionStatus.loading && <LoadingTable />}
					{status !== eBaseActionStatus.loading && (
						<>
							<DxTable
								dataSource={listDatas || []}
								keyExpr='id'
								columns={columns}
								toolbars={toolBars}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								wordWrapEnabled={true}
								height={'fit-content'}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										setSelectedChangeIds(e.selectedRowKeys);
									},
									selectedRowKeys: selectedChangeIds,
								}}
								showToolbar={true}
							/>
							<hr />
							<PaginationRender
								onPageChange={(page) => setFilter((prev) => ({ ...prev, page_index: page }))}
								pagination={pagination}
								siblingCount={2}
							/>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} text='Đóng' />
					<ButtonSave
						type='button'
						isDisabled={selectedChangeIds.length === 0}
						onClick={() => {
							onSelectedValueChanged(selectedChangeIds);
						}}
						text={`Xác nhận`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default ArticlePublishedSelectionModal;

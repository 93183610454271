import React from 'react';

type IPageBodyProps = {
	children?: any;
};

export const PageBody = ({ children }: IPageBodyProps) => {
	return (
		<>
			<div className='nk-content-inner'>
				<div className='nk-content-body'>{children}</div>
			</div>
		</>
	);
};

import * as Yup from 'yup';

export const valid_base_object = Yup;

export const valid_base = {
	bool: () => Yup.bool(),
	bool_required: (message?: string) => Yup.bool().required(message || 'Nội dung chỉ có thể là true/false.'),
	string: () => Yup.string(),
	string_required: (message?: string) => Yup.string().required(message || 'Vui lòng nhập nội dung.'),
	string_array_required: (message?: string) =>
		Yup.array(Yup.string().required(message || 'Danh sách không thể trống.')).required(message || 'Danh sách không thể trống.'),
	number: () => Yup.number(),
	number_required: (message?: string) => Yup.number().required(message || 'Vui lòng nhập giá trị số.'),
	number_array_required: (message?: string) =>
		Yup.array(Yup.number().required(message || 'Danh sách không thể trống.')).required(message || 'Danh sách không thể trống.'),
	number_min_required: (min: number, message?: string) =>
		Yup.number()
			.required(message || 'Vui lòng nhập giá trị số.')
			.min(min, message || `Vui lòng nhập giá trị số lớn hơn ${min}.`),
	number_max_required: (max: number, message?: string) =>
		Yup.number()
			.required(message || 'Vui lòng nhập giá trị số.')
			.max(max, message || `Vui lòng nhập giá trị số nhỏ hơn ${max}.`),
	number_minmax_required: (min: number, max: number, message?: string) =>
		Yup.number()
			.required(message || 'Vui lòng nhập giá trị số.')
			.min(min, message || `Vui lòng nhập giá trị số lớn hơn ${min}.`)
			.max(max, message || `Vui lòng nhập giá trị số nhỏ hơn ${max}.`),
	email_required: (is_valid: true | false, message?: string) =>
		Yup.string()
			.transform((x) => (x === '' ? undefined : x))
			.concat(is_valid ? Yup.string().required(message || 'Vui lòng nhập email.') : Yup.string())
			.email(message || 'Định dạng email không phù hợp.'),
	password: (mode?: 'add' | 'edit', min?: number, message?: string) =>
		Yup.string()
			.transform((x) => (x === '' ? undefined : x))
			.concat(mode === 'add' ? Yup.string().required(message || 'Vui lòng nhập mật khẩu.') : Yup.string())
			.matches(
				/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
				'Mật khẩu tối thiểu 8 kí tự. Gồm CHỮ HOA, chữ thường, số và kí tự đặc biệt (!@#$...).'
			)
			.min(min || 6, `Mật khẩu tối thiểu ${min || 6} ký tự`),
	password_confirmed: (ref: string, mode?: 'add' | 'edit', message?: string) =>
		Yup.string()
			.transform((x) => (x === '' ? undefined : x))
			.when(ref || 'password', (password, schema: any) => {
				if (password && mode === 'add') return schema.required(message || 'Vui lòng nhập Mật khẩu xác nhận.');
			})
			.oneOf([Yup.ref(ref || 'password')], message || 'Mật khẩu xác nhận không khớp.'),
};

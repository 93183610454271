import { ApiClient } from "../../base/_ApiClient";

export const aticleCrawlerApi = {
	GetAsync: () => {
		return ApiClient.GET('/article-crawlers');
	},
	DetailAsync: (id: string) => {
		return ApiClient.GET(`/article-crawlers/${id}`);
	},
	CreateArticleDrafAsync: (id: string) => {
		return ApiClient.POST(`/article-crawlers/create-draft/${id}`);
	},
};
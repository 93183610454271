import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api/rootApi';
import { BaseResponse } from '../../base/BaseResponse';
import { actions } from '../rootActions';
import { ArticleTypeModel } from '../../models/articles-type/ArticleTypeModel';
import {
	ArticleTypeAction_DELETE_Request,
	ArticleTypeAction_GETPAGINATION_Request,
	ArticleTypeAction_SAVE_Request,
	eArticleTypeActionTypeIds,
} from './IArticleTypeActionsTypes';

const _sagaApi = rootApi.articleType;

function* onLoadAllArticleTypes() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listArticleTypes = res.result as ArticleTypeModel[];
			yield put(actions.articleType.getAllSuccess(listArticleTypes));
		} else {
			yield put(actions.articleType.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.articleType.getAllFailure(err || 'Có lỗi'));
	}
}

function* onLoadPaginationArticleTypes(action: ArticleTypeAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listArticleTypes = res.result as ArticleTypeModel[];
			yield put(actions.articleType.getPaginationSuccess({ listDatas: listArticleTypes, pagination: res.pagination }));
		} else {
			yield put(actions.articleType.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.articleType.getPaginationFailure(err || 'Có lỗi'));
	}
}

function* onSaveArticleTypes(action: ArticleTypeAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.articleType.saveSuccess(res.message));
				yield put(actions.articleType.reload());
			} else {
				yield put(actions.articleType.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.articleType.saveSuccess(res.message));
				yield put(actions.articleType.reload());
			} else {
				yield put(actions.articleType.saveFailure(res.message));
			}
		}
	} catch (err: any) {
		yield put(actions.articleType.saveFailure(err || 'Có lỗi'));
	}
}

function* onDeleteArticleTypes(action: ArticleTypeAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.articleType.deleteSuccess(res.message));
			yield put(actions.articleType.reload());
		} else {
			yield put(actions.articleType.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.articleType.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllArticleTypes() {
	yield takeEvery(eArticleTypeActionTypeIds.GET_ALL_REQUEST, onLoadAllArticleTypes);
}

function* watchOnLoadPaginationArticleTypes() {
	yield takeEvery(eArticleTypeActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationArticleTypes);
}

function* watchOnSaveArticleTypes() {
	yield takeEvery(eArticleTypeActionTypeIds.SAVE_REQUEST, onSaveArticleTypes);
}

function* watchOnDeleteArticleTypes() {
	yield takeEvery(eArticleTypeActionTypeIds.DELETE_REQUEST, onDeleteArticleTypes);
}

function* articleTypesSaga() {
	yield all([fork(watchOnLoadAllArticleTypes)]);
	yield all([fork(watchOnLoadPaginationArticleTypes)]);
	yield all([fork(watchOnSaveArticleTypes)]);
	yield all([fork(watchOnDeleteArticleTypes)]);
}

export default articleTypesSaga;

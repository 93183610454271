import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { BadgesRoleScope, useLookupRoleScopes } from '../../../components/shared/system';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { RoleModel } from '../../../context/models/my-system/roles/RoleModel';
import { RolesActionTypes } from '../../../context/stores/my-system/roles/IRolesActionsTypes';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import RoleDetailModal from './detail-modal';

type IRolesPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allDatas: RoleModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<RoleModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: RoleModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<RoleModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Quyền`,
		status: state.roles.status,
		allDatas: state.roles.allRoles,
		selectedIds: state.roles.selectedIds,
		showDetail: state.roles.showDetail,
		showConfirm: state.roles.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<RolesActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.roles.getAllRequest());
	},
	handleSaveData: (data: RoleModel) => {
		dispatch(actions.roles.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.roles.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.roles.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<RoleModel>) => {
		dispatch(actions.roles.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.roles.showConfirm(isShow));
	},
});

const RolesPage = (props: IRolesPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allDatas,
		handleReloadAllData,
		onChangeSelectedIds,
		handleDeleteData,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;
	const { role_scopes_lookup } = useLookupRoleScopes();
	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn allowHeaderFiltering={false} key={createUUID()} dataField='name' caption={`Tên`} width={150}></DxTableColumn>,
			<DxTableColumn
				allowHeaderFiltering={false}
				key={createUUID()}
				dataField='description'
				minWidth={150}
				caption={`Mô tả`}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='role_scope_ids'
				caption={`Quyền hạn`}
				width={320}
				dataType={'object'}
				cellRender={(cell: any) => {
					return <BadgesRoleScope role_scopes_all={role_scopes_lookup} value={cell.value} />;
				}}
				// calculateFilterExpression={(filterValue: any, selectedFilterOperation: string | null, target: string) => () => {
				// 	if (target === 'search' && typeof filterValue === 'string') {
				// 		return [target, 'contains', filterValue];
				// 	}
				// 	return function (data: any) {
				// 		return (data.role_scope_ids || []).indexOf(filterValue) !== -1;
				// 	};
				// }}
			>
				{/* <DxLookup dataSource={role_scopes_lookup} valueExpr='id' displayExpr='name' /> */}
			</DxTableColumn>,
			<DxTableColumn width={100} allowHeaderFiltering={false} key={createUUID()} dataField='is_active' caption={`Áp dụng`}></DxTableColumn>,
			<DxTableColumn
				width={100}
				allowHeaderFiltering={false}
				key={createUUID()}
				dataField='is_protected'
				caption={`Không sửa đổi`}
			></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				cellRender={(cell: any) => {
					const isProtected = cell.data.is_protected;
					return (
						<>
							{!isProtected && (
								<ButtonLink
									onClick={() => onShowDetail({ isShow: true, detailData: cell.data })}
									text=''
									icon='ni ni-edit'
									theme='primary'
								/>
							)}
						</>
					);
				}}
			/>
		);
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [role_scopes_lookup]);

	const toolBars = useMemo(() => {
		const result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<span>
					Tổng số: <b>{allDatas.length}</b>{' '}
				</span>
			</DxToolbarItem>,
		];
		return result;
	}, [allDatas]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allDatas}
								keyExpr='id'
								filters={{
									refreshDataGrid: () => handleReloadAllData(),
								}}
								exportOptions={{
									title: `${titlePage}`,
									hasExportSelected: true,
									hasHeader: true,
									headerMerge: { leftIndex: 2, rightIndex: columns?.length },
								}}
								// isHeaderFilter={true}
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								toolbars={toolBars}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
								wordWrapEnabled={true}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<RoleDetailModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);

import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationResponse, defaultBasePagination } from '../../base/BasePaginationResponse';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { ChannelModel } from '../../models/channel/ChannelModel';
import { ChannelActionTypes, eChannelActionTypeIds } from './IChannelActionsTypes';
import { IChannelState } from './IChannelState';

const initPaginationResponse: BasePaginationResponse<ChannelModel> = {
	listDatas: [],
	pagination: defaultBasePagination,
};

const initialState: IChannelState = {
	status: eBaseActionStatus.idle,
	allChannels: [],
	selectedIds: [],
	showConfirm: false,
	showDetail: { isShow: false },
	paginationResponse: initPaginationResponse,
};

const channelReducer = (state: IChannelState = initialState, action: ChannelActionTypes): IChannelState => {
	switch (action.type) {
		case eChannelActionTypeIds.GET_ALL_REQUEST:
		case eChannelActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allChannels: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allChannels: [],
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				allChannels: [],
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eChannelActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eChannelActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên trang mới thành công!');
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Xóa chuyên trang thành công!');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eChannelActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		default:
			return state;
	}
};

export default channelReducer;

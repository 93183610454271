import { combineReducers } from 'redux';
import accountReducer from './my-system/auth/accountReducer';
import commonReducer from './my-system/common/commonReducer';
import menusReducer from './my-system/menus/menusReducer';
import roleScopesReducer from './my-system/roles/roleScopesReducer';
import rolesReducer from './my-system/roles/rolesReducer';
import usersGroupReducer from './my-system/users-group/userGroupReducer';
import usersReducer from './my-system/users/usersReducer';
import articleCategoryReducer from './articles-category/articleCategoryReducer';
import articleReducer from './articles/articleReducer';
import articleTypeReducer from './articles-type/articleTypeReducer';
import royaltyReducer from './royalty/royaltyReducer';
import channelReducer from './channel/channelTypeReducer';
import keywordSeoReducer from './seos/keywordSeoReducer';
import crawlArticleReducer from './crawl-articles/crawlArticleReducer';

const rootReducers = combineReducers({
	common: commonReducer,
	menus: menusReducer,
	users: usersReducer,
	usersGroup: usersGroupReducer,
	account: accountReducer,
	roles: rolesReducer,
	roleScopes: roleScopesReducer,
	articleCategory: articleCategoryReducer,
	article: articleReducer,
	articleType: articleTypeReducer,
	royalty: royaltyReducer,
	channel: channelReducer,
	keywordSeo: keywordSeoReducer,
	crawlArticle: crawlArticleReducer
});

export type AppState = ReturnType<typeof rootReducers>;

export default rootReducers;

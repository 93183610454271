import React from 'react';

export const PageFooter = ({ children }: { children?: any }) => {
	return (
		<>
			<div className='nk-block mt-2'>
				<div className='card'>
					<div className='card-inner'>{children}</div>
				</div>
			</div>
		</>
	);
};

import { CommonActionTypes, eCommonActionTypeIds } from './ICommonActionTypes';
import { ICommonState } from './ICommonState';

const initialState: ICommonState = {
	menu_mode: localStorage.menu_mode || 'show',
};

const commonReducer = (state: ICommonState = initialState, action: CommonActionTypes): ICommonState => {
	switch (action.type) {
		case eCommonActionTypeIds.CHANGE_MENU_MODE:
			localStorage.menu_mode = action.payload;
			return {
				...state,
				menu_mode: action.payload,
			};
		case eCommonActionTypeIds.HEADER_RESET_SELECTED:
			localStorage.menu_mode = 'show';
			return {
				...state,
				menu_mode: 'show',
			};
		default:
			return state;
	}
};
export default commonReducer;

import { ArticleApi } from './articles/articleApi';
import { ArticleCategoryApi } from './articles/articleCategoryApi';
import { ArticleTypeApi } from './articles/articleTypeApi';
import { ChannelApi } from './channel/channelApi';
import { crawlArticleApi } from './crawl-articles/crawlArticleApi';
import { AccountApi } from './my-system/accountApi';
import { MenuApi } from './my-system/menus/menuApi';
import { RoleApi } from './my-system/users/roleApi';
import { UserApi } from './my-system/users/userApi';
import { UserGroupApi } from './my-system/users/userGroupApi';
import { RoyaltyApi } from './royalty/royaltyApi';
import { KeywordSeoApi } from './seos/keywordSeoApi';

export const rootApi = {
	account: AccountApi,
	user: UserApi,
	userGroup: UserGroupApi,
	menus: MenuApi,
	role: RoleApi,
	articleCategory: ArticleCategoryApi,
	article: ArticleApi,
	articleType: ArticleTypeApi,
	royalty: RoyaltyApi,
	channel: ChannelApi,
	keywordSeo: KeywordSeoApi,
	crawlArticle: crawlArticleApi
};

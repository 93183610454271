import React, { useMemo } from 'react';

export type ICheckBoxListProps = {
	name: string;
	keyExpr: string;
	displayExpr: string;
	displaySubExpr?: string;
	dataSource: any[];
	selectedValue: any[];
	onSelectionChanged: (values: any[]) => void;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
};

export const CheckBoxList = (props: ICheckBoxListProps) => {
	const { error, name, sizeClass, helpBlock, dataSource, selectedValue, onSelectionChanged, keyExpr, displayExpr, displaySubExpr } = props;
	const showError = error ? true : false;

	const selectedValueString = useMemo(() => {
		return selectedValue?.map((x: any) => x.toString());
	}, [selectedValue]);

	return (
		<>
			{showError && <span className='form-note invalid'>{error}</span>}
			{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
			<ul className='custom-control-group custom-control-vertical w-100'>
				{dataSource &&
					dataSource.map((item, index) => {
						return (
							<li key={`${item[keyExpr]}_${index}`}>
								<div
									className={`custom-control custom-control-${sizeClass || 'sm'} custom-checkbox custom-control-pro ${
										showError ? 'error' : ''
									}`}
								>
									<input
										className='custom-control-input'
										id={`checkbox-${item[keyExpr]}_${index}`}
										name={name}
										type='checkbox'
										value={item[keyExpr].toString()}
										checked={selectedValueString?.includes(item[keyExpr].toString())}
										onChange={(e) => {
											const valueCopy = [...(selectedValue?.map((x: any) => x.toString()) || [])];
											const valueInput: any = e.target.value;
											const exist = valueCopy.indexOf(valueInput);

											if (e.target.checked) {
												if (exist) {
													valueCopy.push(e.target.value);
												}
											} else {
												if (exist > -1) {
													valueCopy.splice(exist, 1);
												}
											}
											onSelectionChanged(valueCopy);
										}}
									/>
									<label className='custom-control-label' htmlFor={`checkbox-${item[keyExpr]}_${index}`}>
										<div className='user-card'>
											<div className='user-info'>
												<div className='lead-text'>{item[displayExpr]}</div>
												{displaySubExpr && <div className='sub-text'>{item[displaySubExpr]}</div>}
											</div>
										</div>
									</label>
								</div>
							</li>
						);
					})}
			</ul>
		</>
	);
};

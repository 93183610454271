import { Dispatch } from 'react';
import { ArticleActionConfirmRequest } from '../../../context/models/articles/ArticleActionConfirmRequest';
import { ArticleSaveModel } from '../../../context/models/articles/ArticleModel';
import { ArticleSelectRequest } from '../../../context/models/articles/ArticleSelectRequest';
import { BaseShowDetailId } from '../../../context/base/BaseShowDetail';
import { ArticleActionTypes } from '../../../context/stores/articles/IArticleActionsTypes';
import { actions } from '../../../context/stores/rootActions';


export const mapArticlePublishedPageDispatchToProps = (dispatch: Dispatch<ArticleActionTypes>) => ({
	handleReloadData: (request: ArticleSelectRequest) => {
		dispatch(actions.article.getPublishedRequest(request));
	},
	handleGetDetail: (id: number) => {
		dispatch(actions.article.detailRequest(id));
	},
	handleSaveData: (data: ArticleSaveModel) => {
		dispatch(actions.article.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.article.deleteRequest(ids));
	},
	handleChangeStatus: (detail: ArticleActionConfirmRequest) => {
		dispatch(
			actions.article.changeStatusRequest({
				actionStatus: detail.actionStatus,
				articleIds: detail.ids || [],
				receivedBy: detail.receivedBy || '',
				message: detail.message || '',
				subject: detail.subject || '',
			})
		);
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.article.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetailId) => {
		dispatch(actions.article.showDetail(data));
	},
	onShowConfirm: (detail: ArticleActionConfirmRequest) => {
		dispatch(actions.article.showConfirm(detail));
	},
});

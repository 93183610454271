import { useMemo } from 'react';
import { DxSelectBox } from '../../../../base/DxSelectBox';
import { useLookupUsers } from '../useLookupUser';

type IComboBoxUserProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
};

const ComboBoxUser = (props: IComboBoxUserProps) => {
	const { valueExpr, displayExpr } = props;
	const { users_all } = useLookupUsers();
	const dataSource = useMemo(() => {
		return users_all?.map(x => ({...x, text: `${x.user_name} - ${x.full_name}`}));
	}, [users_all]);
	return (
		<DxSelectBox
			dataSource={dataSource}
			valueExpr={valueExpr || 'id'}
			displayExpr={displayExpr || 'text'}
			{...props}
			placeholder={props.placeholder || 'Chọn tài khoản'}
			isClearable={true}
		/>
	);
};
export default ComboBoxUser;

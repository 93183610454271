import ArticleTypeNewsPage from './article-typenews';
import ArticleCategoryPage from './articles-category';
import ArticleTypePage from './articles-type';
import ArticleEditPage from './edit-page';
import {
	APPROVINGPage,
	DELETEDPage,
	DRAFTPage,
	EDITINGPage,
	PUBLISHEDPage,
	REMOVEDPage,
	SENTAPPROVINGPage,
	SENTEDITINGPage,
	WAITAPPROVINGPage,
	WAITEDITINGPage,
} from './status-page';

export const articleRoutes = [
	{
		title: 'Chuyên mục',
		path: '/articles-category',
		component: ArticleCategoryPage,
	},
	{
		title: 'Loại tin bài',
		path: '/articles-type',
		component: ArticleTypePage,
	},
	{ title: 'Tin bài', path: '/articles-template', component: DRAFTPage },
	{ title: 'Tin bài', path: '/articles-published', component: PUBLISHEDPage },
	{ title: 'Tin bài', path: '/articles-deleted', component: DELETEDPage },
	{ title: 'Tin bài', path: '/articles-editing', component: EDITINGPage },
	{ title: 'Tin bài', path: '/articles-removed', component: REMOVEDPage },
	{ title: 'Tin bài', path: '/articles-sent-approve', component: SENTAPPROVINGPage },
	{ title: 'Tin bài', path: '/articles-sent-edit', component: SENTEDITINGPage },
	{ title: 'Tin bài', path: '/articles-wait-approving', component: WAITAPPROVINGPage },
	{ title: 'Tin bài', path: '/articles-wait-editing', component: WAITEDITINGPage },
	{ title: 'Tin bài', path: '/articles-approving', component: APPROVINGPage },
	{ title: 'Tin bài', path: '/articles-typenews', component: ArticleTypeNewsPage },
	
	{ title: 'Sửa tin', path: '/articles-template/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-published/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-deleted/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-editing/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-removed/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-sent-approve/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-sent-edit/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-wait-approving/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-wait-editing/edit/:id', component: ArticleEditPage },
	{ title: 'Sửa tin', path: '/articles-approving/edit/:id', component: ArticleEditPage },
];

import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { rootApi } from '../../api/rootApi';
import { BaseResponse } from '../../base/BaseResponse';
import { RoyaltyModel } from '../../models/royalty/RoyaltyModel';
import { actions } from '../rootActions';
import {
	RoyaltyAction_DELETE_Request,
	RoyaltyAction_GETPAGINATION_Request,
	RoyaltyAction_SAVE_Request,
	eRoyaltyActionTypeIds,
} from './IRoyaltyActionsTypes';

const _sagaApi = rootApi.royalty;

function* onLoadAllRoyalties() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listRoyalties = res.result as RoyaltyModel[];
			yield put(actions.royalty.getAllSuccess(listRoyalties));
		} else {
			yield put(actions.royalty.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.royalty.getAllFailure(err || 'Có lỗi'));
	}
}

function* onLoadPaginationRoyalties(action: RoyaltyAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listRoyalties = res.result as RoyaltyModel[];
			yield put(actions.royalty.getPaginationSuccess({ listDatas: listRoyalties, pagination: res.pagination }));
		} else {
			yield put(actions.royalty.getAllFailure(res.message));
		}
	} catch (err: any) {
		yield put(actions.royalty.getPaginationFailure(err || 'Có lỗi'));
	}
}

function* onSaveRoyalty(action: RoyaltyAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.royalty.saveSuccess(res.message));
				yield put(actions.royalty.reload());
			} else {
				yield put(actions.royalty.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.royalty.saveSuccess(res.message));
				yield put(actions.royalty.reload());
			} else {
				yield put(actions.royalty.saveFailure(res.message));
			}
		}
	} catch (err: any) {
		yield put(actions.royalty.saveFailure(err || 'Có lỗi'));
	}
}

function* onDeleteRoyalty(action: RoyaltyAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.royalty.deleteSuccess(res.message));
			yield put(actions.royalty.reload());
		} else {
			yield put(actions.royalty.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.royalty.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllRoyalties() {
	yield takeEvery(eRoyaltyActionTypeIds.GET_ALL_REQUEST, onLoadAllRoyalties);
}

function* watchOnLoadPaginationRoyalties() {
	yield takeEvery(eRoyaltyActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationRoyalties);
}

function* watchOnSaveRoyalty() {
	yield takeEvery(eRoyaltyActionTypeIds.SAVE_REQUEST, onSaveRoyalty);
}

function* watchOnDeleteRoyalty() {
	yield takeEvery(eRoyaltyActionTypeIds.DELETE_REQUEST, onDeleteRoyalty);
}

function* royaltySaga() {
	yield all([fork(watchOnLoadAllRoyalties)]);
	yield all([fork(watchOnLoadPaginationRoyalties)]);
	yield all([fork(watchOnSaveRoyalty)]);
	yield all([fork(watchOnDeleteRoyalty)]);
}

export default royaltySaga;

import { ApiClient } from './_ApiClient';

export abstract class ApiBase<TModel> {
	_baseUrl: string;
	constructor(baseUrl: string) {
		this._baseUrl = baseUrl;
	}
	GetAllAsync() {
		return ApiClient.GET(`${this._baseUrl}`);
	}
	GetPaginationAsync(request: any) {
		// var queryString = paramsQuery ? Object.keys(paramsQuery).map(key => key + '=' + paramsQuery[key]).join('&') : '';
		return ApiClient.GET(`${this._baseUrl}/pagination${request ? `?${new URLSearchParams(request).toString()}` : ''}`);
	}
	GetByIdAsync(id: any) {
		return ApiClient.GET(`${this._baseUrl}/${id}`);
	}
	PostAsync(entity: TModel) {
		return ApiClient.POST(`${this._baseUrl}`, entity);
	}
	PutAsync(entity: TModel) {
		return ApiClient.PUT(`${this._baseUrl}`, entity);
	}
	DeleteAsync(id: any) {
		return ApiClient.DELETE(`${this._baseUrl}/${id}`);
	}
	DeletesAsync(ids: any[]) {
		return ApiClient.POST(`${this._baseUrl}/deletes`, { ids });
	}
}

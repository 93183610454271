import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetailId } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { ArticleChangeStatusRequest } from '../../models/articles/ArticleChangeStatusRequest';
import { ArticleItemResponse, ArticleModel, ArticleSaveModel } from '../../models/articles/ArticleModel';
import { ArticleSelectRequest } from '../../models/articles/ArticleSelectRequest';
import { ArticleActionConfirmRequest } from '../../models/articles/ArticleActionConfirmRequest';
import { ArticleRelatedSelectRequest } from '../../models/articles/ArticleRelatedSelectRequest';
import { ArticleTypeNewsResponse } from '../../models/articles/ArticleTypeNews';
import { ArticleTypeNewsSelectRequest } from '../../models/articles/ArticleTypeNewsSelectRequest';

export enum eArticleActionTypeIds {
	GET_PAGINATION_REQUEST = 'Article_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'Article_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'Article_GET_PAGINATION_FAILURE',

	GET_BY_ACTION_REQUEST = 'Article_GET_BY_ACTION_REQUEST',
	GET_BY_ACTION_SUCCESS = 'Article_GET_BY_ACTION_SUCCESS',
	GET_BY_ACTION_FAILURE = 'Article_GET_BY_ACTION_FAILURE',

	GET_BY_OWNER_ACTION_REQUEST = 'Article_GET_BY_OWNER_ACTION_REQUEST',
	GET_BY_OWNER_ACTION_SUCCESS = 'Article_GET_BY_OWNER_ACTION_SUCCESS',
	GET_BY_OWNER_ACTION_FAILURE = 'Article_GET_BY_OWNER_ACTION_FAILURE',

	GET_PUBLISHED_REQUEST = 'Article_GET_PUBLISHED_REQUEST',
	GET_PUBLISHED_SUCCESS = 'Article_GET_PUBLISHED_SUCCESS',
	GET_PUBLISHED_FAILURE = 'Article_GET_PUBLISHED_FAILURE',

	GET_DETAIL_EDIT_REQUEST = 'Article_GET_DETAIL_EDIT_REQUEST',
	GET_DETAIL_EDIT_SUCCESS = 'Article_GET_DETAIL_EDIT_SUCCESS',
	GET_DETAIL_EDIT_FAILURE = 'Article_GET_DETAIL_EDIT_FAILURE',

	GET_DETAIL_REQUEST = 'Article_GET_DETAIL_REQUEST',
	GET_DETAIL_SUCCESS = 'Article_GET_DETAIL_SUCCESS',
	GET_DETAIL_FAILURE = 'Article_GET_DETAIL_FAILURE',

	SAVE_REQUEST = 'Article_SAVE_REQUEST',
	SAVE_SUCCESS = 'Article_SAVE_SUCCESS',
	SAVE_FAILURE = 'Article_SAVE_FAILURE',

	DELETE_REQUEST = 'Article_DELETE_REQUEST',
	DELETE_SUCCESS = 'Article_DELETE_SUCCESS',
	DELETE_FAILURE = 'Article_DELETE_FAILURE',

	CHANGE_STATUS_REQUEST = 'Article_CHANGE_STATUS_REQUEST',
	CHANGE_STATUS_SUCCESS = 'Article_CHANGE_STATUS_SUCCESS',
	CHANGE_STATUS_FAILURE = 'Article_CHANGE_STATUS_FAILURE',

	NEED_RELOAD = 'Article_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'Article_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'Article_SHOW_DETAIL',
	CLOSE_DETAIL = 'Article_CLOSE_DETAIL',
	SHOW_CONFIRM = 'Article_SHOW_CONFIRM',
	CLOSE_CONFIRM = 'Article_CLOSE_CONFIRM',

	GET_RELATED_REQUEST = 'Article_GET_RELATED_REQUEST',
	GET_RELATED_SUCCESS = 'Article_GET_RELATED_SUCCESS',
	GET_RELATED_FAILURE = 'Article_GET_RELATED_FAILURE',

	GET_TYPENEWS_REQUEST = 'Article_GET_TYPENEWS_REQUEST',
	GET_TYPENEWS_SUCCESS = 'Article_GET_TYPENEWS_SUCCESS',
	GET_TYPENEWS_FAILURE = 'Article_GET_TYPENEWS_FAILURE',
}

export interface ArticleAction_GETPAGINATION_Request
	extends IActionTypeBase<eArticleActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface ArticleAction_GETPAGINATION_Success
	extends IActionTypeBase<eArticleActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationResponse<ArticleItemResponse>> {}
export interface ArticleAction_GETPAGINATION_Failure
	extends IActionTypeBase<eArticleActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface ArticleAction_GETBY_ACTION_Request
	extends IActionTypeBase<eArticleActionTypeIds.GET_BY_ACTION_REQUEST, ArticleSelectRequest> {}
export interface ArticleAction_GETBY_ACTION_Success
	extends IActionTypeBase<eArticleActionTypeIds.GET_BY_ACTION_SUCCESS, BasePaginationResponse<ArticleItemResponse>> {}
export interface ArticleAction_GETBY_ACTION_Failure extends IActionTypeBase<eArticleActionTypeIds.GET_BY_ACTION_FAILURE, Error | string> {}

export interface ArticleAction_GETBY_OWNER_ACTION_Request
	extends IActionTypeBase<eArticleActionTypeIds.GET_BY_OWNER_ACTION_REQUEST, ArticleSelectRequest> {}
export interface ArticleAction_GETBY_OWNER_ACTION_Success
	extends IActionTypeBase<eArticleActionTypeIds.GET_BY_OWNER_ACTION_SUCCESS, BasePaginationResponse<ArticleItemResponse>> {}
export interface ArticleAction_GETBY_OWNER_ACTION_Failure
	extends IActionTypeBase<eArticleActionTypeIds.GET_BY_OWNER_ACTION_FAILURE, Error | string> {}

export interface ArticleAction_GETPUBLISHED_Request
	extends IActionTypeBase<eArticleActionTypeIds.GET_PUBLISHED_REQUEST, ArticleSelectRequest> {}
export interface ArticleAction_GETPUBLISHED_Success
	extends IActionTypeBase<eArticleActionTypeIds.GET_PUBLISHED_SUCCESS, BasePaginationResponse<ArticleItemResponse>> {}
export interface ArticleAction_GETPUBLISHED_Failure extends IActionTypeBase<eArticleActionTypeIds.GET_PUBLISHED_FAILURE, Error | string> {}

export interface ArticleAction_GET_DETAIL_Request extends IActionTypeBase<eArticleActionTypeIds.GET_DETAIL_REQUEST, any> {}
export interface ArticleAction_GET_DETAIL_Success
	extends IActionTypeBase<eArticleActionTypeIds.GET_DETAIL_SUCCESS, ArticleModel | undefined> {}
export interface ArticleAction_GET_DETAIL_Failure extends IActionTypeBase<eArticleActionTypeIds.GET_DETAIL_FAILURE, Error | string> {}


export interface ArticleAction_GET_DETAIL_EDIT_Request extends IActionTypeBase<eArticleActionTypeIds.GET_DETAIL_EDIT_REQUEST, any> {}
export interface ArticleAction_GET_DETAIL_EDIT_Success
	extends IActionTypeBase<eArticleActionTypeIds.GET_DETAIL_EDIT_SUCCESS, ArticleSaveModel | undefined> {}
export interface ArticleAction_GET_DETAIL_EDIT_Failure extends IActionTypeBase<eArticleActionTypeIds.GET_DETAIL_EDIT_FAILURE, Error | string> {}

export interface ArticleAction_SAVE_Request extends IActionTypeBase<eArticleActionTypeIds.SAVE_REQUEST, ArticleSaveModel> {}
export interface ArticleAction_SAVE_Success extends IActionTypeBase<eArticleActionTypeIds.SAVE_SUCCESS, string> {}
export interface ArticleAction_SAVE_Failure extends IActionTypeBase<eArticleActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface ArticleAction_DELETE_Request extends IActionTypeBase<eArticleActionTypeIds.DELETE_REQUEST, any[]> {}
export interface ArticleAction_DELETE_Success extends IActionTypeBase<eArticleActionTypeIds.DELETE_SUCCESS, string> {}
export interface ArticleAction_DELETE_Failure extends IActionTypeBase<eArticleActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface ArticleAction_CHANGE_STATUS_Request
	extends IActionTypeBase<eArticleActionTypeIds.CHANGE_STATUS_REQUEST, ArticleChangeStatusRequest> {}
export interface ArticleAction_CHANGE_STATUS_Success extends IActionTypeBase<eArticleActionTypeIds.CHANGE_STATUS_SUCCESS, string> {}
export interface ArticleAction_CHANGE_STATUS_Failure extends IActionTypeBase<eArticleActionTypeIds.CHANGE_STATUS_FAILURE, Error | string> {}

export interface ArticleAction_RELOAD extends IActionTypeBase<eArticleActionTypeIds.NEED_RELOAD, undefined> {}

export interface ArticleAction_SHOW_DETAIL extends IActionTypeBase<eArticleActionTypeIds.SHOW_DETAIL, BaseShowDetailId> {}
export interface ArticleAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eArticleActionTypeIds.CHANGE_SELECTED_IDS, any[]> {}
export interface ArticleAction_SHOW_CONFIRM extends IActionTypeBase<eArticleActionTypeIds.SHOW_CONFIRM, ArticleActionConfirmRequest> {}

export interface ArticleAction_GET_RELATED_Request extends IActionTypeBase<eArticleActionTypeIds.GET_RELATED_REQUEST, ArticleRelatedSelectRequest> {}
export interface ArticleAction_GET_RELATED_Success extends IActionTypeBase<eArticleActionTypeIds.GET_RELATED_SUCCESS, ArticleSaveModel[]> {}
export interface ArticleAction_GET_RELATED_Failure extends IActionTypeBase<eArticleActionTypeIds.GET_RELATED_FAILURE, Error | string> {}

export interface ArticleAction_GET_TYPENEWS_Request extends IActionTypeBase<eArticleActionTypeIds.GET_TYPENEWS_REQUEST, ArticleTypeNewsSelectRequest> {}
export interface ArticleAction_GET_TYPENEWS_Success extends IActionTypeBase<eArticleActionTypeIds.GET_TYPENEWS_SUCCESS, ArticleTypeNewsResponse[]> {}
export interface ArticleAction_GET_TYPENEWS_Failure extends IActionTypeBase<eArticleActionTypeIds.GET_TYPENEWS_FAILURE, Error | string> {}

export type ArticleActionTypes =
	| ArticleAction_GETPAGINATION_Request	| ArticleAction_GETPAGINATION_Success	| ArticleAction_GETPAGINATION_Failure
	| ArticleAction_GETBY_ACTION_Request	| ArticleAction_GETBY_ACTION_Success	| ArticleAction_GETBY_ACTION_Failure
	| ArticleAction_GETBY_OWNER_ACTION_Request	| ArticleAction_GETBY_OWNER_ACTION_Success	| ArticleAction_GETBY_OWNER_ACTION_Failure
	| ArticleAction_GETPUBLISHED_Request	| ArticleAction_GETPUBLISHED_Success	| ArticleAction_GETPUBLISHED_Failure
	| ArticleAction_GET_DETAIL_Request	| ArticleAction_GET_DETAIL_Success	| ArticleAction_GET_DETAIL_Failure
	| ArticleAction_GET_DETAIL_EDIT_Request	| ArticleAction_GET_DETAIL_EDIT_Success	| ArticleAction_GET_DETAIL_EDIT_Failure
	| ArticleAction_SAVE_Request	| ArticleAction_SAVE_Success	| ArticleAction_SAVE_Failure
	| ArticleAction_DELETE_Request	| ArticleAction_DELETE_Success	| ArticleAction_DELETE_Failure
	| ArticleAction_CHANGE_STATUS_Request	| ArticleAction_CHANGE_STATUS_Success	| ArticleAction_CHANGE_STATUS_Failure
	| ArticleAction_RELOAD	| ArticleAction_SHOW_DETAIL	| ArticleAction_CHANGE_SELECTED_IDS
	| ArticleAction_SHOW_CONFIRM
	| ArticleAction_GET_RELATED_Request	| ArticleAction_GET_RELATED_Success	| ArticleAction_GET_RELATED_Failure
	| ArticleAction_GET_TYPENEWS_Request	| ArticleAction_GET_TYPENEWS_Success	| ArticleAction_GET_TYPENEWS_Failure;

import { useCallback } from 'react';

export type IBadgesData = {
	text: string;
	bgClass?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'gray' | 'light';
};

export type IBadgesProps = {
	dataSource: IBadgesData[];
	sizeClass?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
	bgClass?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'gray' | 'light';
	preset?: 'dim' | 'outline' | 'dimoutline';
	rounded?: boolean;
	className?: string;
};

const bgObject = ['primary', 'secondary', 'success', 'info', 'warning', 'dark', 'gray', 'light'];

export const Badges = (props: IBadgesProps) => {
	const { sizeClass, dataSource, bgClass, preset, rounded, className } = props;
	const getRandomObject = () => {
		const randomObject = bgObject[Math.floor(Math.random() * bgObject.length)];
		return randomObject;
	};
	const classButton = useCallback(
		(_bgClass?: string) => {
			let _preset = '';
			const ___bgClass = _bgClass || bgClass || getRandomObject();
			if (preset === 'dim') {
				_preset = 'badge-dim';
			} else if (preset === 'outline') {
				_preset = `bg-outline-${___bgClass}`;
			} else if (preset === 'dimoutline') {
				_preset = `badge-dim bg-outline-${___bgClass}`;
			} else {
				_preset = `bg-${___bgClass}`;
			}
			return `badge ${rounded ? 'rounded-pill' : ''} ${_preset} badge-${sizeClass || 'xs'} ${className} me-1 mb-1`;
		},
		[preset, rounded, className, sizeClass, bgClass]
	);
	return (
		<>
			{dataSource &&
				dataSource.map((item, idx) => {
					return (
						<span key={idx} className={classButton(item.bgClass)}>
							{item.text}
						</span>
					);
				})}
		</>
	);
};

import React, { useMemo } from 'react';
import {
	DxArgumentAxis,
	DxChart,
	DxCommonSeriesSettings,
	DxGrid,
	DxLegend,
	DxMargin,
	DxSeries,
	DxTooltip,
} from '../../../components/common/DxCharts';

const energySources = [
	{ value: 'da_xuat_ban', name: 'Đã xuất bản' },
	{ value: 'cho_duyet', name: 'Chờ duyệt' },
	{ value: 'cho_bien_tap', name: 'Chờ biên tập' },
];

const BieuDoXuatBanTheoNgay = () => {
	const dataSource = useMemo(() => {
		const data = [];
		for (let i = 1; i <= 31; i++) {
			data.push({
				ngay: `${i}/3`,
				da_xuat_ban: Math.floor(Math.random() * 1000),
				cho_duyet: Math.floor(Math.random() * 1000),
				cho_bien_tap: Math.floor(Math.random() * 1000),
			});
		}
		return data;
	}, []);

	return (
		<>
			<DxChart palette='Dark Violet' dataSource={dataSource} height={320}>
				<DxCommonSeriesSettings argumentField='ngay' type={'line'} />
				{energySources.map((item) => (
					<DxSeries key={item.value} valueField={item.value} name={item.name} />
				))}
				<DxMargin bottom={20} />
				<DxArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
					<DxGrid visible={true} />
				</DxArgumentAxis>
				<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='bottom' />
				{/* <DxTitle text='Energy Consumption in 2004'></DxTitle> */}
				<DxTooltip enabled={true} />
			</DxChart>
		</>
	);
};

export default BieuDoXuatBanTheoNgay;

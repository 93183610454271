import { eArticleStatus } from './eArticleStatus';

export type ArticleActionConfirmRequest = {
	ids: number[];
	actionStatus: eArticleStatus;
	isShow: boolean;
	receivedBy?: string;
	subject?: string;
	message?: string;
};

export const dfArticleActionConfirmRequest: ArticleActionConfirmRequest = {
	ids: [],
	actionStatus: eArticleStatus.DRAFT,
	isShow: false,
	receivedBy: '',
	subject: '',
	message: '',
};

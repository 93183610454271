import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { actions } from '../../../context/stores/rootActions';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import no_img from '../../../assets/images/picture.png';
import { useLookupArticleCategory } from '../../../components/shared/hoc-article-category/useLookupArticleCategory';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { eArticleArchives, eArticleArchivesData } from '../../../context/models/articles/eArticleArchives';
import { DxSelectBox } from '../../../components/base/DxSelectBox';
import ArticlePublishedSelectionModal from '../article-published-selection';
import { ArticleApi } from '../../../context/api/articles/articleApi';
import { BaseResponse } from '../../../context/base/BaseResponse';
import { NotifyHelper } from '../../../utils/NotifyHelper';

const ArticleTypeNewsPage = () => {
	const titlePage = 'Cấu hình bài viết hiển thị';
	const dispatch = useDispatch();
	const [selectedIds, onChangeSelectedIds] = useState<number[]>([]);
	const [showAddModal, onShowAddModal] = useState(false);
	const [showConfirm, onShowConfirm] = useState(false);
	const { article_category_all } = useLookupArticleCategory();
	const [filter, setFilter] = useState({ typenews: eArticleArchives.NOI_BAT_TRANG_CHU, page_id: 0 });
	const { status, listByTypeNews } = useSelector((state: AppState) => state.article);

	const titlePopupModal = useMemo(() => {
		const result = `Chọn bài viết "${eArticleArchivesData.find((x) => x.key === filter.typenews)?.title}" cho trang "${
			filter.page_id === 0 ? 'Trang chủ' : `Chuyên mục ${filter.page_id}`
		}"`;
		return result;
	}, [filter]);

	const pageViewData = useMemo(() => {
		const result = [{ page_id: 0, text: '[0] Trang chủ' }];
		for (const item of article_category_all) {
			result.push({ page_id: item.id, text: `[${item.id}] ${item.name}` });
		}
		return result;
	}, [article_category_all]);

	const handleReloadAllData = useCallback(() => {
		dispatch(actions.article.getTypeNewsRequest(filter));
		onShowAddModal(false);
		onShowConfirm(false);
	}, [filter, dispatch]);

	const dataSources = useMemo(() => {
		return listByTypeNews?.sort((a, b) => a.sortOrder - b.sortOrder);
	}, [listByTypeNews]);

	const handleDeleteData = useCallback(
		async (ids: number[]) => {
			const response: BaseResponse = await ArticleApi.DeleteArticleTypeNewsAsync(ids);
			if (response.isSuccess) {
				NotifyHelper.Success('Xóa bài viết thành công');
				handleReloadAllData();
			} else {
				NotifyHelper.Error(response.message);
			}
		},
		[handleReloadAllData]
	);

	const handleSaveData = useCallback(
		async (ids: number[]) => {
			const response: BaseResponse = await ArticleApi.AddArticleTypeNewsAsync({
				typenews: filter.typenews,
				article_ids: ids,
				page_id: filter.page_id,
			});
			if (response.isSuccess) {
				NotifyHelper.Success('Cấu hình bài viết thành công');
				handleReloadAllData();
			} else {
				NotifyHelper.Error(response.message);
			}
		},
		[filter.typenews, filter.page_id, handleReloadAllData]
	);

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='title'
				caption={`Tiêu đề`}
				minWidth={240}
				fixed
				cellRender={(cell: any) => {
					const article = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='article-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={article.image?.length > 0 ? article.image : no_img} alt='ảnh đại diện' />
								</div>
								<div className='user-info'>
									<h6 className='tb-lead'>
										<span className='fw-bold'>
											<em className='ni ni-article text-azure fs-16px'></em>
											<span className='bold _neo_article_TITLE fs-14px'>{article.title}</span>
										</span>
									</h6>
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='originCatId' width={150} caption={`Chuyên mục`}>
				<DxLookup dataSource={article_category_all} valueExpr={'id'} displayExpr={'name'} />
			</DxTableColumn>,
		];
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [article_category_all]);

	const toolBars = useMemo(() => {
		const result = [
			<DxToolbarItem location='before' key={createUUID()}>
				<DxSelectBox
					name='filter_page'
					placeholder='Chọn trang hiển thị'
					dataSource={pageViewData}
					value={filter.page_id}
					valueExpr='page_id'
					displayExpr='text'
					onValueChanged={(value) => setFilter({ ...filter, page_id: value || 0 })}
				/>
			</DxToolbarItem>,
			<DxToolbarItem location='before' key={createUUID()}>
				<DxSelectBox
					name='filter_page'
					placeholder='Chọn trang hiển thị'
					dataSource={eArticleArchivesData.sort((a, b) => a.sort_order - b.sort_order)}
					value={filter.typenews}
					valueExpr='key'
					displayExpr='title'
					onValueChanged={(value) => setFilter({ ...filter, typenews: value || eArticleArchives.NOI_BAT_TRANG_CHU })}
				/>
			</DxToolbarItem>,
			<DxToolbarItem location='after' key={createUUID()}>
				<Button
					text='Thêm bài viết'
					icon='icon ni ni-plus'
					theme='primary'
					className='d-md-inline-flex'
					onClick={() => onShowAddModal(true)}
				/>
			</DxToolbarItem>,
			<DxToolbarItem location='after' key={createUUID()}>
				<Button
					text={`Xóa (${selectedIds.length})`}
					isDisabled={selectedIds.length == 0}
					icon='icon ni ni-trash'
					className='d-md-inline-flex'
					theme='danger'
					onClick={() => onShowConfirm(true)}
				/>
			</DxToolbarItem>,
		];
		return result;
	}, [pageViewData, filter, selectedIds]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}></PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={dataSources}
								keyExpr='id'
								columns={columns}
								toolbars={toolBars}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} bài viết đã chọn khỏi danh sách hiện tại ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
			{showAddModal && (
				<>
					<ArticlePublishedSelectionModal
						onClose={() => onShowAddModal(false)}
						onSelectedValueChanged={(value) => {
							handleSaveData(value);
						}}
						title={titlePopupModal}
					/>
				</>
			)}
		</>
	);
};

export default ArticleTypeNewsPage;

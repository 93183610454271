import { ApiBase } from '../../base/_ApiBase';
import { ApiClient } from '../../base/_ApiClient';
import { ArticleChangeStatusRequest } from '../../models/articles/ArticleChangeStatusRequest';
import { ArticleModel, ArticleSaveModel } from '../../models/articles/ArticleModel';
import { ArticleRelatedSelectRequest } from '../../models/articles/ArticleRelatedSelectRequest';
import { ArticleSelectRequest } from '../../models/articles/ArticleSelectRequest';
import { ArticleTypeNewsAddRequest, ArticleTypeNewsSelectRequest } from '../../models/articles/ArticleTypeNewsSelectRequest';

export const ArticleApi = new (class ArticleApi {
	_baseUrl: string;
	constructor() {
		this._baseUrl = '/articles';
	}
	GetAllAsync() {
		return ApiClient.GET(`${this._baseUrl}`);
	}
	GetPaginationAsync(request: any) {
		// var queryString = paramsQuery ? Object.keys(paramsQuery).map(key => key + '=' + paramsQuery[key]).join('&') : '';
		return ApiClient.GET(`${this._baseUrl}/pagination${request ? `?${new URLSearchParams(request).toString()}` : ''}`);
	}
	GetByIdAsync(id: any) {
		return ApiClient.GET(`${this._baseUrl}/${id}`);
	}
	GetEditByIdAsync(id: any) {
		return ApiClient.GET(`${this._baseUrl}/get-edit/${id}`);
	}
	PostAsync(entity: ArticleSaveModel) {
		return ApiClient.POST(`${this._baseUrl}`, entity);
	}
	PutAsync(entity: ArticleSaveModel) {
		return ApiClient.PUT(`${this._baseUrl}`, entity);
	}
	DeleteAsync(id: any) {
		return ApiClient.DELETE(`${this._baseUrl}/${id}`);
	}
	DeletesAsync(ids: any[]) {
		return ApiClient.POST(`${this._baseUrl}/deletes`, { ids });
	}
	ChangeStatusAsync(request: ArticleChangeStatusRequest) {
		return ApiClient.POST(`${this._baseUrl}/change-article-status`, request);
	}
	SelectPaginationByActionAsync(request: ArticleSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/select-by-action${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	SelectPaginationByActionOwnerAsync(request: ArticleSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/select-by-owner-action${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	SelectPaginationPublishedAsync(request: ArticleSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/select-published${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	SelectArticleRelatedAsync(request: ArticleRelatedSelectRequest) {
		return ApiClient.POST(`${this._baseUrl}/select-article-related`, request);
	}
	SelectAllByTypeNewsAsync(request: ArticleTypeNewsSelectRequest) {
		const params: any = request;
		return ApiClient.GET(`${this._baseUrl}/article-typenews${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
	}
	AddArticleTypeNewsAsync(request: ArticleTypeNewsAddRequest) {
		return ApiClient.POST(`${this._baseUrl}/article-typenews`, request);
	}
	DeleteArticleTypeNewsAsync(ids: number[]) {
		return ApiClient.DELETE(`${this._baseUrl}/article-typenews`, { ids });
	}
})();

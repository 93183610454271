import { AccountInfo } from '../context/models/my-system/accounts';
import { createContext, useContext } from 'react';
import { MenuResponse } from '../context/models/my-system/accounts/MenuResponse';


export type AuthContextType = {
	account: AccountInfo | null;
	menus: MenuResponse[];
	login: (acount: AccountInfo | null) => void;
	logout: () => void;
};

export const AuthContext = createContext<AuthContextType>(null!);

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}
	return context;
};
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationResponse, defaultBasePagination } from '../../base/BasePaginationResponse';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { KeywordSeoModel } from '../../models/seos/KeywordSeoModel';
import { KeywordSeoActionTypes, eKeywordSeoActionTypeIds } from './IKeywordSeoActionTypes';
import { IKeywordSeoState } from './IKeywordSeoState';

const initPaginationResponse: BasePaginationResponse<KeywordSeoModel> = {
	listDatas: [],
	pagination: defaultBasePagination,
};

const initialState: IKeywordSeoState = {
	status: eBaseActionStatus.idle,
	allKeywords: [],
	selectedIds: [],
	showConfirm: false,
	showDetail: { isShow: false },
	paginationResponse: initPaginationResponse,
};

const keywordSeoReducer = (state: IKeywordSeoState = initialState, action: KeywordSeoActionTypes): IKeywordSeoState => {
	switch (action.type) {
		case eKeywordSeoActionTypeIds.GET_ALL_REQUEST:
		case eKeywordSeoActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allKeywords: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allKeywords: [],
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				allKeywords: [],
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eKeywordSeoActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eKeywordSeoActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm từ khóa mới thành công!');
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Xóa từ khóa thành công!');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: { isShow: false },
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eKeywordSeoActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		default:
			return state;
	}
};

export default keywordSeoReducer;

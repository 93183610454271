import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, LoadingTable } from '../../../components/base';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { BadgesRole } from '../../../components/shared/system/badges-role/BadgesRole';
import { useLookupRoles } from '../../../components/shared/system/useLookupRoles';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import MenuPhanQuyenMultiModal from './phan-quyen-multi/MenuPhanQuyenMultiModal';
import MenuPhanQuyenModal from './phan-quyen/MenuPhanQuyenModal';
import { MenuItemResponse } from '../../../context/models/my-system/menus/MenuItemResponse';
import {
	MenuRemoveRoleRequest,
	MenuUpdateRoleMultipleRequest,
	MenuUpdateRoleRequest,
} from '../../../context/models/my-system/menus/MenuUpdateRoleRequest';
import { MenusActionTypes } from '../../../context/stores/my-system/menus/IMenuActionTypes';

type IMenusPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	menus_all: MenuItemResponse[];
	selectedIds: number[];
	showChangeRole: BaseShowDetail<MenuItemResponse>;
	showChangeRoleMulti: boolean;
	showConfirm: boolean;
	handleReloadData: () => void;
	handleChangRole: (data: MenuUpdateRoleRequest) => void;
	handleChangRoleMultiple: (data: MenuUpdateRoleMultipleRequest) => void;
	handleRemoveRole: (data: MenuRemoveRoleRequest) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowChangeRole: (data: BaseShowDetail<MenuItemResponse>) => void;
	onShowChangeRoleMulti: (isShow: boolean) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Phân quyền chức năng`,
		status: state.menus.status,
		menus_all: state.menus.menus_all,
		showChangeRole: state.menus.showChangeRole,
		showChangeRoleMulti: state.menus.showChangeRoleMulti,
		selectedIds: state.menus.selectedIds,
		showConfirm: state.menus.showConfirm,
		allRoles: state.roles.allRoles,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<MenusActionTypes>) => ({
	handleReloadData: () => {
		dispatch(actions.menus.getAllRequest());
	},
	handleChangRole: (data: MenuUpdateRoleRequest) => {
		dispatch(actions.menus.changeRoleRequest(data));
	},
	handleChangRoleMultiple: (data: MenuUpdateRoleMultipleRequest) => {
		dispatch(actions.menus.changeRoleMultiRequest(data));
	},
	handleRemoveRole: (data: MenuRemoveRoleRequest) => {
		dispatch(actions.menus.removeRoleRequest(data));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.menus.changeSelectedIds(ids));
	},
	onShowChangeRole: (data: BaseShowDetail<MenuItemResponse>) => {
		dispatch(actions.menus.showChangeRole(data));
	},
	onShowChangeRoleMulti: (isShow: boolean) => {
		dispatch(actions.menus.showChangeRoleMulti(isShow));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.menus.showConfirm(isShow));
	},
});

const MenuPage = (props: IMenusPageProps) => {
	const { roles_lookup } = useLookupRoles();
	const {
		status,
		menus_all,
		selectedIds,
		showChangeRole,
		showChangeRoleMulti,
		showConfirm,
		titlePage,
		handleChangRole,
		handleChangRoleMultiple,
		handleReloadData,
		handleRemoveRole,
		onChangeSelectedIds,
		onShowChangeRole,
		onShowChangeRoleMulti,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	function calculateFilterExpression(filterValue: any, selectedFilterOperation: any, target: any) {
		return () => {
			if (target === 'search' && typeof filterValue === 'string') {
				return [target.dataField, 'contains', filterValue];
			}
			return function (data: any) {
				const list = data.roleids || [];
				return list.indexOf(filterValue) !== -1;
			};
		};
	}

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='sortOrder'
				caption={`TT`}
				width={80}
				alignment={'center'}
				allowFiltering={false}
				allowSearch={false}
				allowHeaderFiltering={false}
			></DxTableColumn>,
			<DxTableColumn
				groupIndex={0}
				key={createUUID()}
				dataField='parent_id'
				width={150}
				caption={`Cha`}
				autoExpandGroup={true}
				calculateCellValue={(rowData: any) => {
					return rowData.parent_id <= 0 ? rowData.id : rowData.parent_id;
				}}
				groupCellRender={(cell: any) => {
					const count = cell.data.items == null ? cell.data.collapsedItems?.length : cell.data.items?.length;
					return (
						<span className='text-primary'>
							{cell.text || 'Danh mục cha'} <em>({count})</em>
						</span>
					);
				}}
			>
				<DxLookup dataSource={menus_all} valueExpr='id' displayExpr='name' />
			</DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='icon'
				caption={`Icon`}
				width={80}
				alignment={'center'}
				allowFiltering={false}
				allowSearch={false}
				allowHeaderFiltering={false}
				cellRender={(cell: any) => <em className={`icon ni ni-${cell.value || 'menu-squared'} fs-16px`}></em>}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='name'
				caption={`Chức năng`}
				minWidth={150}
				cellRender={(cell: any) => {
					return (
						<>
							<h6>{cell.value}</h6>
							<code>{cell.data.path}</code>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='parent_id' width={160} caption={`Chức năng cha`}>
				<DxLookup dataSource={menus_all} valueExpr='id' displayExpr='name' />
			</DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='role_ids'
				width={300}
				caption={`Phân quyền`}
				dataType={'object'}
				cellRender={(cell: any) => {
					return <BadgesRole roles_all={roles_lookup} value={cell.value || []} />;
				}}
				calculateFilterExpression={calculateFilterExpression}
			>
				{/* <DxLookup dataSource={roles_lookup} valueExpr='id' displayExpr='name' /> */}
			</DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				caption={'Thao tác'}
				alignment='center'
				width={140}
				allowExporting={false}
				cellRender={(cell: any) => {
					return (
						<>
							{cell.data.id > 0 && (
								<>
									<Button
										onClick={() => onShowChangeRole({ isShow: true, detailData: cell.data })}
										text=''
										icon='ni ni-shield-check-fill'
										preset='dimoutline'
										theme='warning'
										title='Phân quyền'
									/>{' '}
								</>
							)}
						</>
					);
				}}
			/>
		);
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menus_all, roles_lookup]);

	return (
		<>
			<PageContainer>
				<PageHeader title={titlePage}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Hủy phân quyền (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									isLoading={status === eBaseActionStatus.saving}
									onClick={() => onShowConfirm(true)}
								/>
							</li>
							<li>
								<Button
									text={`Thay đổi phân quyền (${selectedIds.length})`}
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									isDisabled={selectedIds.length <= 0}
									isLoading={status === eBaseActionStatus.saving}
									onClick={() => onShowChangeRoleMulti(true)}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={menus_all}
								keyExpr='id'
								columns={columns}
								isShowIdx={false}
								isLoading={status !== eBaseActionStatus.complete}
								exportOptions={{
									title: `${titlePage}`,
									hasExportSelected: true,
									hasHeader: true,
									headerMerge: { leftIndex: 2, rightIndex: columns?.length },
								}}
								filters={{
									refreshDataGrid: handleReloadData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
								wordWrapEnabled={true}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showChangeRole && showChangeRole.isShow && showChangeRole.detailData && (
				<>
					<MenuPhanQuyenModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showChangeRole.isShow}
						menuModel={showChangeRole.detailData}
						onClose={() => onShowChangeRole({ isShow: false })}
						onSubmit={(data: MenuUpdateRoleRequest) => handleChangRole(data)}
						title={`Phân quyền chức năng`}
					/>
				</>
			)}
			{showChangeRoleMulti && selectedIds.length > 0 && (
				<>
					<MenuPhanQuyenMultiModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showChangeRoleMulti}
						menu_ids={selectedIds}
						onClose={() => onShowChangeRoleMulti(false)}
						onSubmit={(data: MenuUpdateRoleMultipleRequest) => handleChangRoleMultiple(data)}
						title={`Phân quyền nhiều chức năng`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn hủy phân quyền ${selectedIds.length} chức năng đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleRemoveRole({ menu_ids: selectedIds })}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Hủy phân quyền'
						btnCloseText='Đóng'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);

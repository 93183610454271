import { PageBody, PageContainer } from '../../components/container';
import SoLieu from './SoLieu/SoLieu';
import BieuDoLuotTruyCap from './bieu-do/BieuDoLuotTruyCap';
import BieuDoLuotTruyCapPieChart from './bieu-do/BieuDoLuotTruyCapPieChart';
import BieuDoXuatBan from './bieu-do/BieuDoXuatBan';
import BieuDoXuatBanPieChart from './bieu-do/BieuDoXuatBanPieChart';
import BieuDoXuatBanTheoNgay from './bieu-do/BieuDoXuatBanTheoNgay';

const Dashboard = () => {
	return (
		<>
			<PageContainer>
				{/* <PageHeader title={`Dashboard`}></PageHeader> */}
				<PageBody>
					<div className='nk-block'>
						<div className='row g-gs'>
							<div className='col-md-12 col-xxl-12'>
								<SoLieu />
							</div>
						</div>
						<div className='row g-gs mb-2'>
							<div className='col-md-12 col-xxl-12'>
								<div className='card card-full h-100'>
									<div className='card-inner-group'>
										<div className='card-inner'>
											<div className='card-title card-title-sm'>
												<h6 className='title'>Thống kê bài viết trong tháng</h6>
											</div>
											<div className='pt-2'>
												<BieuDoXuatBanTheoNgay />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='row g-gs mb-2'>
							<div className='col-md-12 col-xxl-12'>
								<div className='card card-full h-100'>
									<div className='card-inner-group'>
										<div className='card-inner'>
											<div className='card-title card-title-sm'>
												<h6 className='title'>Thống kê bài viết trong tháng theo chuyên mục</h6>
											</div>
											<div className='pt-2'>
												<BieuDoXuatBanPieChart />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='row g-gs mb-2'>
							<div className='col-md-12 col-xxl-12'>
								<div className='card card-full h-100'>
									<div className='card-inner-group'>
										<div className='card-inner'>
											<div className='card-title card-title-sm'>
												<h6 className='title'>Thống kê lượt truy cập trong tháng</h6>
											</div>
											<div className='pt-2'>
												<BieuDoLuotTruyCap />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='row g-gs mb-2'>
							<div className='col-md-12 col-xxl-12'>
								<div className='card card-full h-100'>
									<div className='card-inner-group'>
										<div className='card-inner'>
											<div className='card-title card-title-sm'>
												<h6 className='title'>Thống kê truy cập trong tháng theo chuyên mục</h6>
											</div>
											<div className='pt-2'>
												<BieuDoLuotTruyCapPieChart />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</PageBody>
			</PageContainer>
		</>
	);
};

export default Dashboard;

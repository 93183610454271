export enum eArticleStatus {
	APPROVING = 'APPROVING',
	BACK = 'BACK',
	DELETED = 'DELETED',
	DRAFT = 'DRAFT',
	EDITED = 'EDITED',
	EDITING = 'EDITING',
	PUBLISHED = 'PUBLISHED',
	REMOVED = 'REMOVED',
	RESTORE = 'RESTORE',
	RETURN = 'RETURN',
	SENTAPPROVING = 'SENTAPPROVING',
	SENTEDITING = 'SENTEDITING',
}

export enum eArticleRole {
	CREATE = 'CREATE',
	EDIT = 'EDIT',
	DELETE = 'DELETE',
	WRITER = 'WRITER',
	EDITOR = 'EDITOR',
	APPROVE = 'APPROVE',
	PUBLISH = 'PUBLISH',
	EDIT_AFTER_PUBLISH = 'EDIT_AFTER_PUBLISH',
	REMOVE = 'REMOVE',
}

export const ArticleActionRule = ({
	status,
	isOwner,
	articleActionRoles,
}: {
	status: eArticleStatus;
	isOwner: boolean;
	articleActionRoles: string[];
}) => {
	let listActions: eArticleStatus[] = [];
	if (status === eArticleStatus.DRAFT) {
		listActions = [eArticleStatus.SENTAPPROVING, eArticleStatus.SENTEDITING, eArticleStatus.DELETED];
		if (articleActionRoles.includes(eArticleRole.WRITER)) listActions.push(eArticleStatus.EDITED);
		if (articleActionRoles.includes(eArticleRole.PUBLISH)) listActions.push(eArticleStatus.PUBLISHED);
	} else if (status === eArticleStatus.DELETED) {
		listActions = [eArticleStatus.DELETED, eArticleStatus.RESTORE];
	} else if (status === eArticleStatus.SENTAPPROVING) {
		listActions = [];
		if (isOwner) {
			listActions = [eArticleStatus.RETURN];
		} else {
			if (articleActionRoles.includes(eArticleRole.APPROVE)) listActions = [eArticleStatus.BACK, eArticleStatus.APPROVING];
		}
	} else if (status === eArticleStatus.SENTEDITING) {
		if (isOwner) {
			listActions = [eArticleStatus.RETURN];
		} else {
			listActions = [eArticleStatus.BACK, eArticleStatus.EDITING];
		}
	} else if (status === eArticleStatus.APPROVING) {
		listActions = [eArticleStatus.BACK];
		if (articleActionRoles.includes(eArticleRole.PUBLISH)) listActions.push(eArticleStatus.PUBLISHED);
	} else if (status === eArticleStatus.EDITING) {
		listActions = [eArticleStatus.BACK, eArticleStatus.SENTAPPROVING];
		if (articleActionRoles.includes(eArticleRole.PUBLISH)) listActions.push(eArticleStatus.PUBLISHED);
	} else if (status === eArticleStatus.PUBLISHED) {
		listActions = [];
		if (articleActionRoles.includes(eArticleRole.REMOVE)) listActions.push(eArticleStatus.REMOVED);
	} else if (status === eArticleStatus.REMOVED) {
		listActions = [];
		if (articleActionRoles.includes(eArticleRole.PUBLISH)) listActions.push(eArticleStatus.PUBLISHED);
	}
	return listActions;
};

export const ArticleSaveRule = ({
	status,
	isOwner,
	articleSaveRoles,
}: {
	status: string;
	isOwner: boolean;
	articleSaveRoles: string[];
}) => {
	let listActions: eArticleStatus[] = [];
	if (status === eArticleStatus.DRAFT) {
		listActions = [eArticleStatus.SENTAPPROVING, eArticleStatus.SENTEDITING, eArticleStatus.DELETED];
		if (articleSaveRoles.includes(eArticleRole.WRITER)) listActions.push(eArticleStatus.EDITED);
		if (articleSaveRoles.includes(eArticleRole.PUBLISH)) listActions.push(eArticleStatus.PUBLISHED);
	} else if (status === eArticleStatus.DELETED) {
		listActions = [eArticleStatus.DELETED, eArticleStatus.RESTORE];
	} else if (status === eArticleStatus.SENTAPPROVING) {
		listActions = [];
		if (isOwner) {
			listActions = [eArticleStatus.RETURN];
		} else {
			if (articleSaveRoles.includes(eArticleRole.APPROVE)) listActions = [eArticleStatus.BACK, eArticleStatus.APPROVING];
		}
	} else if (status === eArticleStatus.SENTEDITING) {
		if (isOwner) {
			listActions = [eArticleStatus.RETURN];
		} else {
			listActions = [eArticleStatus.BACK, eArticleStatus.EDITING];
		}
	} else if (status === eArticleStatus.APPROVING) {
		listActions = [];
		if (isOwner) {
			listActions = [eArticleStatus.RETURN];
		} else {
			if (articleSaveRoles.includes(eArticleRole.PUBLISH)) listActions = [eArticleStatus.BACK, eArticleStatus.PUBLISHED];
		}
	} else if (status === eArticleStatus.EDITING) {
		if (isOwner) {
			listActions = [eArticleStatus.RETURN];
		} else {
			listActions = [eArticleStatus.BACK, eArticleStatus.SENTAPPROVING];
			if (articleSaveRoles.includes(eArticleRole.EDITOR)) listActions = [eArticleStatus.BACK, eArticleStatus.PUBLISHED];
		}
	} else if (status === eArticleStatus.PUBLISHED) {
		listActions = [];
		// if (articleSaveRoles.includes(eArticleRole.REMOVE))
		//     listActions = [eArticleStatus.REMOVED]
		if (articleSaveRoles.includes(eArticleRole.EDIT_AFTER_PUBLISH)) listActions = [eArticleStatus.EDITED];
	} else if (status === eArticleStatus.REMOVED) {
		listActions = [];
		if (articleSaveRoles.includes(eArticleRole.PUBLISH)) listActions.push(eArticleStatus.PUBLISHED);
		if (articleSaveRoles.includes(eArticleRole.REMOVE)) listActions.push(eArticleStatus.REMOVED);
	}
	return listActions;
};

export const ArticleButtonRule = ({ status, isOwner }: { status: eArticleStatus; isOwner: boolean }) => {
	let listActions: eArticleStatus[] = [];
	if (status === eArticleStatus.DRAFT) {
		listActions = [eArticleStatus.EDITED];
	} else if (status === eArticleStatus.DELETED) {
		listActions = [];
	} else if (status === eArticleStatus.SENTAPPROVING) {
		listActions = [];
	} else if (status === eArticleStatus.SENTEDITING) {
		listActions = [];
	} else if (status === eArticleStatus.APPROVING) {
		listActions = [eArticleStatus.EDITED];
	} else if (status === eArticleStatus.EDITING) {
		listActions = [eArticleStatus.EDITED];
	} else if (status === eArticleStatus.PUBLISHED) {
		listActions = [eArticleStatus.EDITED];
	} else if (status === eArticleStatus.REMOVED) {
		listActions = [eArticleStatus.EDITED];
	}
	return listActions;
};

const eArticleStatusData = [
	{
		Code: 'APPROVING',
		Name: 'Nhận duyệt',
	},
	{
		Code: 'BACK',
		Name: 'Trả lại',
	},
	{
		Code: 'DELETED',
		Name: 'Xóa',
	},
	{
		Code: 'DRAFT',
		Name: 'Nháp',
	},
	{
		Code: 'EDITED',
		Name: 'Sửa',
	},
	{
		Code: 'EDITING',
		Name: 'Nhận biên tập',
	},
	{
		Code: 'PUBLISHED',
		Name: 'Xuất bản',
	},
	{
		Code: 'REMOVED',
		Name: 'Gỡ xuất bản',
	},
	{
		Code: 'RESTORE',
		Name: 'Khôi phục',
	},
	{
		Code: 'RETURN',
		Name: 'Lấy lại',
	},
	{
		Code: 'SENTAPPROVING',
		Name: 'Gửi duyệt',
	},
	{
		Code: 'SENTEDITING',
		Name: 'Gửi biên tập',
	},
];

export const getArticleStatusName = (status: string) => {
	const pageName = eArticleStatusData.find((x) => x.Code === status);
	return pageName ? pageName.Name : 'Bài viết';
};

import { connect } from 'react-redux';

import { eArticleStatus, getArticleStatusName } from '../../../context/models/articles/eArticleStatus';
import { AppState } from '../../../context/stores/rootReducers';
import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';
import ArticleTable, { mapOwnerArticlePageDispatchToProps } from '../article-table';

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Bài viết ${getArticleStatusName(eArticleStatus.APPROVING)}`,
		articleStatus: eArticleStatus.APPROVING,
		isOwner: true,
		langCode: jwtTokenHelper.LANGCODE(),
		status: state.article.status,
		paginationResponse: state.article.paginationResponse,
		selectedIds: state.article.selectedIds,
		showDetail: state.article.showDetail,
		showConfirm: state.article.showConfirm,
	};
};

const ArticleApprovingPage = connect(mapStateToProps, mapOwnerArticlePageDispatchToProps)(ArticleTable);
export default ArticleApprovingPage;

import React from 'react';
import { DxArgumentAxis, DxChart, DxCommonSeriesSettings, DxGrid, DxLegend, DxMargin, DxSeries, DxTooltip } from '../../../components/common/DxCharts';

const energySources = [
	{ value: 'thang_nay', name: 'Tháng này' },
	{ value: 'thang_truoc', name: 'Tháng trước' },
];

const BieuDoLuotTruyCap = () => {
    const khoiSoLieuGrouped = Array.from({ length: 10 }, (_, idx) => ({
        chuyen_muc: `Chuyên mục ${idx + 1}`,
        thang_nay: Math.floor(Math.random() * 100),
        thang_truoc: Math.floor(Math.random() * 100),
    }));
	return (
		<>
			<DxChart palette='Dark Violet' dataSource={khoiSoLieuGrouped} height={320}>
				<DxCommonSeriesSettings argumentField='chuyen_muc' type={'line'} />
				{energySources.map((item) => (
					<DxSeries key={item.value} valueField={item.value} name={item.name} />
				))}
				<DxMargin bottom={20} />
				<DxArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode='crossLabels'>
					<DxGrid visible={true} />
				</DxArgumentAxis>
				<DxLegend verticalAlignment='bottom' horizontalAlignment='center' itemTextPosition='bottom' />
				{/* <DxTitle text='Energy Consumption in 2004'></DxTitle> */}
				<DxTooltip enabled={true} />
			</DxChart>
		</>
	);
};

export default BieuDoLuotTruyCap;

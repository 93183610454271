import { BasePaginationResponse } from '../../base/BasePaginationResponse';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { CrawlArticleModel } from '../../models/crawl-articles/CrawlArticleModel';

export enum eCrawlArticleActionTypeIds {
	GET_ALL_REQUEST = 'CrawlArticle_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'CrawlArticle_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'CrawlArticle_GET_ALL_FAILURE',

	GET_PAGINATION_REQUEST = 'CrawlArticle_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'CrawlArticle_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'CrawlArticle_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'CrawlArticle_SAVE_REQUEST',
	SAVE_SUCCESS = 'CrawlArticle_SAVE_SUCCESS',
	SAVE_FAILURE = 'CrawlArticle_SAVE_FAILURE',

	DELETE_REQUEST = 'CrawlArticle_DELETE_REQUEST',
	DELETE_SUCCESS = 'CrawlArticle_DELETE_SUCCESS',
	DELETE_FAILURE = 'CrawlArticle_DELETE_FAILURE',

	RELOAD = 'CrawlArticle_RELOAD',

	CHANGE_SELECTED_IDS = 'CrawlArticle_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'CrawlArticle_SHOW_DETAIL',
	SHOW_CONFIRM = 'CrawlArticle_SHOW_CONFIRM',
}

export interface CrawlArticleAction_GETALL_Request extends IActionTypeBase<eCrawlArticleActionTypeIds.GET_ALL_REQUEST, undefined> {}
export interface CrawlArticleAction_GETALL_Success
	extends IActionTypeBase<eCrawlArticleActionTypeIds.GET_ALL_SUCCESS, CrawlArticleModel> {}
export interface CrawlArticleAction_GETALL_Failure extends IActionTypeBase<eCrawlArticleActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface CrawlArticleAction_GETPAGINATION_Request
	extends IActionTypeBase<eCrawlArticleActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface CrawlArticleAction_GETPAGINATION_Success
	extends IActionTypeBase<eCrawlArticleActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationResponse<CrawlArticleModel>> {}
export interface CrawlArticleAction_GETPAGINATION_Failure
	extends IActionTypeBase<eCrawlArticleActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface CrawlArticleAction_SAVE_Request extends IActionTypeBase<eCrawlArticleActionTypeIds.SAVE_REQUEST, CrawlArticleModel> {}
export interface CrawlArticleAction_SAVE_Success extends IActionTypeBase<eCrawlArticleActionTypeIds.SAVE_SUCCESS, string> {}
export interface CrawlArticleAction_SAVE_Failure extends IActionTypeBase<eCrawlArticleActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface CrawlArticleAction_DELETE_Request extends IActionTypeBase<eCrawlArticleActionTypeIds.DELETE_REQUEST, number[]> {}
export interface CrawlArticleAction_DELETE_Success extends IActionTypeBase<eCrawlArticleActionTypeIds.DELETE_SUCCESS, string> {}
export interface CrawlArticleAction_DELETE_Failure extends IActionTypeBase<eCrawlArticleActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface CrawlArticleAction_RELOAD extends IActionTypeBase<eCrawlArticleActionTypeIds.RELOAD, undefined> {}

export interface CrawlArticleAction_SHOW_DETAIL
	extends IActionTypeBase<eCrawlArticleActionTypeIds.SHOW_DETAIL, BaseShowDetail<CrawlArticleModel>> {}
export interface CrawlArticleAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eCrawlArticleActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface CrawlArticleAction_SHOW_CONFIRM extends IActionTypeBase<eCrawlArticleActionTypeIds.SHOW_CONFIRM, boolean> {}

export type CrawlArticleActionTypes =
	| CrawlArticleAction_GETALL_Request
	| CrawlArticleAction_GETALL_Success
	| CrawlArticleAction_GETALL_Failure
	| CrawlArticleAction_GETPAGINATION_Request
	| CrawlArticleAction_GETPAGINATION_Success
	| CrawlArticleAction_GETPAGINATION_Failure
	| CrawlArticleAction_SAVE_Request
	| CrawlArticleAction_SAVE_Success
	| CrawlArticleAction_SAVE_Failure
	| CrawlArticleAction_DELETE_Request
	| CrawlArticleAction_DELETE_Success
	| CrawlArticleAction_DELETE_Failure
	| CrawlArticleAction_RELOAD
	| CrawlArticleAction_SHOW_DETAIL
	| CrawlArticleAction_CHANGE_SELECTED_IDS
	| CrawlArticleAction_SHOW_CONFIRM;

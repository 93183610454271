import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ArticleCrawlerModel } from '../../../context/models/crawlers/ArticleCrawlerModel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { aticleCrawlerApi } from '../../../context/api/articles/aticleCrawlerApi';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { ArticleButton } from '../../articles/button-actions/ArticleButton';
import { PageBodyScroll } from '../../../components/container/PageBodyScroll';
import { ButtonSave, LoadingPanel } from '../../../components/base';
import { Badges } from '../../../components/base/Badges';
import { NotifyHelper } from '../../../utils/NotifyHelper';

const ArticleCrawlerDetailPage = () => {
	const titlePage = 'Danh sách tin bài mới';
	const { id } = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [detail, setDetail] = useState<ArticleCrawlerModel>();

	const handleReloadData = useCallback(async () => {
		setIsLoading(true);
		setDetail(undefined);
		const response = await aticleCrawlerApi.DetailAsync(id || '');
		if (response.isSuccess) {
			setDetail(response.result as ArticleCrawlerModel);
		}
		setIsLoading(false);
	}, [id]);

	const handleKeepData = useCallback(async () => {
		const response = await aticleCrawlerApi.CreateArticleDrafAsync(id || '');
		if (response.isSuccess) {
			NotifyHelper.Success('Lấy tin bài thành công');
			setTimeout(() => {
				navigate(`/crawler-new`, { replace: false });
			}, 1000);
		}
		else{
			NotifyHelper.Error('Lấy tin bài không thành công');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		handleReloadData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return (
		<>
			<div className='components-preview wide-md mx-auto'>
				<PageContainer>
					<PageHeader title={`Xem trước`}>
						<div className='nk-fmg-actions'>
							<ul className='nk-block-tools g-3'>
								<li>
									<ButtonSave
										type='button'
										theme='dark'
										preset='dimoutline'
										isLoading={isLoading}
										isDisabled={isLoading}
										onClick={() => {
											handleKeepData();
										}}
										text={`Lấy tin bài này`}
									/>
								</li>
							</ul>
						</div>
					</PageHeader>
					<PageBody>
						<BlockCard>
							{isLoading && <LoadingPanel />}
							{!isLoading && detail && (
								<>
									<article className='entry'>
										<h3 className='fs-26px mb-4'>{detail.title}</h3>
										<div>
											Tác giả: <b>{detail.author}</b>
											{'; '}
											Ngày xuất bản: <b>{detail.datePublic}</b>
											{'; '}
											Chuyên mục: <b>{detail.category}</b>
										</div>
										<div>
											Từ khóa: <Badges dataSource={detail.tag?.split(',')?.map((x) => ({ text: x }))} />
										</div>
										<div className='mb-4'>
											Link bài viết:{' '}
											<code>
												<a href={detail.url} target='_blank'>
													{detail.url}
												</a>
											</code>
										</div>
										<div className='fs-18px mb-4'>{detail.summary}</div>
										<div dangerouslySetInnerHTML={{ __html: detail.contentHtml }}></div>
									</article>
								</>
							)}
						</BlockCard>
					</PageBody>
				</PageContainer>
			</div>
		</>
	);
};

export default ArticleCrawlerDetailPage;

/* eslint-disable react-refresh/only-export-components */
import { connect } from 'react-redux';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { RoyaltyModel } from '../../../context/models/royalty/RoyaltyModel';
import { AppState } from '../../../context/stores/rootReducers';
import { Dispatch } from 'redux';
import { RoyaltyActionTypes } from '../../../context/stores/royalty/IRoyaltyActionsTypes';
import { actions } from '../../../context/stores/rootActions';
import { useEffect, useMemo } from 'react';
import { createUUID } from '../../../utils/createUUID';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import React from 'react';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import RoyaltyDetail from './RoyaltyDetail';
import { toNumberWithPeriod } from '../../../utils/numberHelper';

type IConfigRoyaltyPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allRoyalties: RoyaltyModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<RoyaltyModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: RoyaltyModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<RoyaltyModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Thiết lập nhuận bút`,
		status: state.royalty.status,
		allRoyalties: state.royalty.allRoyalties,
		selectedIds: state.royalty.selectedIds,
		showDetail: state.royalty.showDetail,
		showConfirm: state.royalty.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<RoyaltyActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.royalty.getAllRequest());
	},
	handleSaveData: (data: RoyaltyModel) => {
		dispatch(actions.royalty.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.royalty.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.royalty.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<RoyaltyModel>) => {
		dispatch(actions.royalty.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.royalty.showConfirm(isShow));
	},
});

const ConfigRoyaltyPage = (props: IConfigRoyaltyPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allRoyalties,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const dataSource = useMemo(() => {
		return allRoyalties.map((royalty) => ({
			...royalty,
			minView: toNumberWithPeriod(parseInt(royalty.minView)),
			money: `${toNumberWithPeriod(parseInt(royalty.money, 10))} ₫`,
		}));
	}, [allRoyalties]);

	const columns = useMemo((): any => {
		const result: any = [
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên`} width={200}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='sort_order' width={50} caption={`Thứ tự`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='money' width={150} caption={`Giá tiền`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='minView' width={150} caption={`Lượt xem tối thiểu`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='description' caption={`Mô tả`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoyalties]);

	return (
		<React.Fragment>
			<PageContainer>
				<PageHeader title={`${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={dataSource}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<RoyaltyDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) =>
							handleSaveData(
								Object.assign(data, {
									money: data.money.replace('.', '').replace(' ₫', ''),
									minView: data.minView.replace('.', '').replace(' ₫', ''),
								})
							)
						}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</React.Fragment>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigRoyaltyPage);
